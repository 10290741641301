/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoggedUserController } from '../../networking/controllers/logged-user-controller';
import { PasswordChangeController } from '../../networking/controllers/password-change-controller';

const initialState = {
  loading: false,
  error: '',
  success: false,
};

const requestPasswordChange = createAsyncThunk(
  'password/requestPasswordChangeStatus',
  async (params) => {
    const user = await LoggedUserController.checkLoggedUser();
    return PasswordChangeController.passwordChange({ ...params, user });
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
  state.success = false;
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.error;
  state.success = false;
};

const setSuccess = (state) => {
  state.error = '';
  state.loading = false;
  state.success = true;
};

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
      state.error = '';
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordChange.pending, setLoading)
      .addCase(requestPasswordChange.fulfilled, setSuccess)
      .addCase(requestPasswordChange.rejected, setError);
  },
});

export { requestPasswordChange };

export const { clear } = passwordSlice.actions;

export default passwordSlice.reducer;
