import { toEducationType } from '../../helpers/utils';
import institutionTypes from '../../config/institution-types';
import { EventSerializer } from './event-serializer';

const toInstitutionType = (type) => {
  switch (type) {
    case 'escuela_comun':
      return institutionTypes.REGULAR_SCHOOL;
    case 'escuela_especial':
      return institutionTypes.SPECIAL_SCHOOL;
    case 'colegio_comun':
      return institutionTypes.REGULAR_PRIVATE_SCHOOL;
    case 'colegio_especial':
      return institutionTypes.SPECIAL_PRIVATE_SCHOOL;
    case 'liceo':
      return institutionTypes.HIGH_SCHOOL;
    case 'utu':
      return institutionTypes.UTU;
    case 'universidad':
      return institutionTypes.UNIVERSITY;
    case 'club_de_ninos':
      return institutionTypes.KIDS_CLUB;
    case 'jardin_publico':
      return institutionTypes.PUBLIC_KINDER;
    case 'jardin_privado':
      return institutionTypes.PRIVATE_KINDER;
    case 'caif':
      return institutionTypes.CAIF;
    default:
      return '-';
  }
};

class PersonSerializer {
  static deSerialize(data) {
    return {
      id: data.id,
      contactName: data.contacts[0] ? data.contacts[0].name : '',
      relationshipType: data.contacts[0] ? data.contacts[0].relationship_type : '',
      contactPhoneNumber: data.contacts[0] ? data.contacts[0].phone_number : '',
      contactEmail: data.contacts[0] ? data.contacts[0].email : '',
      name: data.name,
      birthDate: data.birth_date,
      motive: data.inquiry_motive,
      description: data.inquiry_description,
      institutionType: toInstitutionType(data.institution_type),
      institutionName: data.institution_name,
      institutionLocality: data.institution_locality,
      educationType: toEducationType(data.education_type),
      course: data.course,
      events: data.events?.map((event) => EventSerializer.deSerialize(event, data.id)),
      suggestions: data.suggestion,
      followup: data.followup,
      comments: data.comments,
      archived: data.archived,
      imported: data.imported,
      subscribedUsers: data.users,
    };
  }

  static serialize(person) {
    return {
      id: person.id,
      contact_name: person.contactName,
      relationship_type: person.relationshipType,
      phone_number: person.contactPhoneNumber,
      email: person.contactEmail,
      name: person.name,
      birth_date: person.birthDate,
      inquiry_motive: person.motive,
      inquiry_description: person.description,
      institution_type: person.institutionType?.id,
      institution_name: person.institutionName,
      institution_locality: person.institutionLocality,
      education_type: person.educationType?.id,
      course: person.course,
      events: person.events,
      suggestion: person.suggestions,
      followup: person.followup,
      comments: person.comments,
      archived: person.archived,
    };
  }
}

export { PersonSerializer };
