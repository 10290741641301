import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { LoginSerializer } from '../serializers/login-serializer';

class LoginController {
  // static async login() {
  //   const response = await ApiService.post(API_ROUTES.EXAMPLE);
  //   const deSerializedExample = ExampleSerializer.deSerialize(response.data);
  //   return new Example(deSerializedExample);
  // }

  static login(params) {
    const user = LoginSerializer.serialize(params);
    return ApiService.post(API_ROUTES.LOGIN, {
      user,
    });
  }
}

export { LoginController };
