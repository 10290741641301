import { EducationLevelStatistic } from './pages/education-level-statistic';
import { MessageDetail } from './pages/message-detail';
import { routeNaming } from './routes';
import { Home } from './pages/home';
import { Messages } from './pages/messages';
import { NotFound } from './pages/not-found';
import { People } from './pages/people';
import { Login } from './pages/login';
import { PasswordChangeScreen } from './pages/password-change-screen';
import { Downloads } from './pages/downloads';
import { EnrollPerson } from './pages/enroll-person';
import { PersonDetail } from './pages/person-detail';
import { Statistics } from './pages/statistics';
import { InformedExclusionStatistics } from './pages/informed-exclusion-statistics';
import { InquiryMotivesDistribution } from './pages/inquiry-motives-distribution';
import { InquiryAmountStatistic } from './pages/inquiry-amount-statistic';
import { EducationResultsDistribution } from './pages/education-results-distribution';
import { Meetings } from './pages/meetings';

const routeConfig = [
  {
    name: routeNaming.ENROLL_PERSON,
    component: EnrollPerson,
  },
  {
    name: routeNaming.MESSAGES,
    component: Messages,
  },
  {
    name: routeNaming.PASSWORD_CHANGE_SCREEN,
    component: PasswordChangeScreen,
  },
  {
    name: routeNaming.PEOPLE,
    component: People,
  },
  {
    name: routeNaming.PERSON_DETAIL,
    component: PersonDetail,
  },
  {
    name: routeNaming.MESSAGE_DETAIL,
    component: MessageDetail,
  },
  {
    name: routeNaming.HOME,
    component: Home,
  },
  {
    name: routeNaming.LOGIN,
    component: Login,
  },
  {
    name: routeNaming.DOWNLOADS,
    component: Downloads,
  },
  {
    name: routeNaming.STATISTICS,
    component: Statistics,
  },
  {
    name: routeNaming.EDUCATION_LEVEL_STATISTIC,
    component: EducationLevelStatistic,
  },
  {
    name: routeNaming.INFORMED_EXCLUSION_STATISTICS,
    component: InformedExclusionStatistics,
  },
  {
    name: routeNaming.MOTIVE_DISTRIBUTION_STATISTICS,
    component: InquiryMotivesDistribution,
  },
  {
    name: routeNaming.INQUIRY_AMOUNT_STATISTIC,
    component: InquiryAmountStatistic,
  },
  {
    name: routeNaming.EDUCATION_RESULTS_STATISTICS,
    component: EducationResultsDistribution,
  },
  {
    name: routeNaming.MEETINGS,
    component: Meetings,
  },
  {
    name: routeNaming.NOT_FOUND,
    component: NotFound,
  },
];

export { routeConfig };
