const relationshipTypes = {
  PARENT: 'Madre/Padre',
  SIBLING: 'Hermano/a',
  TEACHER: 'Maestro/a',
  PROFESSOR: 'Profesor/a',
  SPECIALIST: 'Técnico/especialista',
  INSTITUTION_REPRESENTATIVE: 'Representante de institución educativa',
  OTHER: 'Otro',
};

export default relationshipTypes;
