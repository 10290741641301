import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { Message } from '../../models/message';
import { MessageSerializer } from '../serializers/message-serializer';

class MessageController {
  static async getMessage(id) {
    return ApiService.get(`${API_ROUTES.MESSAGES}/${id}`)
      .then((response) => new Message(MessageSerializer.deSerialize(response.data)));
  }

  static createMessage(message) {
    const serializedMessage = MessageSerializer.serialize(message);
    return ApiService.post(API_ROUTES.MESSAGES, {
      inquiry: serializedMessage,
    });
  }
}

export { MessageController };
