import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import styles from './home.module.scss';
import ADdULogo from '../../assets/images/logo.png';
import relationshipTypes from '../../config/relationship-types';
import motiveTypes from '../../config/motive-types';
import institutionTypes from '../../config/institution-types';
import educationTypes from '../../config/education-types';
import { submitForm } from '../../store/slices/form-slice';
import { LoadingIndicator } from '../../common/loading-indicator';
import { Title } from '../../common/title';
import { DropdownSelect } from '../../common/dropdown-select/dropdown-select';
import { strings } from '../../config/strings';
import { constants } from '../../config/constants';
import { EducationCourseAndInstitutionSelects } from '../../common/education-course-and-institution-selects/education-course-and-institution-selects';

const steps = {
  CONTACT_DATA: { id: 'CONTACT_DATA', name: strings.MESSAGE_CONTACT_DATA },
  PERSON_DATA: { id: 'PERSON_DATA', name: strings.MESSAGE_PERSON_DATA },
  INSTITUTION_DATA: { id: 'INSTITUTION_DATA', name: strings.MESSAGE_INSTITUTION_DATA },
};

const initialFormState = {
  contactName: '',
  relationshipType: '',
  otherRelationship: '',
  contactEmail: '',
  contactPhoneNumber: '',
  name: '',
  birthDate: '',
  motive: '',
  otherMotive: '',
  description: '',
  institutionType: '',
  institutionName: '',
  institutionLocality: '',
  educationType: undefined,
  course: '',
  otherCourse: '',
};

const Home = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [error, setError] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((selector) => selector.form);

  const onRelationshipChanged = (e) => {
    setFormState({ ...formState, relationshipType: e.target.value });
  };

  const onMotiveChanged = (e) => {
    setFormState({ ...formState, motive: e.target.value });
  };

  const onInstTypeChanged = (e) => {
    setFormState({ ...formState, institutionType: e.target.value });
  };
  const onContactNameChanged = (e) => {
    setFormState({ ...formState, contactName: e.target.value });
  };
  const onContactPhoneChanged = (e) => {
    if (/^(\d+)$/.test(e.target.value) || e.target.value === '') {
      setFormState({ ...formState, contactPhoneNumber: e.target.value });
      setError(false);
    }
  };
  const onContactOtherRelationshipChanged = (e) => {
    setFormState({ ...formState, otherRelationship: e.target.value });
  };
  const onContactEmailChanged = (e) => {
    setFormState({ ...formState, contactEmail: e.target.value });
    setError(false);
  };
  const onPersonNameChanged = (e) => {
    setFormState({ ...formState, name: e.target.value });
  };
  const onPersonBirthChanged = (e) => {
    setFormState({ ...formState, birthDate: e });
    setErrorDate(false);
  };
  const onOtherMotiveChanged = (e) => {
    setFormState({ ...formState, otherMotive: e.target.value });
  };
  const onDescriptionChanged = (e) => {
    setFormState({ ...formState, description: e.target.value });
  };
  const onInstitutionNameChanged = (e) => {
    setFormState({ ...formState, institutionName: e.target.value });
  };
  const onInstitutionLocalityChanged = (e) => {
    setFormState({ ...formState, institutionLocality: e.target.value });
  };

  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const onRecaptchaChanged = (value) => {
    setRecaptchaValue(value);
  };

  const [currentStep, setCurrentStep] = useState(steps.CONTACT_DATA);
  const handleNextClick = (e) => {
    e.preventDefault();
    switch (currentStep) {
      case steps.CONTACT_DATA:
        if (formState.contactPhoneNumber !== '' || formState.contactEmail !== '') {
          setCurrentStep(steps.PERSON_DATA);
        } else {
          setError(true);
        }
        break;
      case steps.PERSON_DATA:
        if (formState.birthDate < new Date()) {
          setCurrentStep(steps.INSTITUTION_DATA);
        } else {
          setErrorDate(true);
        }
        break;
      case steps.INSTITUTION_DATA:
        if (recaptchaValue !== null) {
          setSuccess(true);
          dispatch(submitForm(formState));
        } else {
          // TODO error
        }
        break;
      default:
        setCurrentStep(steps.CONTACT_DATA);
        break;
    }
  };

  const handleBackClick = () => {
    switch (currentStep) {
      case steps.CONTACT_DATA:
        setCurrentStep(steps.INSTITUTION_DATA);
        break;
      case steps.PERSON_DATA:
        setCurrentStep(steps.CONTACT_DATA);
        break;
      case steps.INSTITUTION_DATA:
        setCurrentStep(steps.PERSON_DATA);
        break;
      default:
        setCurrentStep(steps.CONTACT_DATA);
        break;
    }
  };

  return (
    <div className={styles.container}>
      {!(state.loading || state.success || state.error)
        && (
          <div className={`${styles.containerImage} ${visible ? styles.invisible : styles.visible}`}>
            <div className={styles.containerLogo}>
              <figure>
                <img alt={strings.ADDU_LOGO_DESCRIPTION} src={ADdULogo} />
              </figure>
            </div>
            <div className={styles.containerTitle}>
              <h1>{strings.FORM_SEND_MESSAGE}</h1>
              <h2>{strings.FORM_FILL_DATA}</h2>
              <button type="button" className={styles.completeButton} onClick={() => setVisible(true)}>
                Completar
              </button>
            </div>
          </div>
        )}
      <div className={`${styles.containerForm}  ${success ? styles.success : {}} ${visible ? styles.visible : {}}`}>
        {!(state.loading || state.success || state.error)
          && (
            <>
              <div className={styles.containerStepbyStep}>
                <svg>
                  <circle cx="60" cy="60" r="15" fill="#505A99" />
                  <line x1="60" y1="60" x2="150" y2="60" strokeWidth="3" stroke="#505A99" />
                  {
                    currentStep === steps.CONTACT_DATA
                    && (
                      <svg>
                        <circle cx="150" cy="60" r="15" fill="#C9A79C" />
                        <line x1="150" y1="60" x2="240" y2="60" strokeWidth="3" stroke="#C9A79C" />
                        <circle cx="240" cy="60" r="15" fill="#C9A79C" />
                      </svg>
                    )
                  }
                  {
                    currentStep === steps.PERSON_DATA
                    && (
                      <svg>
                        <circle cx="150" cy="60" r="15" fill="#505A99" />
                        <line x1="150" y1="60" x2="240" y2="60" strokeWidth="3" stroke="#505A99" />
                        <circle cx="240" cy="60" r="15" fill="#C9A79C" />
                      </svg>
                    )
                  }
                  {
                    currentStep === steps.INSTITUTION_DATA
                    && (
                      <svg>
                        <circle cx="150" cy="60" r="15" fill="#505A99" />
                        <line x1="150" y1="60" x2="240" y2="60" strokeWidth="3" stroke="#505A99" />
                        <circle cx="240" cy="60" r="15" fill="#505A99" />
                      </svg>
                    )
                  }
                </svg>
              </div>
              <div className={styles.titleForm}>
                <h1>
                  {currentStep.name}
                </h1>
              </div>
            </>
          )}
        {state.loading && <LoadingIndicator />}
        {
          (currentStep === steps.CONTACT_DATA && !state.loading && !state.success)
          && (
            <div className={styles.containerData}>
              <form className={styles.sendForm} onSubmit={handleNextClick}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  onChange={(e) => onContactNameChanged(e)}
                  value={formState.contactName}
                  label={strings.FORM_CONTACT_NAME_LABEL}
                  variant="outlined"
                  margin="normal"
                  className="primaryOutlinedTextField"
                  required
                />
                <DropdownSelect
                  label={strings.FORM_CONTACT_RELATIONSHIP_LABEL}
                  value={formState.relationshipType}
                  onChange={onRelationshipChanged}
                  items={relationshipTypes}
                />
                {formState.relationshipType === relationshipTypes.OTHER
                  && (
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label={strings.FORM_CONTACT_RELATIONSHIP_OTHER_LABEL}
                      variant="outlined"
                      margin="normal"
                      className="primaryOutlinedTextField"
                      onChange={(e) => onContactOtherRelationshipChanged(e)}
                      value={formState.otherRelationship}
                    />
                  )}
                <TextField
                  size="small"
                  id="outlined-basic"
                  onChange={(e) => onContactPhoneChanged(e)}
                  value={formState.contactPhoneNumber}
                  className="primaryOutlinedTextField"
                  label={strings.FORM_CONTACT_PHONE_LABEL}
                  variant="outlined"
                  margin="normal"
                  error={error}
                />
                <TextField
                  type="email"
                  size="small"
                  id="outlined-basic"
                  label={strings.FORM_CONTACT_EMAIL_LABEL}
                  variant="outlined"
                  margin="normal"
                  className="primaryOutlinedTextField"
                  onChange={(e) => onContactEmailChanged(e)}
                  value={formState.contact_email}
                  error={error}
                  helperText={strings.FORM_CONTACT_EMAIL_HELPER}
                />
                <div className={styles.contentButton}>
                  <button type="button" className={styles.backButton} onClick={handleBackClick} disabled>
                    Atrás
                  </button>
                  <button type="submit" className={styles.nextButton}>
                    Siguiente
                  </button>
                </div>
              </form>
            </div>
          )
        }
        {
          (currentStep === steps.PERSON_DATA && !state.loading && !state.success)
          && (
            <div className={styles.containerData}>
              <form className={styles.sendForm} onSubmit={handleNextClick}>
                <TextField
                  size="small"
                  id="outlined-basic"
                  label={strings.FORM_PERSON_NAME_LABEL}
                  variant="outlined"
                  className="primaryOutlinedTextField"
                  margin="normal"
                  onChange={(e) => onPersonNameChanged(e)}
                  value={formState.name}
                  required
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={2}>
                    <DatePicker
                      label={strings.FORM_PERSON_BIRTHDAY_LABEL}
                      inputFormat="dd/MM/yyyy"
                      value={formState.birthDate}
                      maxDate={new Date()}
                      onChange={onPersonBirthChanged}
                      /* eslint-disable react/jsx-props-no-spreading */
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          required
                          className="primaryOutlinedTextField"
                          variant="outlined"
                          size="small"
                          margin="normal"
                          error={errorDate}
                          helperText={errorDate ? strings.FORM_PERSON_BIRTHDAY_ERROR : ''}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
                <DropdownSelect
                  label={strings.FORM_MOTIVE_LABEL}
                  value={formState.motive}
                  onChange={onMotiveChanged}
                  items={motiveTypes}
                  required
                />
                {formState.motive === motiveTypes.OTHER
                  && (
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label={strings.FORM_MOTIVE_OTHER_LABEL}
                      variant="outlined"
                      margin="normal"
                      onChange={(e) => onOtherMotiveChanged(e)}
                      value={formState.otherMotive}
                      className="primaryOutlinedTextField"
                      required
                    />
                  )}
                <TextField
                  size="small"
                  id="outlined-multiline-static"
                  label={strings.FORM_DESCRIPTION_LABEL}
                  multiline
                  rows={2}
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => onDescriptionChanged(e)}
                  className="primaryOutlinedTextField"
                  value={formState.description}
                />
                <div className={styles.contentButton}>
                  <button type="button" className={styles.backButton} onClick={handleBackClick}>
                    Atrás
                  </button>
                  <button type="submit" className={styles.nextButton}>
                    Siguiente
                  </button>
                </div>
              </form>
            </div>
          )
        }
        {
          (currentStep === steps.INSTITUTION_DATA && !state.loading && !state.success)
          && (
            <div className={styles.formInstitutionData}>
              <form className={styles.sendForm} onSubmit={handleNextClick}>
                <DropdownSelect
                  label={strings.FORM_INSTITUTION_TYPE_LABEL}
                  value={formState.institutionType}
                  onChange={onInstTypeChanged}
                  items={institutionTypes}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label={strings.FORM_INSTITUTION_IDENTIFIER_LABEL}
                  variant="outlined"
                  margin="normal"
                  onChange={(e) => onInstitutionNameChanged(e)}
                  className="primaryOutlinedTextField"
                  value={formState.institutionName}
                />
                <TextField
                  size="small"
                  id="outlined-basic"
                  label={strings.FORM_INSTITUTION_LOCALITY_LABEL}
                  variant="outlined"
                  margin="normal"
                  className="primaryOutlinedTextField"
                  onChange={(e) => onInstitutionLocalityChanged(e)}
                  value={formState.institutionLocality}
                />
                <EducationCourseAndInstitutionSelects
                  educationType={formState.educationType ? formState.educationType.id : undefined}
                  course={formState.course}
                  onChangeEducationType={(e) => setFormState({
                    ...formState,
                    educationType: Object.values(educationTypes)
                      .find((value) => value.id === e.target.value),
                  })}
                  onChangeCourse={(e) => setFormState({ ...formState, course: e.target.value })}
                  labelEducationType={strings.FORM_EDUCATION_TYPE}
                  labelCourse={strings.FORM_COURSE}
                />
                &nbsp;
                <div>
                  <ReCAPTCHA
                    sitekey={constants.RECAPTCHA_KEY}
                    onChange={onRecaptchaChanged}
                  />
                </div>
                <div className={styles.contentButton}>
                  <button type="button" className={styles.backButton} onClick={handleBackClick}>
                    Atrás
                  </button>
                  <button type="submit" className={styles.nextButton}>
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          )
        }
        {(state.success)
          && (
            <div>
              <figure>
                <img
                  alt={strings.ADDU_LOGO_DESCRIPTION}
                  src={ADdULogo}
                  className={styles.lastFigure}
                />
              </figure>
              <div className={styles.lastText}>
                <Title className={styles.success} text={strings.FORM_SUCCESS_MESSAGE} />
                <a href={strings.FORM_LINK_MESSAGE}>
                  Ir a la página de ADdU
                </a>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export { Home };
