import { React, useState } from 'react';
import { TableRow, TableCell } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import PropTypes from 'prop-types';
import { ButtonOverlay } from '../button-overlay';
import { PersonPreview } from '../../models/person-preview';
import styles from './person-list-item.module.scss';
import { ButtonOverlayAction } from '../../models/button-overlay-action';
import { strings } from '../../config/strings';
import { parseDate } from '../../helpers/utils';

const PersonListItem = ({
  personPreview,
  isArchived,
  onClick,
  archiveAction,
}) => {
  const [hover, setHover] = useState(false);

  const overlayIcon = (isArchived ? UnarchiveIcon : ArchiveIcon);
  const overlayLabel = (isArchived ? strings.PERSON_UNARCHIVE : strings.PERSON_ARCHIVE);

  const overlayActions = [
    new ButtonOverlayAction({
      icon: overlayIcon,
      action: archiveAction,
      label: overlayLabel,
    }),
  ];

  return (
    <TableRow
      className={styles.tableRow}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onClick(personPreview.id)}
    >
      <TableCell className={styles.tableCell}>{personPreview.name}</TableCell>
      <TableCell className={styles.tableCell}>{personPreview.contactName}</TableCell>
      <TableCell className={styles.tableCell}>
        <div className={styles.textContainer}>
          {personPreview.motive}
        </div>
      </TableCell>
      <TableCell className={styles.tableCell}>{personPreview.institutionLocality}</TableCell>
      <TableCell className={styles.tableCell}>{personPreview.educationType?.name}</TableCell>
      <TableCell className={styles.tableCell}>{parseDate(personPreview.createdAt)}</TableCell>
      <TableCell className={styles.tableCell}>
        <ButtonOverlay actions={overlayActions} show={hover} />
      </TableCell>
    </TableRow>
  );
};

PersonListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  personPreview: PropTypes.instanceOf(PersonPreview).isRequired,
  isArchived: PropTypes.bool.isRequired,
  archiveAction: PropTypes.func.isRequired,
};

export { PersonListItem };
