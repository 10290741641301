import { PersonPreviewSerializer } from './person-preview-serializer';

class MeetingSerializer {
  static deSerialize(data) {
    return {
      title: data.title,
      id: data.id,
      date: data.start_date,
      duration: data.duration,
      person: data.enrolled_person
        ? PersonPreviewSerializer.deSerialize(data.enrolled_person) : undefined,
      guests: data.invited_users,
      location: data.location,
      description: data.description,
      notes: data.notes,
    };
  }

  static serialize(meeting) {
    return {
      title: meeting.title,
      id: meeting.id,
      start_date: meeting.date,
      duration: meeting.duration,
      enrolled_person_id: meeting.person?.id,
      invited_users: meeting.guests,
      location: meeting.location,
      description: meeting.description,
      notes: meeting.notes,
    };
  }
}

export { MeetingSerializer };
