class LoggedUserSerializer {
  static deSerialize(data) {
    return {
      id: data.id,
      email: data.email,
    };
  }
}

export { LoggedUserSerializer };
