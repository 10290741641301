import React from 'react';
import PropTypes from 'prop-types';
import UploadFile from '@mui/icons-material/UploadFile';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { strings } from '../../config/strings';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './import-csv-dialog.module.scss';

const Input = styled('input')({
  display: 'none',
});

const ImportCSVDialog = (props) => {
  const {
    open, sendEnabled, onUpload, onConfirm, onCancel, fileName,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {strings.PEOPLE_UPLOAD_BUTTON}
      </DialogTitle>
      <DialogContent>
        <div className={styles.uploadFileContainer}>
          <>{ /* eslint-disable-next-line jsx-a11y/label-has-associated-control */ }</>
          <label htmlFor="contained-button-file">
            <Input
              accept="text/csv"
              id="contained-button-file"
              type="file"
              onChange={onUpload}
            />

            <Button
              variant="contained"
              component="span"
              className={globalStyles.primaryButton}
              color="primary"
              startIcon={<UploadFile />}
            >
              {strings.PEOPLE_UPLOAD_CSV_FILE_BUTTON}
            </Button>
          </label>

          <p>
            {fileName
              ? `${strings.PEOPLE_UPLOAD_CSV_SELECTED_FILE}
              ${fileName.slice(0, 15) + (fileName.length > 15 ? '...' : '')}`
              : strings.PEOPLE_UPLOAD_CSV_SELECT_A_FILE}
          </p>
        </div>

        <div className={styles.spacer} />

        <div className={styles.confirmCancelButtonsContainer}>
          <Button
            variant="contained"
            component="span"
            disabled={!sendEnabled}
            className={
              sendEnabled ? globalStyles.primaryButton : globalStyles.buttonDisabled
            }
            onClick={onConfirm}
          >
            {strings.UTILS_UPLOAD}
          </Button>
          <Button
            variant="contained"
            component="span"
            className={globalStyles.secondaryButton}
            onClick={onCancel}
          >
            {strings.UTILS_CANCEL}
          </Button>
        </div>

      </DialogContent>

    </Dialog>
  );
};

ImportCSVDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sendEnabled: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
};

export { ImportCSVDialog };
