import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { Person } from '../../models/person';
import { PersonSerializer } from '../serializers/person-serializer';
import { EnrollmentRequestSerializer } from '../serializers/enrollment-request-serializer';

class PersonController {
  static async getPerson(id) {
    return ApiService.get(`${API_ROUTES.PEOPLE}/${id}`)
      .then((response) => new Person(PersonSerializer.deSerialize(response.data)));
  }

  static async enrollPerson(EnrollmentRequest) {
    return ApiService.post(API_ROUTES.PEOPLE, {
      enrolled_person: EnrollmentRequestSerializer.serialize(EnrollmentRequest),
    });
  }

  static async updatePerson(person) {
    return ApiService.put(`${API_ROUTES.PEOPLE}/${person.id}`, {
      enrolled_person: PersonSerializer.serialize(person),
    });
  }

  static async subscribeToPerson(id, email) {
    return ApiService.put(`${API_ROUTES.PEOPLE}/${id}/subscription`, {
      subscriber: email,
    })
      .then((response) => new Person(PersonSerializer.deSerialize(response.data)));
  }
}

export { PersonController };
