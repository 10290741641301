import { React } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import eventType from '../../config/event-types';
import { strings } from '../../config/strings';

const EventTypeSelector = ({ value, handleChange }) => (
  <FormControl fullWidth required margin="normal">
    <InputLabel id="Tipo-de-evento-label">{strings.EVENT_TYPE}</InputLabel>
    <Select
      size="small"
      labelId="Tipo-de-evento-label"
      id="Tipo-de-evento"
      value={value}
      label="Tipo-de-evento"
      onChange={handleChange}
    >
      {Object.entries(eventType).map(
        ([, val], index) => index !== 7
        && <MenuItem key={val.display} value={index}>{val.display}</MenuItem>,
      )}
    </Select>
  </FormControl>
);

EventTypeSelector.propTypes = {
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { EventTypeSelector };
