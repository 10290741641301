import React from 'react';

// TODO: Implement this component
const UnexpectedError = () => (
  <>
    <h1>An unexpected error has occured.</h1>
  </>
);

export { UnexpectedError };
