/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import courses from '../../config/courses';
import { GenericFormSelect } from '../create-event-modal/EventForms/common/generic-form-select';
import educationTypes from '../../config/education-types';
import institutionTypes from '../../config/institution-types';

const EducationCourseAndInstitutionSelects = ({
  educationType, course, institutionType, onChangeEducationType, onChangeCourse,
  onChangeInstitutionType, labelCourse, labelEducationType, labelInstitutionType,
}) => {
  const shouldShowTextField = ((educationType === educationTypes.OTHER.id)
    || (educationType === educationTypes.TERCIARY.id));
  const shouldShowSelect = !shouldShowTextField
    && educationType !== educationTypes.BABY_SCHOOL.id
    && educationType;
  const shouldShowInstitutionType = (
    ((institutionType && onChangeInstitutionType && labelInstitutionType) !== undefined)
    && educationType && educationType !== educationTypes.BABY_SCHOOL.id
  );

  let institutionTypesFiltered = institutionTypes;

  if (shouldShowInstitutionType) {
    const institutionTypesEntries = Object.entries(institutionTypes);

    if (educationType === educationTypes.KINDER.id) {
      institutionTypesFiltered = institutionTypesEntries.filter(
        (e) => (
          e[0] === 'PUBLIC_KINDER'
          || e[0] === 'PRIVATE_KINDER'
          || e[0] === 'CAIF'
          || e[0] === 'KIDS_CLUB'
        ),
      ).reduce(
        (b, v) => ({ ...b, [v[0]]: v[1] }), {},
      );
    } else if (educationType === educationTypes.PRIMARY_SCHOOL.id) {
      institutionTypesFiltered = institutionTypesEntries.filter(
        (e) => (
          e[0] === 'REGULAR_SCHOOL'
          || e[0] === 'SPECIAL_SCHOOL'
          || e[0] === 'REGULAR_PRIVATE_SCHOOL'
          || e[0] === 'SPECIAL_PRIVATE_SCHOOL'
          || e[0] === 'KIDS_CLUB'
        ),
      ).reduce((b, v) => ({ ...b, [v[0]]: v[1] }), {});
    } else if (educationType === educationTypes.HIGH_SCHOOL.id) {
      institutionTypesFiltered = institutionTypesEntries.filter(
        (e) => (
          e[0] === 'HIGH_SCHOOL'
          || e[0] === 'SPECIAL_PRIVATE_SCHOOL'
          || e[0] === 'UTU'
        ),
      ).reduce((b, v) => ({ ...b, [v[0]]: v[1] }), {});
    } else if (educationType === educationTypes.TERCIARY.id) {
      institutionTypesFiltered = institutionTypesEntries.filter(
        (e) => (e[0] === 'UNIVERSITY'),
      ).reduce((b, v) => ({ ...b, [v[0]]: v[1] }), {});
    } else {
      institutionTypesFiltered = institutionTypes;
    }
  }

  return (
    <>
      <GenericFormSelect
        value={educationType}
        id="educationTypeSelect"
        label={labelEducationType}
        onChange={onChangeEducationType}
        options={Object.values(educationTypes).map(
          ({ id, name }) => ({ value: id, label: name }),
        )}
        required
      />
      {shouldShowTextField
        && (
          <TextField
            size="small"
            margin="normal"
            id="courseInput"
            label={labelCourse}
            fullWidth
            value={course}
            onChange={onChangeCourse}
            required
          />
        )}
      {shouldShowSelect && (
        <GenericFormSelect
          value={course}
          id="courseSelect"
          label={labelCourse}
          onChange={onChangeCourse}
          required
          options={
            Object.entries(
              courses[Object.entries(educationTypes)
                /* eslint-disable-next-line */
                .find(([key, value]) => value.id === educationType)[0]],
            )
              .map(
                /* eslint-disable-next-line */
                ([key, value]) => ({ value, label: value }),
              )
          }
        />
      )}
      {!!shouldShowInstitutionType && (
        <GenericFormSelect
          value={institutionType}
          id="institutionTypeSelect"
          label={labelInstitutionType}
          required
          onChange={onChangeInstitutionType}
          options={
            Object.entries(institutionTypesFiltered).map(
              ([key, value]) => ({ value: key, label: value.name }),
            )
          }
        />
      )}
    </>
  );
};

EducationCourseAndInstitutionSelects.propTypes = {
  educationType: PropTypes.number,
  course: PropTypes.string.isRequired,
  institutionType: PropTypes.string,
  onChangeEducationType: PropTypes.func.isRequired,
  onChangeCourse: PropTypes.func.isRequired,
  onChangeInstitutionType: PropTypes.func,
  labelEducationType: PropTypes.string.isRequired,
  labelCourse: PropTypes.string.isRequired,
  labelInstitutionType: PropTypes.string,
};

EducationCourseAndInstitutionSelects.defaultProps = {
  educationType: undefined,
  institutionType: undefined,
  onChangeInstitutionType: undefined,
  labelInstitutionType: undefined,
};

export { EducationCourseAndInstitutionSelects };
