import { React } from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { strings } from '../../config/strings';

const DateTimePickerMeeting = ({ value, handleChange }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={2}>
      <DateTimePicker
        label={strings.MEETING_DATE}
        value={value}
        inputFormat="dd/MM/yyyy h:mm"
        showTimeSelect
        onChange={(e) => handleChange(e)}
        /* eslint-disable react/jsx-props-no-spreading */
        renderInput={(props) => (
          <TextField
            {...props}
            required
            className="primaryOutlinedTextField"
            variant="outlined"
            size="small"
            margin="normal"
          />
        )}
      />
    </Stack>
  </LocalizationProvider>

);

DateTimePickerMeeting.propTypes = {
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export { DateTimePickerMeeting };
