import { toEducationType } from '../../helpers/utils';

class PersonPreviewSerializer {
  static deSerialize(data) {
    return {
      id: data.id,
      contactName: data.contacts[0] ? data.contacts[0].name : '',
      name: data.name,
      motive: data.inquiry_motive,
      institutionLocality: data.institution_locality,
      educationType: toEducationType(data.education_type),
      archived: data.archived,
      createdAt: data.created_at,
    };
  }

  static serialize(personPreview) {
    return {
      id: personPreview.id,
      contact_name: personPreview.contactName,
      name: personPreview.name,
      motive: personPreview.motive,
      institution_locality: personPreview.institution,
      course: personPreview.course,
      archived: personPreview.archived,
      created_at: personPreview.createdAt,
    };
  }
}

export { PersonPreviewSerializer };
