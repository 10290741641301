import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { EventSerializer } from '../serializers/event-serializer';

class SendEventController {
  static createEvent(event) {
    const serializedEvent = EventSerializer.serialize(event);
    return ApiService.post(API_ROUTES.EVENTS, {
      event: serializedEvent,
    });
  }

  static editEvent(event, id) {
    const serializedEvent = EventSerializer.serialize(event);
    return ApiService.put(`${API_ROUTES.EVENTS}/${id}`, {
      event: serializedEvent,
    });
  }
}

export { SendEventController };
