import React from 'react';
import PropTypes from 'prop-types';
import { toTimeDifferenceString } from '../../helpers/utils';
import { SubscriptionAlert } from '../../models/alerts/subscription-alert';
import { AlertComponentContainer } from './alert-component-container';
import styles from './alert-components.module.scss';

const SubscriptionAlertComponent = ({ alert, onArchive, onClick }) => (
  <AlertComponentContainer
    onClick={() => onClick(alert.person.id)}
    onArchive={() => onArchive(alert)}
  >
    <p>
      <b>
        <i>
          {`${alert.author.split('@')[0]}`}
        </i>
      </b>
      <br />
      te suscribió a la trayectoria de
      <b>
        {` ${alert.person?.name} `}
      </b>
      <br />
    </p>
    <p className={styles.timeDifference}>
      hace
      {` ${toTimeDifferenceString(alert.date)}`}
    </p>
  </AlertComponentContainer>
);

SubscriptionAlertComponent.propTypes = {
  alert: PropTypes.instanceOf(SubscriptionAlert).isRequired,
  onArchive: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { SubscriptionAlertComponent };
