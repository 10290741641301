import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddIcon from '@mui/icons-material/Add';
import { CustomSnackbar } from '../../common/custom-snackbar';
import { Title } from '../../common/title/title';
import { fetchMeetings } from '../../store/slices/meetings-slice';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './meetings.module.scss';
import { TableWithLoading } from '../../common/table-with-loading/table-with-loading';
import { ConfirmDialog } from '../../common/confirm-dialog/confirm-dialog';
import { ErrorPage } from '../../common/error-page/error-page';
import { MeetingListItem } from '../../common/meeting-list-item/meeting-list-item';
import { MeetingsController } from '../../networking/controllers/meetings-controller';
import { strings } from '../../config/strings';
import { CreateMeetingModal } from '../../common/create-meeting-modal/create-meeting-modal';

const Meetings = () => {
  const state = useSelector((selector) => selector.meetings);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [meetingId, setMeetingId] = useState(null);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [openMeeting, setOpenMeeting] = useState(false);
  const [editingMeeting, setEditingMeeting] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMeetings({
        newPage: 0,
        newItemsPerPage: 10,
        pastNewPage: 0,
        showPast: false,
      }),
    );
  }, []);

  const meetingsTableCells = [
    'Fecha',
    'Duración',
    'Nombre de PSD',
    'Invitados',
    'Descripción',
    '',
  ];

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (message) => {
    setSnackSeverity('success');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(fetchMeetings({
      newPage: state.showPast ? state.currentPage : newPage,
      newItemsPerPage: state.itemsPerPage,
      pastNewPage: state.showPast ? newPage : state.pastPage,
      showPast: state.showPast,
    }));
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(fetchMeetings({
      newPage: state.currentPage,
      newItemsPerPage: event.target.value,
      pastNewPage: state.pastPage,
      showPast: state.showPast,
    }));
  };

  const handleShowPastClick = () => {
    dispatch(fetchMeetings({
      newPage: state.currentPage,
      newItemsPerPage: state.itemsPerPage,
      pastNewPage: state.pastPage,
      showPast: !state.showPast,
    }));
  };

  const requestDeleteMeeting = async () => {
    try {
      await MeetingsController.deleteMeeting(meetingId);
      dispatch(
        fetchMeetings({
          newPage: state.currentPage,
          newItemsPerPage: state.itemsPerPage,
          pastNewPage: state.pastPage,
          showPast: state.showPast,
        }),
      );
      showSnackSuccess('Reunión eliminada con éxito');
    } catch (err) {
      showSnackError('Ocurrió un error al eliminar la reunión');
    }
  };

  const handleConfirmDelete = () => {
    requestDeleteMeeting();
    setOpenConfirmDeleteDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDeleteMeetingClick = (selectedMeetingId) => {
    setMeetingId(selectedMeetingId);
    setOpenConfirmDeleteDialog(true);
  };

  const handleMeetingClick = (meeting) => {
    setEditingMeeting(true);
    setCurrentMeeting(meeting);
    setOpenMeeting(true);
  };

  const handleCopyEmailsClick = (selectedMeetingId) => {
    const selectedMeeting = state.meetings.filter((meeting) => meeting.id === selectedMeetingId);
    const emails = selectedMeeting[0].guests.join();
    navigator.clipboard.writeText(emails);
  };

  return (
    <div className={globalStyles.genericContainer}>
      {state.error && <ErrorPage />}
      {(!state.error) && (
        <>
          <CustomSnackbar
            open={snackOpen}
            onClose={handleSnackClose}
            severity={snackSeverity}
            message={snackMessage}
          />

          <ConfirmDialog
            open={openConfirmDeleteDialog}
            title={strings.MEETINGS_DELETE}
            message={strings.MEETINGS_CONFIRM_DELETE}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
          />

          <Title
            text={state.showPast ? strings.MEETINGS_PAST : strings.MEETINGS_FUTURE}
          />
          <div className={styles.buttonsContainer}>
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<DateRangeIcon />}
              onClick={handleShowPastClick}
            >
              {!state.showPast ? strings.MEETINGS_SHOW_PAST : strings.MEETINGS_SHOW_FUTURE}
            </Button>
            <div className={styles.horizontalSpacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setOpenMeeting(true)}
            >
              {strings.MEETING_ARRANGE}
            </Button>
          </div>
          <TableWithLoading
            items={state.meetings.map((meeting) => (
              <MeetingListItem
                key={meeting.id}
                meeting={meeting}
                onClick={() => handleMeetingClick(meeting)}
                deleteAction={() => handleDeleteMeetingClick(meeting.id)}
                editAction={() => handleMeetingClick(meeting)}
                copyAction={() => handleCopyEmailsClick(meeting.id)}
              />
            ))}
            isLoading={state.loading}
            columns={meetingsTableCells}
            count={state.meetingsCount}
            rowsPerPage={state.itemsPerPage}
            page={state.showPast ? state.pastPage : state.currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
          <CreateMeetingModal
            open={openMeeting}
            handleClose={() => setOpenMeeting(false)}
            editing={editingMeeting}
            meeting={currentMeeting}
          />
        </>
      )}
    </div>
  );
};

export { Meetings };
