import { React } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { ButtonOverlayAction } from '../../models/button-overlay-action';
import styles from './button-overlay.module.scss';

const ButtonOverlay = ({ actions }) => (
  <div className={styles.buttonOverlay}>
    {
      actions.map((action) => {
        const Icon = action.icon;
        return (
          <Tooltip key={action.label} title={action.label}>
            <IconButton
              aria-label={action.label}
              onClick={(event) => {
                event.stopPropagation();
                action.action();
              }}
            >
              <Icon className={styles.icon} />
            </IconButton>
          </Tooltip>
        );
      })
    }
  </div>
);

ButtonOverlay.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.instanceOf(ButtonOverlayAction)).isRequired,
};

export { ButtonOverlay };
