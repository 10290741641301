import { LoggedUser } from '../../models/logged-user';
import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { LoggedUserSerializer } from '../serializers/logged-user-serializer';
import { AlertSerializer } from '../serializers/alert-serializer';

class LoggedUserController {
  static checkLoggedUser() {
    return ApiService.get(API_ROUTES.LOGIN)
      .then((response) => new LoggedUser(LoggedUserSerializer.deSerialize(response.data)));
  }

  static async getAlerts() {
    const response = await ApiService.get(API_ROUTES.SHOW_ALERTS);
    return response.data.map((alert) => AlertSerializer.deSerialize(alert));
  }

  static async markAlertSeen(id) {
    const response = await ApiService.put(`${API_ROUTES.ALERTS}/${id}/see`);
    return response.data;
  }

  static async archiveAlert(id) {
    const response = await ApiService.put(`${API_ROUTES.ALERTS}/${id}/archive`);
    return response.data;
  }
}

export { LoggedUserController };
