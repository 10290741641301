/*
  Add all API paths used on the app here so that
  they are centrally documented.
*/

const API_ROUTES = {
  MESSAGES: '/inquiries',
  MEETINGS: '/meetings',
  LOGIN: '/session',
  PEOPLE: '/enrolled_people',
  LOGOUT: '/session',
  IMPORT_ENROLLED_PEOPLE: '/enrolled_people/import',
  EVENTS: '/events',
  PASSWORD_CHANGE: '/users',
  USERS_INDEX: '/users',
  ENROLLED_PEOPLE_COUNT: '/statistics/enrolled_people_count',
  EDUCATION_TYPE_STATISTIC: '/statistics/educational_level_distribution',
  INFORMED_EXCLUSION_STATISTICS: '/statistics/exclution_distribution',
  MOTIVE_DISTRIBUTION_STATISTICS: '/statistics/inquiry_motive_distribution',
  INQUIRY_AMOUNT_STATISTICS: '/statistics/inquiries_and_inscriptions_distribution',
  EDUCATION_RESULTS_STATISTICS: '/statistics/education_results_distribution',
  SHOW_ALERTS: '/users/show_alerts',
  ALERTS: '/alerts',
};

export { API_ROUTES };
