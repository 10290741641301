import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import DatePicker from '@mui/lab/DatePicker';
import { strings } from '../../config/strings';
import { Title } from '../../common/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './education-results-distribution.module.scss';
import { EnrolledPeopleComponent } from '../../common/enrolled-people/enrolled-people';
import { PieChart } from '../../common/pie-chart';
import { fetchEducationResults } from '../../store/slices/education-results-slice';

const toData = (object, datasetName) => ({
  labels: [...(Object.keys(object).map((name) => name))],
  datasets: {
    [datasetName]:
      { values: [...(Object.values(object).map((value) => value))] },
  },
});

const EducationResultsDistribution = () => {
  const today = new Date();

  const [date, setDate] = useState(today);
  const [errorRequiredDate, setErrorRequiredDate] = useState(false);

  const state = useSelector((selector) => selector.educationResults);
  const dispatch = useDispatch();

  const kinderData = toData(state.educationResults.KINDER);
  const primarySchoolData = toData(state.educationResults.PRIMARY_SCHOOL);
  const middleBasicData = toData(state.educationResults.MIDDLE_BASIC);
  const middleUpperData = toData(state.educationResults.MIDDLE_UPPER);

  const fetchData = (newYear) => {
    if (newYear) {
      dispatch(fetchEducationResults(newYear));
    }
  };

  useEffect(() => fetchData(date), []);

  const onDateChanged = (e) => {
    setDate(e);
    setErrorRequiredDate(!e);
    if (e) {
      const year = (new Date(e)).getFullYear();
      if (year.toString().length === 4) {
        fetchData(year);
      }
    }
  };

  const renderDatePicker = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          views={['year']}
          label={strings.UTILS_YEAR}
          value={date?.toLocaleDateString('en-UY') ?? ''}
          maxDate={new Date()}
          onChange={onDateChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorRequiredDate}
              helperText={`
                ${errorRequiredDate ? strings.UTILS_DATE_REQUIRED_RANGE : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  return (
    <div className={globalStyles.genericContainer}>
      <div className={styles.titleAndEnrolledPeople}>
        <Title text={strings.EDUCATION_RESULTS_DISTRIBUTION_TITLE} />
        <EnrolledPeopleComponent />
      </div>
      <div className={styles.buttonsContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {renderDatePicker()}
        </LocalizationProvider>
        <div className={styles.heightSpacer} />
      </div>
      <div className={styles.heightSpacer} />
      <div className={styles.chartsContainer}>
        <div className={styles.charts}>
          <h3>Inicial</h3>
          <div>
            <PieChart
              data={kinderData}
            />
          </div>
        </div>
        <div className={styles.charts}>
          <h3>Primaria</h3>
          <div>
            <PieChart
              data={primarySchoolData}
            />
          </div>
        </div>
        <div className={styles.charts}>
          <h3>Educación media básica</h3>
          <div>
            <PieChart
              data={middleBasicData}
            />
          </div>
        </div>
        <div className={styles.charts}>
          <h3>Educación media superior</h3>
          <div>
            <PieChart
              data={middleUpperData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { EducationResultsDistribution };
