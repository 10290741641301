class EnrollmentRequestSerializer {
  static serialize(request) {
    return {
      inquiry_id: request.inquiryId,
      contact_name: request.contactName,
      relationship_type: request.relationshipType,
      contact_phone_number: request.contactPhoneNumber,
      contact_email: request.contactEmail,
      name: request.name,
      birth_date: request.birthDate,
      inquiry_motive: request.inquiryMotive,
      inquiry_description: request.inquiryDescription,
      institution_type: request.institutionType.id,
      institution_name: request.institutionName,
      institution_locality: request.institutionLocality,
      course: request.course,
      suggestion: request.suggestions,
      followup: request.followup,
      comments: request.comments,
      education_type: request.educationType.id,
    };
  }
}

export { EnrollmentRequestSerializer };
