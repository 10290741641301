import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const PrimaryTextfield = ({
  value,
  onChange,
  label,
  InputProps,
  className,
  multiline,
  fullWidth,
  required,
  helperText,
  validation,
}) => (
  <TextField
    size="small"
    value={value}
    onChange={onChange}
    variant="outlined"
    className={`primaryOutlinedTextField ${className}`}
    label={label}
    multiline={multiline}
    rows={multiline ? 3 : 1}
    InputProps={InputProps}
    fullWidth={fullWidth}
    required={required}
    error={(required && !value) || !validation(value)}
    helperText={helperText}
  />
);

PrimaryTextfield.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  InputProps: PropTypes.object,
  className: PropTypes.string,
  multiline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  validation: PropTypes.func,
};

PrimaryTextfield.defaultProps = {
  InputProps: {},
  className: '',
  multiline: false,
  fullWidth: false,
  required: false,
  helperText: '',
  validation: () => true,
};

export { PrimaryTextfield };
