import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { EducationTypeStatistic } from '../../models/education-level-statistic';
import { EducationTypeStatisticSerializer } from '../serializers/education-level-statistic-serializer';

class EducationTypeStatisticController {
  static async getEducationLevelStatistic(date) {
    const getYear = date.getFullYear();
    return ApiService.get(API_ROUTES.EDUCATION_TYPE_STATISTIC, { year: `${getYear}` })
      // eslint-disable-next-line max-len
      .then((response) => new EducationTypeStatistic(EducationTypeStatisticSerializer.deSerialize(response.data)));
  }
}

export { EducationTypeStatisticController };
