/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatisticsController } from '../../networking/controllers/statistics-controller';

const initialState = {
  dataInquiries: {},
  loading: false,
  error: '',
};

const fetchInquiryAmountStatistic = createAsyncThunk(
  'inquiryAmount/fetchStatistics',
  async ({ dateFrom, dateTo }) => StatisticsController.getInquiryAmountStatistic(
    dateFrom, dateTo,
  ),
);

const setLoading = (state) => {
  state.loading = true;
  state.error = false;
};

const setInquiryAmounts = (state, action) => {
  state.error = '';
  state.loading = false;
  state.dataInquiries = action.payload;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const inquiryAmountStatisticSlice = createSlice({
  name: 'inquiry-amount-statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInquiryAmountStatistic.pending, setLoading)
      .addCase(fetchInquiryAmountStatistic.fulfilled, setInquiryAmounts)
      .addCase(fetchInquiryAmountStatistic.rejected, setError);
  },
});

export { fetchInquiryAmountStatistic };

export default inquiryAmountStatisticSlice.reducer;
