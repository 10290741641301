import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RepeatIcon from '@mui/icons-material/Repeat';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EmailIcon from '@mui/icons-material/Email';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { DateRange } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAlerts,
  markAlertsSeen,
  archiveAlert,
} from '../../store/slices/session-slice';
import { EventAlert } from '../../models/alerts/event-alert';
import { EventAlertComponent } from '../alerts/event-alert-component';
import { SubscriptionAlertComponent } from '../alerts/subscription-alert-component';
import { AppLink, goToPage } from '../../routes';
import { useWindowSize } from '../../hooks/useWindowSize';
import { routes, routeNaming } from '../../routes/routes';
import ADdULogo from '../../assets/images/logo.png';
import styles from './header.module.scss';
import { strings } from '../../config/strings';
import { LogoutController } from '../../networking/controllers/logout-controller';

const Header = () => {
  const location = useLocation();
  const getRoute = (routeName) => routes.find((route) => route.name === routeName).path;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const { loggedUser, alerts } = useSelector((state) => state.session);
  const [width] = useWindowSize();
  const mobileBreakpoint = 968;
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };

  useEffect(() => {
    dispatch(fetchAlerts());
    const interval = setInterval(() => {
      dispatch(fetchAlerts());
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const notSeenAlerts = alerts.filter((alert) => !alert.seen);

  const handleSeen = () => dispatch(markAlertsSeen(notSeenAlerts));

  useEffect(() => {
    if (notificationDrawerOpen) {
      handleSeen();
    }
  }, [notificationDrawerOpen]);

  const handleArchive = (alert) => {
    if (alerts.length === 1) {
      setNotificationDrawerOpen(false);
    }
    dispatch(archiveAlert(alert));
  };

  const handleSubscriptionrAlertOnClick = (id) => {
    goToPage(routeNaming.PERSON_DETAIL, { id });
  };

  const handleEventAlertOnClick = (id, eventId) => {
    goToPage(routeNaming.PERSON_DETAIL, { id }, { eventId });
  };

  const onClickLogout = async () => {
    await LogoutController.logout();
    goToPage(routeNaming.LOGIN);
  };

  return (
    <header className={styles.mainHeader}>
      <div className={styles.header}>
        {(width <= mobileBreakpoint)
          && (
            <IconButton className={styles.hamburgerMenu} onClick={openMobileMenu}>
              <MenuRoundedIcon className={styles.hamburger} />
            </IconButton>
          )}
        <Tooltip title="Notificaciones">
          <IconButton
            className={styles.badge}
            disabled={alerts.length === 0}
            onClick={(event) => {
              event.stopPropagation();
              setNotificationDrawerOpen(!notificationDrawerOpen);
            }}
          >
            <Badge color="secondary" badgeContent={notSeenAlerts.length}>
              {alerts.length > 0
                ? <NotificationsActiveIcon className={styles.icon} />
                : <NotificationsIcon className={styles.icon} />}
            </Badge>
          </IconButton>
        </Tooltip>
        <Drawer
          anchor="right"
          open={notificationDrawerOpen}
          onClose={() => { setNotificationDrawerOpen(false); }}
          PaperProps={{ className: styles.drawer }}
        >
          {alerts.map((alert) => {
            if (alert instanceof EventAlert) {
              return (
                <EventAlertComponent
                  alert={alert}
                  onArchive={handleArchive}
                  onClick={handleEventAlertOnClick}
                />
              );
            }
            return (
              <SubscriptionAlertComponent
                alert={alert}
                onArchive={handleArchive}
                onClick={handleSubscriptionrAlertOnClick}
              />
            );
          })}
        </Drawer>
        <AppLink routeName={routeNaming.HOME}>
          <figure>
            <img alt={strings.ADDU_LOGO_DESCRIPTION} src={ADdULogo} />
          </figure>
        </AppLink>
      </div>
      {(width > mobileBreakpoint)
        ? (
          <div className={styles.mainNavigation}>
            <ul className={styles.leftMenu}>
              <AppLink routeName={routeNaming.MESSAGES}>
                <li
                  className={
                    location.pathname === getRoute(routeNaming.MESSAGES)
                      ? styles.selectedAppLink
                      : styles.appLink
                  }
                >
                  {strings.HEADER_LINK_MESSAGES}
                </li>
              </AppLink>
              <AppLink routeName={routeNaming.PEOPLE}>
                <li
                  className={
                    location.pathname === getRoute(routeNaming.PEOPLE)
                      ? styles.selectedAppLink
                      : styles.appLink
                  }
                >
                  {strings.HEADER_LINK_PEOPLE}
                </li>
              </AppLink>
              <AppLink routeName={routeNaming.STATISTICS}>
                <li className={
                  (location.pathname === getRoute(routeNaming.STATISTICS))
                    ? styles.selectedAppLink
                    : styles.appLink
                }
                >
                  {strings.HEADER_LINK_STATISTICS}
                </li>
              </AppLink>
              <AppLink routeName={routeNaming.DOWNLOADS}>
                <li
                  className={
                    location.pathname === getRoute(routeNaming.DOWNLOADS)
                      ? styles.selectedAppLink
                      : styles.appLink
                  }
                >
                  {strings.HEADER_LINK_DOWNLOAD}
                </li>
              </AppLink>
              <AppLink routeName={routeNaming.MEETINGS}>
                <li
                  className={
                    location.pathname === getRoute(routeNaming.MEETINGS)
                      ? styles.selectedAppLink
                      : styles.appLink
                  }
                >
                  {strings.HEADER_LINK_MEETINGS}
                </li>
              </AppLink>
            </ul>
            <ul className={styles.rightMenu}>
              <AppLink routeName={routeNaming.PASSWORD_CHANGE_SCREEN}>
                <li
                  className={
                    location.pathname === getRoute(routeNaming.PASSWORD_CHANGE_SCREEN)
                      ? styles.selectedAppLink
                      : styles.appLink
                  }
                >
                  {strings.HEADER_LINK_PASSWORD}
                </li>
              </AppLink>
              <li onClick={onClickLogout} role="none">
                {strings.HEADER_LINK_LOGOUT}
              </li>
            </ul>
          </div>
        ) : (
          <>
            <div className={`${styles.mobileMenu} ${mobileMenuOpen ? styles.open : styles.closed}`}>
              <div className={styles.userInfo}>
                <h5>
                  {loggedUser ? loggedUser.email : ''}
                </h5>
              </div>
              <ul>
                <li>
                  <AppLink routeName={routeNaming.PEOPLE}>
                    <Button
                      startIcon={<AssignmentIndIcon />}
                      className={styles.mobileMenuButton}
                    >
                      {strings.HEADER_LINK_PEOPLE}
                    </Button>
                  </AppLink>
                </li>
                <li>
                  <AppLink routeName={routeNaming.MESSAGES}>
                    <Button
                      startIcon={<EmailIcon />}
                      className={styles.mobileMenuButton}
                    >
                      {strings.HEADER_LINK_MESSAGES}
                    </Button>
                  </AppLink>
                </li>
                <li>
                  <AppLink routeName={routeNaming.STATISTICS}>
                    <Button
                      startIcon={<AssessmentIcon />}
                      className={styles.mobileMenuButton}
                    >
                      {strings.HEADER_LINK_STATISTICS}
                    </Button>
                  </AppLink>
                </li>
                <li>
                  <AppLink routeName={routeNaming.DOWNLOADS}>
                    <Button
                      startIcon={<CloudDownloadIcon />}
                      className={styles.mobileMenuButton}
                    >
                      {strings.HEADER_LINK_DOWNLOAD}
                    </Button>
                  </AppLink>
                </li>
                <li>
                  <AppLink routeName={routeNaming.MEETINGS}>
                    <Button
                      startIcon={<DateRange />}
                      className={styles.mobileMenuButton}
                    >
                      {strings.HEADER_LINK_MEETINGS}
                    </Button>
                  </AppLink>
                </li>
              </ul>
              <div className={styles.logout}>
                <AppLink routeName={routeNaming.PASSWORD_CHANGE_SCREEN}>
                  <Button
                    startIcon={<RepeatIcon />}
                    className={styles.mobileMenuButton}
                  >
                    {strings.HEADER_LINK_PASSWORD}
                  </Button>
                </AppLink>
                <Button
                  startIcon={<ArrowBackRoundedIcon />}
                  className={styles.mobileMenuButton}
                  onClick={onClickLogout}
                >
                  {strings.HEADER_LINK_LOGOUT}
                </Button>
              </div>
            </div>
            <div
              role="none"
              onClick={() => setMobileMenuOpen(false)}
              className={`${styles.closeMenu} ${mobileMenuOpen && styles.open}`}
            />
          </>
        )}
    </header>
  );
};

export { Header };
