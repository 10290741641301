import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { EducationCourseAndInstitutionSelects } from '../../education-course-and-institution-selects/education-course-and-institution-selects';
import { strings } from '../../../config/strings';
import educationTypes from '../../../config/education-types';
import courseEventResults from '../../../config/course-event-results';
import { GenericFormSelect } from './common/generic-form-select';
import { DatePickerEvent } from '../date-picker-event';

const CourseEventForm = ({ values, changeValues, date }) => (
  <>
    <EducationCourseAndInstitutionSelects
      educationType={values.educationType?.id}
      course={values.educationLevel}
      onChangeEducationType={
        (e) => {
          changeValues({
            educationLevel: '',
            educationType: Object.values(educationTypes)
              .find((value) => value.id === e.target.value),
          });
        }
      }
      onChangeCourse={(e) => changeValues({ educationLevel: e.target.value })}
      labelEducationType={strings.EVENT_CARD_EDUCATION_TYPE}
      labelCourse={strings.EVENT_CARD_GRADE}
    />
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={values.repeating}
            onChange={(e) => changeValues({ repeating: e.target.checked })}
          />
          )}
        label={strings.EVENT_REPEATING_COURSE}
      />
    </FormGroup>
    <GenericFormSelect
      value={values.result}
      id="resultSelect"
      label={strings.EVENT_CARD_RESULT}
      onChange={(e) => {
        if (e.target.value === 3) {
          changeValues({
            result: e.target.value,
            endDate: undefined,
          });
        } else {
          changeValues({
            result: e.target.value,
            endDate: values.endDate || new Date(),
          });
        }
      }}
      options={Object.values(courseEventResults).map(
        ({ id, name }) => ({ value: id, label: name }),
      )}
      required
    />
    {values.endDate
    && (
    <DatePickerEvent
      value={values.endDate}
      handleChange={(ev) => changeValues({
        endDate: ev,
      })}
      label={strings.EVENT_END_DATE}
      eventType={4}
      minDate={date}
    />
    )}
  </>
);

CourseEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
};

export { CourseEventForm };
