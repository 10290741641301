import { toEducationType } from '../../helpers/utils';

class MessagePreviewSerializer {
  static deSerialize(data) {
    return {
      id: data.id,
      contactName: data.contact_name,
      name: data.name,
      motive: data.motive,
      institutionLocality: data.institution_locality,
      educationType: toEducationType(data.education_type),
      date: Date.parse(data.created_at),
      archived: data.archived,
    };
  }

  static serialize(messagePreview) {
    return {
      id: messagePreview.id,
      contact_name: messagePreview.contactName,
      name: messagePreview.name,
      motive: messagePreview.motive,
      institution_locality: messagePreview.institution,
      education_type: messagePreview.educationType.id,
      date: messagePreview.date,
      archived: messagePreview.archived,
    };
  }
}

export { MessagePreviewSerializer };
