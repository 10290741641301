import React from 'react';
import PropTypes from 'prop-types';
import styles from './statistics-preview.module.scss';

const StatisticsPreview = ({ title, children, onClick }) => (
  /* eslint-disable-next-line */
  <div className={styles.container} onClick={onClick} >
    <p>{title}</p>
    <div className={styles.children}>
      {children}
    </div>
  </div>
);

StatisticsPreview.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
};

StatisticsPreview.defaultProps = {
  onClick: () => {},
};

export { StatisticsPreview };
