const discriminationTypes = {
  LACK_OF_STAFF: 'Negaron inscripción por falta de personal capacitado',
  NO_QUOTA: 'Negaron inscripción por estar cubierto el cupo',
  SCHEDULE_REDUCTION: 'Reducción de días, horarios y actividades',
  SCHOLARSHIP_DEMAND: 'Exigencia de escolaridad compartida con la educación especial',
  PERSONAL_ASISTANT_DEMAND: 'Exigencia de asistente personal o acompañante terapéutico',
  OTHER: 'Otros',
};

export default discriminationTypes;
