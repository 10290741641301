import React, { useEffect, useState } from 'react';

import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { strings } from '../../config/strings';
import { Title } from '../../common/title';
import { CheckboxList } from '../../common/checkbox-list';
import { CustomSnackbar } from '../../common/custom-snackbar';
import { parseDate } from '../../helpers/utils';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './informed-exclusion-statistics.module.scss';
import { EnrolledPeopleComponent } from '../../common/enrolled-people/enrolled-people';
import {
  StatisticsController,
} from '../../networking/controllers/statistics-controller';
import { InformedExclusionChart } from '../../common/informed-exclusion-chart';

const InformedExclusionStatistics = () => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const [errorDateFrom, setErrorDateFrom] = useState(false);
  const [errorDateTo, setErrorDateTo] = useState(false);
  const [errorRequiredDateTo, setErrorRequiredDateTo] = useState(false);
  const [errorRequiredDateFrom, setErrorRequiredDateFrom] = useState(false);

  const [institutionTypes, setInstitutionTypes] = useState([]);
  const [institutionTypesSelected, setInstitutionTypesSelected] = useState([]);

  const [showCheckboxList, setShowCheckboxList] = useState(false);
  const [statisticsEndpointResponse, setStatisticsEndpointResponse] = useState(null);

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const onDateFromChanged = (e) => {
    setDateFrom(e);
    setErrorDateFrom(false);
    setErrorRequiredDateFrom(false);
  };

  const onDateToChanged = (e) => {
    setDateTo(e);
    setErrorDateTo(false);
    setErrorRequiredDateTo(false);
  };

  const parseInstitutions = (res) => {
    const institutionLabels = Object.keys(res).map(
      (label, index) => ({ name: label, id: index }),
    );
    setInstitutionTypes(institutionLabels);
  };

  useEffect(() => {
    if (statisticsEndpointResponse) parseInstitutions(statisticsEndpointResponse);
  }, [statisticsEndpointResponse]);

  const renderDatePickerFrom = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_FROM}
          inputFormat="dd/MM/yyyy"
          value={dateFrom?.toLocaleDateString('en-UY') ?? ''}
          maxDate={new Date()}
          onChange={onDateFromChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorDateFrom}
              helperText={`
                ${errorDateFrom ? strings.UTILS_DATE_FROM_HELPER : ''}
                ${errorRequiredDateFrom ? strings.UTILS_DATE_REQUIRED_HELPER : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  const renderDatePickerTo = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_TO}
          inputFormat="dd/MM/yyyy"
          value={dateTo?.toLocaleDateString('en-UY') ?? ''}
          maxDate={new Date()}
          onChange={onDateToChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorDateTo}
              helperText={`
                ${errorRequiredDateTo ? strings.UTILS_DATE_REQUIRED_HELPER : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  const handleGetDataButtonClick = async () => {
    if (dateFrom > dateTo) {
      setErrorDateFrom(true);
    } else if (dateFrom === '') {
      setErrorRequiredDateFrom(true);
    } else if (dateTo === '') {
      setErrorRequiredDateTo(true);
    } else {
      const dateFromString = parseDate(dateFrom);
      const dateToString = parseDate(dateTo);
      try {
        const result = await StatisticsController.getExclusionStatistics(
          dateFromString, dateToString,
        );
        setStatisticsEndpointResponse(result.data);
        setShowCheckboxList(true);
        setInstitutionTypesSelected([0, 3, 4]);
      } catch {
        setShowCheckboxList(false);
        showSnackError(strings.INFORMED_EXC_STATISTICS_RETRIEVE_ERROR_SNACK);
      }
    }
  };

  const renderGetDataButton = () => (
    <Stack spacing={2}>
      <Button
        className={globalStyles.primaryButton}
        variant="contained"
        color="primary"
        startIcon={<CompareArrowsIcon />}
        onClick={handleGetDataButtonClick}
      >
        {strings.INFORMED_EXC_STATISTICS_GET_DATA_BUTTON_LABEL}
      </Button>
    </Stack>
  );

  const handleCheckboxClick = (event, id) => {
    // add or remove the ids from the array that renders the data.
    if (event.target.checked) {
      if (institutionTypesSelected.length < 4) {
        const updateInstitutionTypesSelected = institutionTypesSelected.slice();
        updateInstitutionTypesSelected.push(id);
        setInstitutionTypesSelected(updateInstitutionTypesSelected);
      } else {
        showSnackError(strings.INFORMED_EXC_STATISTICS_CHECKLIST_ERROR_SNACK);
      }
    } else {
      setInstitutionTypesSelected(institutionTypesSelected.filter((i) => i !== id));
    }
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  return (
    <div className={globalStyles.genericContainer}>
      <CustomSnackbar
        open={snackOpen}
        onClose={handleSnackClose}
        severity={snackSeverity}
        message={snackMessage}
      />

      <div className={styles.titleAndEnrolledPeople}>
        <Title text={strings.INFORMED_EXC_STATISTICS_TITLE} />
        <EnrolledPeopleComponent />
      </div>
      <div className={styles.buttonsContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {renderDatePickerFrom()}
          {renderDatePickerTo()}
        </LocalizationProvider>
        <div className={styles.heightSpacer} />
        {renderGetDataButton()}
      </div>
      <div className={styles.heightSpacer} />
      <div className={styles.contentContainer}>
        <div className={styles.institutionPickContainer}>
          {
            showCheckboxList
            && (
              <CheckboxList
                display={false}
                title={strings.INFORMED_EXC_STATISTICS_CHECKLIST_TITLE}
                values={institutionTypes}
                selectedValues={institutionTypesSelected}
                onClick={handleCheckboxClick}
              />
            )
          }
        </div>
        <div className={styles.chartsContainer}>
          <InformedExclusionChart
            statisticsEndpointResponse={statisticsEndpointResponse}
            institutionTypesSelected={institutionTypesSelected}
          />
          <div className={styles.heightSpacer} />
        </div>
      </div>
    </div>
  );
};

export { InformedExclusionStatistics };
