import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';

class LogoutController {
  static logout() {
    return ApiService.delete(API_ROUTES.LOGOUT, {});
  }
}

export { LogoutController };
