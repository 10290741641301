/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatisticsController } from '../../networking/controllers/statistics-controller';

const initialState = {
  distribution: {},
  loading: false,
  error: false,
};

const fetchMotiveDistribution = createAsyncThunk(
  'motiveDistribution/fetchPersonStatus',
  async ({ dateFrom, dateTo }) => StatisticsController.getMotivesDistribution(dateFrom, dateTo),
);

const setLoading = (state) => {
  state.error = '';
  state.loading = true;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const setPerson = (state, action) => {
  state.loading = false;
  state.error = '';
  state.distribution = action.payload;
};

const motiveDistributionSlice = createSlice({
  name: 'motiveDistribution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMotiveDistribution.pending, setLoading)
      .addCase(fetchMotiveDistribution.fulfilled, setPerson)
      .addCase(fetchMotiveDistribution.rejected, setError);
  },
});

export { fetchMotiveDistribution };

export default motiveDistributionSlice.reducer;
