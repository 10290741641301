import educationTypes from '../config/education-types';

/**
 * Utility function to concat classNames.
 *
 * Usage: classnames('css_class1', 'css_class1')
 *
 * Can be used with objects where the keys are css classes and the
 * values are booleans that decide if classes are active or not:
 *
 * Example: classnames('input', { 'input-error': has_errors })
 *
 * @param  {...any} args
 * @returns string
 */
function classnames(...args) {
  if (args.length === 1) {
    const [firstEntry] = args;
    if (firstEntry && typeof firstEntry === 'object') {
      /* firstEntry's keys whose value is truthy */
      const activeClasses = Object.entries(firstEntry)
        .filter(([, value]) => value).map(([key]) => key);
      return activeClasses.join(' ');
    }
    return firstEntry;
  }
  return args.filter((entry) => !!entry).map((value) => classnames(value)).join(' ');
}

function parseDate(date) {
  const dateObject = new Date(date);
  const localeString = dateObject.toLocaleDateString('es-UY');
  return localeString.split(',')[0];
}

// Receives a date in 'YYYY/MM/DD' format and returns 'DD/MM/YYYY'
const formatDate = (date) => {
  const localeDate = ((new Date(date)).toLocaleDateString('es-UY'));
  const dateSplitted = localeDate.split('/');
  return `${dateSplitted[0]}/${dateSplitted[1]}/${dateSplitted[2]}`;
};

const toEducationType = (type) => {
  switch (type) {
    case 'maternal':
      return educationTypes.BABY_SCHOOL;
    case 'inicial':
      return educationTypes.KINDER;
    case 'primaria':
      return educationTypes.PRIMARY_SCHOOL;
    case 'secundaria':
      return educationTypes.HIGH_SCHOOL;
    case 'terciaria':
      return educationTypes.TERCIARY;
    case 'otro':
      return educationTypes.OTHER;
    default:
      return undefined;
  }
};

const getKey = (object, value) => Object.keys(object).find((key) => object[key] === value);

const toTimeDifferenceString = (date) => {
  const timeDifference = (new Date()) - (new Date(date));
  const diffDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(timeDifference / (1000 * 60 * 60));
  const diffMinutes = Math.ceil(timeDifference / (1000 * 60));
  const diffSeconds = Math.ceil(timeDifference / (1000));

  let diffDisplay;

  if (diffSeconds < 60) {
    diffDisplay = `${diffSeconds} segundos`;
  } else if (diffMinutes < 60) {
    diffDisplay = `${diffMinutes} minutos`;
  } else if (diffHours < 24) {
    diffDisplay = `${diffHours} horas`;
  } else {
    diffDisplay = `${diffDays} días`;
  }
  return diffDisplay;
};

export {
  classnames,
  parseDate,
  formatDate,
  toEducationType,
  getKey,
  toTimeDifferenceString,
};
