import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import styles from './pie-chart.module.scss';
import chartPalette from '../chart-palette';

const defaultOptions = {
  scales: {
    y: {
      display: false,
    },
  },
  maintainAspectRatio: false,
};

const PieChart = ({ data, options }) => {
  const dataset = {
    labels: data.labels,
    datasets: Object.entries(data.datasets).map(([key, value]) => ({
      label: key,
      data: value.values,
      backgroundColor: chartPalette,
      borderColor: chartPalette,
      borderWidth: 1,
    })),
  };
  return (
    <Doughnut
      data={dataset}
      options={{ ...defaultOptions, ...options }}
      className={styles.chart}
    />
  );
};

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

PieChart.defaultProps = {
  options: {},
};

export { PieChart };
