import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { strings } from '../../config/strings';
import { Title } from '../../common/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './inquiry-motives-distribution.module.scss';
import { EnrolledPeopleComponent } from '../../common/enrolled-people/enrolled-people';
import { MotiveDistributionChart } from '../../common/motive-distribution-chart';

const InquiryMotivesDistribution = () => {
  const today = new Date();
  const previousYear = today.getFullYear() - 1;
  const initialFrom = new Date();
  initialFrom.setFullYear(previousYear);

  const [dateTo, setDateTo] = useState(today);
  const [dateFrom, setDateFrom] = useState(initialFrom);

  const [errorDate, setErrorDate] = useState(false);
  const [errorRequiredDate, setErrorRequiredDate] = useState(false);

  const onDateFromChanged = (e) => {
    setDateFrom(e);
    setErrorDate(e > dateTo);
    setErrorRequiredDate(!e);
  };

  const onDateToChanged = (e) => {
    setDateTo(e);
    setErrorDate(e < dateFrom);
    setErrorRequiredDate(!e);
  };

  const renderDatePickerFrom = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_FROM}
          inputFormat="dd/MM/yyyy"
          value={dateFrom?.toLocaleDateString('en-UY') ?? ''}
          maxDate={new Date()}
          onChange={onDateFromChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorDate || errorRequiredDate}
              helperText={`
                ${errorDate ? strings.UTILS_DATE_RANGE : ''}
                ${errorRequiredDate ? strings.UTILS_DATE_REQUIRED_RANGE : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  const renderDatePickerTo = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_TO}
          inputFormat="dd/MM/yyyy"
          value={dateTo?.toLocaleDateString('en-UY') ?? ''}
          maxDate={new Date()}
          onChange={onDateToChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorDate || errorRequiredDate}
              helperText={`
                ${errorDate ? strings.UTILS_DATE_RANGE : ''}
                ${errorRequiredDate ? strings.UTILS_DATE_REQUIRED_RANGE : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  return (
    <div className={globalStyles.genericContainer}>
      <div className={styles.titleAndEnrolledPeople}>
        <Title text={strings.MOTIVE_DISTRIBUTION_TITLE} />
        <EnrolledPeopleComponent />
      </div>
      <div className={styles.buttonsContainer}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {renderDatePickerFrom()}
          {renderDatePickerTo()}
        </LocalizationProvider>
        <div className={styles.heightSpacer} />
      </div>
      <div className={styles.heightSpacer} />
      <div className={styles.chartsContainer}>
        <div className={styles.charts}>
          <MotiveDistributionChart dateFrom={dateFrom} dateTo={dateTo} />
        </div>
      </div>
    </div>
  );
};

export { InquiryMotivesDistribution };
