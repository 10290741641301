/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EducationTypeStatisticController } from '../../networking/controllers/education-level-statistic-controller';

const initialState = {
  educationLevels: {
    maternal: 0,
    inicial: 0,
    priamria: 0,
    secuandaria: 0,
    tercearia: 0,
  },
  loading: false,
  error: '',
};

const fetchEducationLevelStatistic = createAsyncThunk(
  'education-level/fetchStatistics',
  async (year) => EducationTypeStatisticController.getEducationLevelStatistic(year),
);

const setLoading = (state) => {
  state.loading = true;
  state.error = false;
};

const setEducationLevels = (state, action) => {
  state.error = '';
  state.loading = false;
  state.educationLevels = action.payload;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const educationLevelStatisticSlice = createSlice({
  name: 'education-level-statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducationLevelStatistic.pending, setLoading)
      .addCase(fetchEducationLevelStatistic.fulfilled, setEducationLevels)
      .addCase(fetchEducationLevelStatistic.rejected, setError);
  },
});

export { fetchEducationLevelStatistic };

export default educationLevelStatisticSlice.reducer;
