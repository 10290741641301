const chartPalette = [
  'rgba(239, 71, 111, 0.4)',
  'rgba(127, 235, 195, 0.4)',
  'rgba(255, 196, 61, 0.4)',
  'rgba(6, 214, 160, 0.4)',
  'rgba(248, 102, 36, 0.4)',
  'rgba(234, 53, 70, 0.4)',
  'rgba(27, 154, 170, 0.4)',
  'rgba(102, 46, 155, 0.4)',
];

export default chartPalette;
