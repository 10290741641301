/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PersonController } from '../../networking/controllers/person-controller';
import institutionTypes from '../../config/institution-types';
import educationTypes from '../../config/education-types';

const initialState = {
  person: {
    id: '',
    contactName: '',
    relationshipType: '',
    contactPhoneNumber: '',
    contactEmail: '',
    name: '',
    birthDate: '',
    motive: '',
    description: '',
    institutionType: institutionTypes.REGULAR_SCHOOL,
    institutionName: '',
    institutionLocality: '',
    educationType: educationTypes.BABY_SCHOOL,
    course: '',
    events: [],
    suggestions: '',
    followup: '',
    comments: '',
  },
  loading: false,
  error: false,
};

const fetchPerson = createAsyncThunk(
  'person/fetchPersonStatus',
  async (id) => PersonController.getPerson(id),
);

const setLoading = (state) => {
  state.error = '';
  state.loading = true;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const setPerson = (state, action) => {
  state.loading = false;
  state.error = '';
  state.person = action.payload;
};

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    updatePerson(state, action) {
      state.person = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerson.pending, setLoading)
      .addCase(fetchPerson.fulfilled, setPerson)
      .addCase(fetchPerson.rejected, setError);
  },
});

export { fetchPerson };

export const { updatePerson } = personSlice.actions;

export default personSlice.reducer;
