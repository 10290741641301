import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { MeetingSerializer } from '../serializers/meeting-serializer';

class MeetingController {
  static createMeeting(params) {
    const serializedMeeting = MeetingSerializer.serialize(params);
    return ApiService.post(API_ROUTES.MEETINGS, {
      meeting: serializedMeeting,
    });
  }

  static editMeeting(params, id) {
    const serializedMeeting = MeetingSerializer.serialize(params);
    return ApiService.put(`${API_ROUTES.MEETINGS}/${id}`, {
      meeting: serializedMeeting,
    });
  }
}

export { MeetingController };
