import { React } from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './list-item-skeleton.module.scss';

const ListItemSkeleton = ({ columnCount }) => (
  <TableRow className={styles.tableRow}>
    {/* eslint-disable react/no-array-index-key */}
    {
      Array(columnCount).fill(0).map((_, i) => (
        <TableCell key={i} className={styles.tableCell}>
          <Skeleton variant="text" />
        </TableCell>
      ))
    }
  </TableRow>
);

ListItemSkeleton.propTypes = {
  columnCount: PropTypes.number.isRequired,
};

export { ListItemSkeleton };
