class Event {
  constructor(params) {
    this.date = params.date;
    this.note = params.note;
    this.personId = params.personId;
    this.eventType = params.eventType;
    this.id = params.id;
  }
}

// Event type 0 = ExtraordinaryHelpEvent
// Event type 1 = DiscriminationEvent
// Event type 2 = EducationAbandonmentEvent
// Event type 3 = EgressEvent
// Event type 4 = CourseEvent
// Event type 5 = InstitutionEnrollmentEvent
// Event type 6 = InstitutionChangeEvent
// Event type 7 = Meeting

export { Event };
