/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Downloads } from '../../models/downloads';
import { DownloadsController } from '../../networking/controllers/downloads-controller';

const initialState = {
  editing: true,
  loading: false,
  error: '',
  success: false,
};

const submitDownloadsPerson = createAsyncThunk(
  'downloads/submitDownloadsPerson',
  async (dates) => {
    const exportPeople = new Downloads(dates);
    return DownloadsController.getExportPeople(exportPeople);
  },
);

const submitDownloadsEvents = createAsyncThunk(
  'downloads/submitDownloadsEvents',
  async (dates) => {
    const exportEvents = new Downloads(dates);
    return DownloadsController.getExportEvents(exportEvents);
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
  state.editing = false;
  state.success = false;
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.editing = true;
  state.success = false;
};

const setSuccess = (state) => {
  state.loading = false;
  state.error = '';
  state.editing = false;
  state.success = true;
};

const downloadsSlice = createSlice({
  name: 'downloads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitDownloadsPerson.pending, setLoading)
      .addCase(submitDownloadsPerson.fulfilled, setSuccess)
      .addCase(submitDownloadsPerson.rejected, setError)
      .addCase(submitDownloadsEvents.pending, setLoading)
      .addCase(submitDownloadsEvents.fulfilled, setSuccess)
      .addCase(submitDownloadsEvents.rejected, setError);
  },
});

export { submitDownloadsPerson, submitDownloadsEvents };

export default downloadsSlice.reducer;
