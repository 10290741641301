/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PersonPreviewController } from '../../networking/controllers/person-preview-controller';

const orders = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

const initialState = {
  people: [],
  currentPage: 0,
  archivedPage: 0,
  itemsPerPage: 10,
  peopleCount: 0,
  showArchived: false,
  error: '',
  loading: false,
};

const fetchPeople = createAsyncThunk(
  'people/fetchPeopleStatus',
  async ({
    newPage,
    newItemsPerPage,
    archivedNewPage,
    showArchived,
    search,
  }) => {
    const response = await PersonPreviewController.getPersonPreviews(
      orders.ASCENDING,
      newItemsPerPage,
      showArchived,
      showArchived ? archivedNewPage : newPage,
      (!search || search.length >= 3) ? search : '',
    );
    return {
      people: response.people,
      paging: {
        newPage: showArchived ? newPage : response.page,
        newItemsPerPage,
        archivedNewPage: showArchived ? response.page : archivedNewPage,
        count: response.count,
      },
      showArchived,
    };
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.error;
};

const setPeople = (state, action) => {
  const {
    newPage, newItemsPerPage, archivedNewPage, count,
  } = action.payload.paging;
  return {
    ...state,
    people: action.payload.people,
    archivedPage: archivedNewPage,
    currentPage: newPage,
    itemsPerPage: newItemsPerPage,
    peopleCount: count,
    showArchived: action.payload.showArchived,
    error: '',
    loading: false,
  };
};

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPeople.pending, setLoading)
      .addCase(fetchPeople.fulfilled, setPeople)
      .addCase(fetchPeople.rejected, setError);
  },
});

export { fetchPeople };

export default peopleSlice.reducer;
