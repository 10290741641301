class PersonPreview {
  constructor(params) {
    this.id = params.id;
    this.contactName = params.contactName;
    this.name = params.name;
    this.motive = params.motive;
    this.institutionLocality = params.institutionLocality;
    this.educationType = params.educationType;
    this.archived = params.archived;
    this.createdAt = params.createdAt;
  }
}

export { PersonPreview };
