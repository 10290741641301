import { Event } from './event';

class ExtraordinaryHelpEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.helpType = params.helpType;
  }
}

export { ExtraordinaryHelpEvent };
