import React from 'react';
import PropTypes from 'prop-types';
import styles from './value-or-blank-field-flex.module.scss';
import { PrimaryTextfield } from '../primary-textfield/primary-textfield';

const ValueOrBlankFieldFlex = ({
  label, value, editing, onChange, multiline, required, className,
}) => (
  editing
    ? (
      <PrimaryTextfield
        label={label}
        value={value}
        onChange={onChange}
        multiline={multiline}
        required={required}
        className={className}
      />
    )
    : (
      <>
        <p className={styles.label}>{label}</p>
        <p className={styles.value}>{value || '---'}</p>
      </>
    )
);

ValueOrBlankFieldFlex.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
};

ValueOrBlankFieldFlex.defaultProps = {
  value: '',
  editing: false,
  onChange: () => { },
  multiline: false,
  required: false,
  className: '',
};

export { ValueOrBlankFieldFlex };
