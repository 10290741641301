import React from 'react';
import PropTypes from 'prop-types';
import ArchiveIcon from '@mui/icons-material/Archive';
import IconButton from '@mui/material/IconButton';
import styles from './alert-components.module.scss';

const AlertComponentContainer = ({ onClick, onArchive, children }) => (
  <div className={styles.container}>
    <div className={styles.children} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      {children}
    </div>
    <div className={styles.actions}>
      <IconButton onClick={onArchive}>
        <ArchiveIcon className={styles.icon} />
      </IconButton>
    </div>
  </div>
);

AlertComponentContainer.propTypes = {
  children: PropTypes.element.isRequired,
  onArchive: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { AlertComponentContainer };
