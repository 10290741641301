import React from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ListItemSkeleton } from '../list-item-skeleton';
import styles from './table-with-loading.module.scss';

const TableWithLoading = ({
  columns,
  items,
  isLoading,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  count,
}) => (
  <>
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table className={styles.table} aria-label="Tabla de mensajes">
        <TableHead>
          <TableRow className={styles.tableHeader}>
            {columns.map((column) => <TableCell key={column}>{column}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading && items}
          {/* eslint-disable react/no-array-index-key */}
          {isLoading
            && Array(rowsPerPage).fill(0)
              .map((_, i) => <ListItemSkeleton key={i} columnCount={columns.length} />)}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  </>
);

TableWithLoading.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export { TableWithLoading };
