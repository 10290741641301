class EventAlert {
  constructor(params) {
    this.author = params.author;
    this.person = params.person;
    this.date = params.date;
    this.id = params.id;
    this.action = params.action;
    this.eventType = params.eventType;
    this.seen = params.seen;
    this.eventId = params.eventId;
  }
}

export { EventAlert };
