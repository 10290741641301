/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ExtraordinaryHelpEvent } from '../../models/events/extraordinary-help-event';
import { DiscriminationEvent } from '../../models/events/discrimination-event';
import { EducationAbandonmentEvent } from '../../models/events/education-abandonment-event';
import { EgressEvent } from '../../models/events/egress-event';
import { CourseEvent } from '../../models/events/course-event';
import { InstitutionEnrollmentEvent } from '../../models/events/institution-enrollment-event';
import { InstitutionChangeEvent } from '../../models/events/institution-change-event';
import { SendEventController } from '../../networking/controllers/send-event-controller';
import { FollowUpEvent } from '../../models/events/follow-up-event';

const initialState = {
  editing: true,
  loading: false,
  error: '',
  success: false,
  event: {},
};

const createEvent = (eventType, formValues) => {
  switch (eventType) {
    case 0:
      return new ExtraordinaryHelpEvent(formValues);
    case 1:
      return new DiscriminationEvent(formValues);
    case 2:
      return new EducationAbandonmentEvent(formValues);
    case 3:
      return new EgressEvent(formValues);
    case 4:
      return new CourseEvent(formValues);
    case 5:
      return new InstitutionEnrollmentEvent(formValues);
    case 6:
      return new InstitutionChangeEvent(formValues);
    case 8:
      return new FollowUpEvent(formValues);
    default:
      return new ExtraordinaryHelpEvent(formValues);
  }
};

const sendEvent = createAsyncThunk(
  'event/sendEventStatus',
  async ({ eventType, actualFormValues }) => {
    const createdEvent = createEvent(eventType, actualFormValues);
    return SendEventController.createEvent(createdEvent);
  },
);

const editEvent = createAsyncThunk(
  'event/editEventStatus',
  async ({ eventType, actualFormValues, id }) => {
    const createdEvent = createEvent(eventType, actualFormValues);
    return SendEventController.editEvent(createdEvent, id);
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
  state.editing = false;
  state.success = false;
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.editing = true;
  state.success = false;
};

const setSuccess = (state, action) => {
  state.loading = false;
  state.error = '';
  state.editing = false;
  state.success = true;
  state.event = action.payload;
};

const sendEventSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEvent.pending, setLoading)
      .addCase(sendEvent.fulfilled, setSuccess)
      .addCase(sendEvent.rejected, setError)
      .addCase(editEvent.pending, setLoading)
      .addCase(editEvent.fulfilled, setSuccess)
      .addCase(editEvent.rejected, setError);
  },
});

export { sendEvent, editEvent };

export default sendEventSlice.reducer;
