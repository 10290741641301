import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { MotiveDistributionSerializer } from '../serializers/motive-distribution-serializer';
import { InquiryAmountSerializer } from '../serializers/inquiry-amount-serializer';
import { parseDate } from '../../helpers/utils';

class StatisticsController {
  static getExclusionStatistics(dateFrom, dateTo) {
    return ApiService.get(
      `${API_ROUTES.INFORMED_EXCLUSION_STATISTICS}/?from_date=${dateFrom}&to_date=${dateTo}`,
    );
  }

  static async getMotivesDistribution(dateFrom, dateTo) {
    const response = await ApiService.get(
      `${API_ROUTES.MOTIVE_DISTRIBUTION_STATISTICS}/?from_date=${parseDate(dateFrom)}&to_date=${parseDate(dateTo)}`,
    );
    return MotiveDistributionSerializer.deSerialize(response.data);
  }

  static async getInquiryAmountStatistic(dateFrom, dateTo) {
    const response = await ApiService.get(
      `${API_ROUTES.INQUIRY_AMOUNT_STATISTICS}/?from_date=${dateFrom}&to_date=${dateTo}`,
    );
    return InquiryAmountSerializer.deSerialize(response.data);
  }

  static async getEducationResultsStatistics(date) {
    const response = await ApiService.get(
      API_ROUTES.EDUCATION_RESULTS_STATISTICS, { year: date },
    );

    return response.data;
  }
}

export { StatisticsController };
