class Meeting {
  constructor(params) {
    this.title = params.title;
    this.id = params.id;
    this.date = params.date;
    this.duration = params.duration;
    this.person = params.person;
    this.guests = params.guests;
    this.location = params.location;
    this.description = params.description;
    this.notes = params.notes;
  }
}

export { Meeting };
