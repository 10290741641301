import { Event } from './event';

class MeetingEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.meeting = params.meeting;
  }
}

export { MeetingEvent };
