import { React, useEffect, useState } from 'react';
import { LoadingIndicator } from '../loading-indicator';
import styles from './enrolled-people.module.scss';
import { EnrolledPeopleCountController } from '../../networking/controllers/enrolled_people_count_controller';

const EnrolledPeopleComponent = () => {
  const [enrolledPeople, setEnrolledPeople] = useState([]);
  const [enrolledPeopleError, setEnrolledPeopleError] = useState(false);

  useEffect(() => {
    const fetchEnrolledPeopleCount = async () => {
      try {
        const result = await EnrolledPeopleCountController.enrolledPeopleCount();
        setEnrolledPeople(result);
      } catch {
        setEnrolledPeopleError(true);
      }
    };

    fetchEnrolledPeopleCount();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <p>
          {/* eslint-disable-next-line no-nested-ternary */}
          {('active' in enrolledPeople)
            ? enrolledPeople.active
            : enrolledPeopleError
              ? 'Error obteniendo datos'
              : <LoadingIndicator />}
        </p>
        <p>
          {/* eslint-disable-next-line no-nested-ternary */}
          {('active' in enrolledPeople)
            ? 'Inscriptos'
            : ''}
        </p>
      </div>
    </div>
  );
};

export { EnrolledPeopleComponent };
