/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import educationTypes from '../../config/education-types';
import institutionTypes from '../../config/institution-types';
import { MessageController } from '../../networking/controllers/message-controller';

const initialState = {
  message: {
    id: '',
    contactName: '',
    relationshipType: '',
    contactPhoneNumber: '',
    contactEmail: '',
    name: '',
    birthDate: '',
    motive: '',
    description: '',
    institutionType: institutionTypes.REGULAR_SCHOOL,
    institutionName: '',
    institutionLocality: '',
    educationType: educationTypes.BABY_SCHOOL,
    course: '',
    date: '',
    archived: '',
  },
  loading: false,
  error: '',
};

const fetchMessage = createAsyncThunk(
  'message/fetchStatus',
  async (id) => MessageController.getMessage(id),
);

const setLoading = (state) => {
  state.loading = true;
  state.error = false;
};

const setMessage = (state, action) => {
  state.error = '';
  state.loading = false;
  state.message = action.payload;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    updateMessage(state, action) {
      state.message = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessage.pending, setLoading)
      .addCase(fetchMessage.fulfilled, setMessage)
      .addCase(fetchMessage.rejected, setError);
  },
});

export const { updateMessage } = messageSlice.actions;

export { fetchMessage };

export default messageSlice.reducer;
