import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { strings } from '../../../config/strings';
import { GenericFormSelect } from './common/generic-form-select';
import educationTypes from '../../../config/education-types';

const EducationAbandonmentEventForm = ({ values, changeValues }) => (
  <>
    <GenericFormSelect
      value={values.educationType?.id}
      id="educationTypeSelect"
      label={strings.EVENT_CARD_EDUCATION_TYPE}
      onChange={(e) => {
        changeValues({
          educationType: Object.values(educationTypes)
            .find((value) => value.id === e.target.value),
        });
      }}
      options={Object.values(educationTypes).map(
        ({ id, name }) => ({ value: id, label: name }),
      )}
      required
    />
    <TextField
      id="motive"
      size="small"
      margin="normal"
      label={strings.EVENT_CARD_MOTIVE}
      fullWidth
      value={values.motive}
      required
      onChange={(e) => changeValues({ motive: e.target.value })}
    />
  </>
);
EducationAbandonmentEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export { EducationAbandonmentEventForm };
