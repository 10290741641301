import { Event } from './event';

class InstitutionEnrollmentEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.institutionName = params.institutionName;
    this.institutionNumber = params.institutionNumber;
    this.institutionLocality = params.institutionLocality;
    this.institutionType = params.institutionType;
    // [
    //     escuela_comun
    //     escuela_especial
    //     colegio_comun
    //     colegio_especial
    //     liceo
    //     utu
    //     universidad
    //     club_de_ninos
    //     jardin_publico
    //     jardin_privado
    //     caif
    //   ]
  }
}

export { InstitutionEnrollmentEvent };
