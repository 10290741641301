import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import institutionTypes from '../../../config/institution-types';
import { GenericFormSelect } from './common/generic-form-select';
import { strings } from '../../../config/strings';

const InstitutionChangeEventForm = ({ values, changeValues }) => (
  <>
    <GenericFormSelect
      value={values.institutionType}
      id="institutionTypeSelect"
      label={strings.FORM_INSTITUTION_TYPE_LABEL}
      required
      onChange={(e) => {
        changeValues(
          { institutionType: e.target.value },
        );
      }}
      options={
            Object.entries(institutionTypes).map(
              ([key, value]) => ({ value: key, label: value.name }),
            )
          }
    />

    <TextField
      id="institutionNameForm"
      size="small"
      margin="normal"
      label={strings.MESSAGE_INSTITUTION_NAME}
      fullWidth
      required
      value={values.institutionName}
      onChange={(e) => changeValues({ institutionName: e.target.value })}
    />
    <TextField
      id="institutionLocalityForm"
      size="small"
      margin="normal"
      label={strings.FORM_INSTITUTION_LOCALITY_LABEL}
      fullWidth
      value={values.institutionLocality}
      onChange={(e) => changeValues({ institutionLocality: e.target.value })}
    />
    <TextField
      id="motiveForm"
      size="small"
      margin="normal"
      label={strings.EVENT_CARD_MOTIVE}
      fullWidth
      required
      value={values.motive}
      onChange={(e) => changeValues({ motive: e.target.value })}
    />
  </>
);
InstitutionChangeEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export { InstitutionChangeEventForm };
