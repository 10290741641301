import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import MessageIcon from '@mui/icons-material/Email';
import { CustomSnackbar } from '../../common/custom-snackbar';
import { MessagePreviewController } from '../../networking/controllers/message-preview-controller';
import { goToPage } from '../../routes';
import { routeNaming } from '../../routes/routes';
import { Title } from '../../common/title/title';
import { fetchMessages, SortingMethods } from '../../store/slices/messages-slice';
import { MessageListItem } from '../../common/message-list-item/message-list-item';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './messages.module.scss';
import { TableWithLoading } from '../../common/table-with-loading/table-with-loading';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';
import { ConfirmDialog } from '../../common/confirm-dialog/confirm-dialog';

const Messages = () => {
  const state = useSelector((selector) => selector.messages);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [openConfirmArchiveDialog, setOpenConfirmArchiveDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchMessages({
        newPage: 0,
        newItemsPerPage: 10,
        archivedNewPage: 0,
        showArchived: false,
        sortingMethod: SortingMethods.NEWEST_FIRST,
      }),
    );
  }, []);

  const messagesTableCells = [
    'Nombre del contacto',
    'Nombre de la PSD',
    'Motivo de consulta',
    'Localidad de la institución',
    'Nivel educativo',
    'Fecha',
    '',
  ];

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (message) => {
    setSnackSeverity('success');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(fetchMessages({
      newPage: state.showArchived ? state.currentPage : newPage,
      newItemsPerPage: state.itemsPerPage,
      archivedNewPage: state.showArchived ? newPage : state.archivedPage,
      showArchived: state.showArchived,
      sortingMethod: state.sortingMethod,
    }));
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(fetchMessages({
      newPage: state.currentPage,
      newItemsPerPage: event.target.value,
      archivedNewPage: state.archivedPage,
      showArchived: state.showArchived,
      sortingMethod: state.sortingMethod,
    }));
  };

  const handleSortingChange = (event) => {
    let sorting;
    switch (event.target.value) {
      case SortingMethods.NEWEST_FIRST.id:
        sorting = SortingMethods.NEWEST_FIRST;
        break;
      case SortingMethods.OLDEST_FIRST.id:
        sorting = SortingMethods.OLDEST_FIRST;
        break;
      default:
        sorting = SortingMethods.NEWEST_FIRST;
    }
    dispatch(fetchMessages({
      newPage: state.currentPage,
      newItemsPerPage: state.itemsPerPage,
      archivedNewPage: state.archivedPage,
      showArchived: state.showArchived,
      sortingMethod: sorting,
    }));
  };

  const handleShowArchivedClick = () => {
    dispatch(fetchMessages({
      newPage: state.currentPage,
      newItemsPerPage: state.itemsPerPage,
      archivedNewPage: state.archivedPage,
      showArchived: !state.showArchived,
      sortingMethod: state.sortingMethod,
    }));
  };

  const requestArchiveMessage = async () => {
    try {
      await MessagePreviewController.archiveMessage(selectedMessageId);
      // if success, update the data displayed on the screen.
      dispatch(
        fetchMessages({
          newPage: state.currentPage,
          newItemsPerPage: state.itemsPerPage,
          archivedNewPage: state.archivedPage,
          showArchived: state.showArchived,
          sortingMethod: state.sortingMethod,
        }),
      );
      showSnackSuccess(
        state.showArchived
          ? strings.MESSAGE_UNARCHIVE_SUCCESS_SNACK
          : strings.MESSAGE_ARCHIVE_SUCCESS_SNACK,
      );
      setSelectedMessageId(null);
    } catch (err) {
      showSnackError(strings.MESSAGE_ARCHIVE_ERROR_SNACK);
      setSelectedMessageId(null);
    }
  };

  const handleConfirmArchive = () => {
    requestArchiveMessage();
    setOpenConfirmArchiveDialog(false);
  };

  const handleCancelArchive = () => {
    setOpenConfirmArchiveDialog(false);
    setSelectedMessageId(null);
  };

  const handleArchiveMessageClick = (messageId) => {
    setSelectedMessageId(messageId);
    setOpenConfirmArchiveDialog(true);
  };

  const handleMessageClick = (id) => {
    goToPage(routeNaming.MESSAGE_DETAIL, { id });
  };

  return (
    <div className={globalStyles.genericContainer}>
      {state.error && <ErrorPage />}
      {(!state.error) && (
        <>
          <CustomSnackbar
            open={snackOpen}
            onClose={handleSnackClose}
            severity={snackSeverity}
            message={snackMessage}
          />

          <ConfirmDialog
            open={openConfirmArchiveDialog}
            title={state.showArchived ? strings.MESSAGE_UNARCHIVE : strings.MESSAGE_ARCHIVE}
            message={
              state.showArchived
                ? strings.MESSAGE_UNARCHIVE_DIALOG
                : strings.MESSAGE_ARCHIVE_DIALOG
            }
            onConfirm={handleConfirmArchive}
            onCancel={handleCancelArchive}
          />

          <Title
            text={state.showArchived ? strings.MESSAGES_ARCHIVED_TITLE : strings.MESSAGES_TITLE}
          />
          <div className={styles.buttonsContainer}>
            <FormControl variant="outlined">
              <InputLabel id="sortByLabel" className={styles.label}>Ordenar por</InputLabel>
              <Select
                labelId="sortByLabel"
                id="sortBy"
                value={state.sortingMethod.id}
                onChange={handleSortingChange}
                label={strings.MESSAGES_SORT_LABEL}
                className="primaryOutlinedSelect"
              >
                <MenuItem value={SortingMethods.NEWEST_FIRST.id}>
                  {SortingMethods.NEWEST_FIRST.display}
                </MenuItem>
                <MenuItem value={SortingMethods.OLDEST_FIRST.id}>
                  {SortingMethods.OLDEST_FIRST.display}
                </MenuItem>
              </Select>
            </FormControl>
            <div className={styles.spacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<MessageIcon />}
              onClick={handleShowArchivedClick}
            >
              {!state.showArchived ? strings.MESSAGES_SHOW_ARCHIVED : strings.MESSAGES_SHOW_OPEN}
            </Button>
          </div>
          <TableWithLoading
            items={state.messages.map((inquiry) => (
              <MessageListItem
                key={inquiry.id}
                inquiry={inquiry}
                isArchived={state.showArchived}
                onClick={handleMessageClick}
                archiveAction={() => handleArchiveMessageClick(inquiry.id)}
              />
            ))}
            isLoading={state.loading}
            columns={messagesTableCells}
            count={state.messageCount}
            rowsPerPage={state.itemsPerPage}
            page={state.showArchived ? state.archivedPage : state.currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </div>
  );
};

export { Messages };
