import { PersonPreview } from '../../models/person-preview';
import { PersonPreviewSerializer } from './person-preview-serializer';

class PeopleResponseSerializer {
  static deSerialize(data) {
    return {
      people: data.people.map((it) => new PersonPreview(PersonPreviewSerializer.deSerialize(it))),
      count: data.count,
      page: data.page - 1,
    };
  }
}

export { PeopleResponseSerializer };
