import { toEducationType } from '../../helpers/utils';
import institutionTypes from '../../config/institution-types';

const toInstitutionType = (type) => {
  switch (type) {
    case 'escuela_comun':
      return institutionTypes.REGULAR_SCHOOL;
    case 'escuela_especial':
      return institutionTypes.SPECIAL_SCHOOL;
    case 'colegio_comun':
      return institutionTypes.REGULAR_PRIVATE_SCHOOL;
    case 'colegio_especial':
      return institutionTypes.SPECIAL_PRIVATE_SCHOOL;
    case 'liceo':
      return institutionTypes.HIGH_SCHOOL;
    case 'utu':
      return institutionTypes.UTU;
    case 'universidad':
      return institutionTypes.UNIVERSITY;
    case 'club_de_ninos':
      return institutionTypes.KIDS_CLUB;
    case 'jardin_publico':
      return institutionTypes.PUBLIC_KINDER;
    case 'jardin_privado':
      return institutionTypes.PRIVATE_KINDER;
    case 'caif':
      return institutionTypes.CAIF;
    default:
      return 'Institución inválida';
  }
};

class MessageSerializer {
  static deSerialize(data) {
    return {
      id: data.id,
      contactName: data.contact_name,
      relationshipType: data.relationship_type,
      contactPhoneNumber: data.contact_phone_number,
      contactEmail: data.contact_email,
      name: data.name,
      birthDate: data.birth_date,
      motive: data.motive,
      description: data.description,
      institutionType: toInstitutionType(data.institution_type),
      institutionName: data.institution_name,
      institutionLocality: data.institution_locality,
      educationType: toEducationType(data.education_type),
      course: data.course,
      date: Date(data.created_at),
      archived: data.archived,
      usedForEnrollment: data.used_for_enrollment,
      enrolledPersonId: data.enrolled_person ? data.enrolled_person.id : '',
    };
  }

  static serialize(message) {
    return {
      id: message.id,
      contact_name: message.contactName,
      relationship_type: message.relationshipType,
      contact_phone_number: message.contactPhoneNumber,
      contact_email: message.contactEmail,
      name: message.name,
      birth_date: message.birthDate,
      motive: message.motive,
      description: message.description,
      institution_type: message.institutionType,
      institution_name: message.institutionName,
      institution_locality: message.institutionLocality,
      education_type: message.educationType.id,
      course: message.course,
      date: message.date,
      archived: message.archived,
      enrolled_person_id: message.enrolledPersonId,
    };
  }
}

export { MessageSerializer };
