import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks/useWindowSize';
import { BarChart } from '../bar-chart/bar-chart';
import styles from './informed-exclusion-chart.module.scss';

const InformedExclusionChart = ({
  preview,
  institutionTypesSelected,
  statisticsEndpointResponse,
}) => {
  const [width] = useWindowSize();
  const mobileBreakpoint = 992;
  const [widthIsMobile, setWidthIsMobile] = useState(width < mobileBreakpoint);

  // objects containing labels and datasets.
  const [dataChartOne, setDataChartOne] = useState(null);
  const [dataChartTwo, setDataChartTwo] = useState(null);
  const [dataPreview, setDataPreview] = useState(null);
  // array of objects containing labels and datasets.
  const [dataChartsMobile, setDataChartsMobile] = useState(null);

  const parseStatisticsData = (res) => {
    if (res) {
      const institutionLabels = Object.keys(res).map(
        (label, index) => ({ name: label, id: index }),
      );

      const parseDataChartOne = { labels: [], datasets: {} };
      const parseDataChartTwo = { labels: [], datasets: {} };
      const parseDataPreview = { labels: [], datasets: {} };
      const chartsMobile = [];
      if (width <= mobileBreakpoint) {
        for (let i = 0; i < 6; i += 1) {
          chartsMobile[i] = { labels: [], datasets: {} };
        }
      }

      const chartColors = [
        '#41a0f8',
        '#80d453',
        '#929292',
        '#B963DB',
        '#DB6363',
        '#FFF8A3',
        '#FFB473',
        '#D5CFF9',
        '#f0bb40',
        '#A3BBFF',
        '#F279B4',
      ];

      // we create the keys for the datasets, and add colors, only for those that are selected.
      for (let i = 0; i < institutionLabels.length; i += 1) {
        if (institutionTypesSelected.includes(institutionLabels[i].id)) {
          if (preview) {
            parseDataPreview.datasets[institutionLabels[i].name] = {
              values: [],
              color: chartColors[i],
            };
          } else if (width > mobileBreakpoint) { // if not mobile we set the 2 charts
            parseDataChartOne.datasets[institutionLabels[i].name] = {
              values: [],
              color: chartColors[i],
            };
            parseDataChartTwo.datasets[institutionLabels[i].name] = {
              values: [],
              color: chartColors[i],
            };
          } else { // if mobile we set the 6 charts
            for (let j = 0; j < 6; j += 1) {
              chartsMobile[j].datasets[institutionLabels[i].name] = {
                values: [],
                color: chartColors[i],
              };
            }
          }
        }
      }

      const numberExclusionSituationsPerInstitution = [];
      // we only add the values for the selected institution types.
      for (let i = 0; i < institutionTypesSelected.length; i += 1) {
        const id = institutionTypesSelected[i];
        const institutionName = institutionLabels[id].name;

        const itemToBePushed = res[institutionName];
        itemToBePushed.id = id;
        numberExclusionSituationsPerInstitution.push(itemToBePushed);
      }

      // for each selected institution, adds qty of situations for each type of discrimination.
      numberExclusionSituationsPerInstitution.forEach(
        (data, i) => {
          const exclusionSituationsArray = Object.entries(data).filter(
            (entry) => entry[0] !== 'total' && entry[0] !== 'id',
          );
          const institutionIdArray = Object.entries(data).filter((entry) => entry[0] === 'id');
          const institutionId = institutionIdArray[0][1];

          // if first iteration, we add the chart labels.
          if (i === 0) {
            const exclusionSituations = Object.keys(data).filter(
              (key) => key !== 'total' && key !== 'id',
            );
            if (preview) {
              parseDataPreview.labels = [...exclusionSituations];
            } else if (width > mobileBreakpoint) { // if not mobile we set the 2 charts
              parseDataChartOne.labels = [
                exclusionSituations[0], exclusionSituations[1], exclusionSituations[2],
              ];
              parseDataChartTwo.labels = [
                exclusionSituations[3], exclusionSituations[4], exclusionSituations[5],
              ];
            } else {
              for (let j = 0; j < 6; j += 1) { // if mobile we set the 6 charts
                chartsMobile[j].labels = [exclusionSituations[j]];
              }
            }
          }

          const institution = institutionLabels[institutionId].name;
          if (preview) {
            (parseDataPreview.datasets[institution].values).push(
              exclusionSituationsArray[0][1],
              exclusionSituationsArray[1][1],
              exclusionSituationsArray[2][1],
              exclusionSituationsArray[3][1],
              exclusionSituationsArray[4][1],
              exclusionSituationsArray[5][1],
            );
          } else if (width > mobileBreakpoint) {
            (parseDataChartOne.datasets[institution].values).push(
              exclusionSituationsArray[0][1],
              exclusionSituationsArray[1][1],
              exclusionSituationsArray[2][1],
            );

            (parseDataChartTwo.datasets[institution].values).push(
              exclusionSituationsArray[3][1],
              exclusionSituationsArray[4][1],
              exclusionSituationsArray[5][1],
            );
          } else {
            for (let j = 0; j < 6; j += 1) { // if mobile we set the 6 charts
              (chartsMobile[j].datasets[institution].values).push(
                exclusionSituationsArray[j][1],
              );
            }
          }
        },
      );

      if (preview) {
        setDataPreview(parseDataPreview);
      } else if (width > mobileBreakpoint) {
        setDataChartOne(parseDataChartOne);
        setDataChartTwo(parseDataChartTwo);
      } else {
        setDataChartsMobile(chartsMobile);
      }
    }
  };

  useEffect(() => {
    if (statisticsEndpointResponse) {
      parseStatisticsData(statisticsEndpointResponse);
    }
  }, [institutionTypesSelected, statisticsEndpointResponse, widthIsMobile]);

  useEffect(() => {
    setWidthIsMobile(width < mobileBreakpoint);
  }, [width]);

  return (
    <>
      {width > mobileBreakpoint && dataChartOne && dataChartTwo && !preview
        && (
          <div className={styles.charts}>
            <BarChart data={dataChartOne} />
            <div className={styles.heightSpacer} />
            <BarChart data={dataChartTwo} />
          </div>
        )}

      {(width <= mobileBreakpoint && dataChartsMobile) && !preview
        && (
          <div className={styles.charts}>
            {dataChartsMobile.map(
              (chart) => (
                <>
                  <BarChart data={chart} />
                  <div className={styles.heightSpacer} />
                </>
              ),
            )}
          </div>
        )}
      {preview
        && (
          <BarChart
            data={dataPreview}
            stacked
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  display: false,
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
            }}
          />
        )}
    </>
  );
};

InformedExclusionChart.propTypes = {
  preview: PropTypes.bool,
  institutionTypesSelected: PropTypes.arrayOf(PropTypes.number),
  statisticsEndpointResponse: PropTypes.object,
};

InformedExclusionChart.defaultProps = {
  preview: false,
  statisticsEndpointResponse: undefined,
  institutionTypesSelected: [],
};

export { InformedExclusionChart };
