class Person {
  constructor(params) {
    this.id = params.id;
    this.contactName = params.contactName;
    this.relationshipType = params.relationshipType;
    this.contactPhoneNumber = params.contactPhoneNumber;
    this.contactEmail = params.contactEmail;
    this.name = params.name;
    this.birthDate = params.birthDate;
    this.motive = params.motive;
    this.description = params.description;
    this.institutionType = params.institutionType;
    this.institutionName = params.institutionName;
    this.institutionLocality = params.institutionLocality;
    this.educationType = params.educationType;
    this.course = params.course;
    this.events = params.events;
    this.suggestions = params.suggestions;
    this.followup = params.followup;
    this.comments = params.comments;
    this.archived = params.archived;
    this.imported = params.imported;
    this.subscribedUsers = params.subscribedUsers;
  }
}

export { Person };
