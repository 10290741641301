import { PersonPreviewSerializer } from './person-preview-serializer';
import { EventAlert } from '../../models/alerts/event-alert';
import { SubscriptionAlert } from '../../models/alerts/subscription-alert';
import eventType from '../../config/event-types';

const toEventName = (eventId) => (Object
  .values(eventType)
  .find(({ id }) => id === Number(eventId)))
  .display;

class AlertSerializer {
  static deSerialize(data) {
    const deSerializedObject = {
      id: data.id,
      author: data.modifier ?? data.subscribed_by,
      date: data.modified_at ?? data.subscribed_at,
      action: data.action,
      eventType: Number(data.event_type) >= 0
        ? toEventName(Number(data.event_type))
        : undefined,
      seen: data.seen,
      person: data.enrolled_person
        ? PersonPreviewSerializer.deSerialize(data.enrolled_person) : undefined,
      eventId: data.event?.id,
    };
    if (data.type === 'EventAlert') {
      return new EventAlert(deSerializedObject);
    }
    return new SubscriptionAlert(deSerializedObject);
  }
}

export { AlertSerializer };
