import React from 'react';
import PropTypes from 'prop-types';
import { toTimeDifferenceString } from '../../helpers/utils';
import { EventAlert } from '../../models/alerts/event-alert';
import { AlertComponentContainer } from './alert-component-container';
import styles from './alert-components.module.scss';

const toAction = (string) => {
  switch (string) {
    case 'deletion':
      return 'eliminó';
    case 'updation':
      return 'editó';
    case 'creation':
      return 'creó';
    default:
      return '';
  }
};

const EventAlertComponent = ({ alert, onArchive, onClick }) => (
  <AlertComponentContainer
    onClick={() => onClick(alert.person.id, alert.eventId)}
    onArchive={() => onArchive(alert)}
  >
    <p>
      <b>
        <i>
          {`${alert.author.split('@')[0]}`}
        </i>
      </b>
      <br />
      <b>
        {` ${toAction(alert.action)} `}
      </b>
      un evento de tipo
      <b>
        {` ${alert.eventType} `}
      </b>
      <br />
      en la trayectoria de
      <b>
        {` ${alert.person?.name} `}
      </b>
      <br />
    </p>
    <p className={styles.timeDifference}>
      hace
      {` ${toTimeDifferenceString(alert.date)}`}
    </p>
  </AlertComponentContainer>
);

EventAlertComponent.propTypes = {
  alert: PropTypes.instanceOf(EventAlert).isRequired,
  onArchive: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { EventAlertComponent };
