class EducationTypeStatistic {
  constructor(params) {
    this.Maternal = params.Maternal;
    this.Inicial = params.Inicial;
    this.Primaria = params.Primaria;
    this.Secundaria = params.Secundaria;
    this.Terciaria = params.Terciaria;
  }
}

export { EducationTypeStatistic };
