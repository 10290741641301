import React, { useEffect, useState } from 'react';

import { BarChart } from '../../common/bar-chart/bar-chart';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './statistics.module.scss';

import { routeNaming } from '../../routes/routes';
import { strings } from '../../config/strings';
import { Title } from '../../common/title';
import { StatisticsPreview } from '../../common/statistics-preview';
import {
  EnrolledPeopleCountController,
} from '../../networking/controllers/enrolled_people_count_controller';
import { LoadingIndicator } from '../../common/loading-indicator';
import { goToPage } from '../../routes';
import { StatisticsController } from '../../networking/controllers/statistics-controller';
import { months } from '../../config/months';
import { parseDate } from '../../helpers/utils';
import { InformedExclusionChart } from '../../common/informed-exclusion-chart';
import { EducationLevelChart } from '../../common/education-level-chart';
import { MotiveDistributionChart } from '../../common/motive-distribution-chart';
import educationTypes from '../../config/education-types';

const Statistics = () => {
  const [enrolledPeople, setEnrolledPeople] = useState([]);
  const [enrolledPeopleError, setEnrolledPeopleError] = useState(false);
  const [inquiryAmountData, setInquiryAmountData] = useState({});
  const [inquiryAmountDataError, setInquiryAmountDataError] = useState(false);
  const [statisticsEndpointResponse, setStatisticsEndpointResponse] = useState(undefined);
  const [educationResults, setEducationResults] = useState({});
  const [date] = useState(new Date());

  const numberEnrolledPeople = () => (
    <div className={styles.numberEnrolledPeople}>
      <p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {('active' in enrolledPeople)
          ? enrolledPeople.active
          : enrolledPeopleError
            ? 'Error obteniendo datos'
            : <LoadingIndicator />}
      </p>
      <p>
        {/* eslint-disable-next-line no-nested-ternary */}
        {('active' in enrolledPeople)
          ? 'Inscriptos'
          : ''}
      </p>
    </div>
  );

  useEffect(() => {
    const fetchEnrolledPeopleCount = async () => {
      try {
        const result = await EnrolledPeopleCountController.enrolledPeopleCount();
        setEnrolledPeople(result);
      } catch {
        setEnrolledPeopleError(true);
      }
    };

    const fetchInquiryAmountData = async () => {
      try {
        const result = await StatisticsController.getInquiryAmountStatistic(
          new Date(date.getFullYear(), 0, 1),
          date,
        );
        setInquiryAmountData(result);
      } catch {
        setInquiryAmountDataError(true);
      }
    };

    const fetchInformedExclusions = async () => {
      const dateFromString = parseDate(new Date(date.getFullYear(), 0, 1));
      const dateToString = parseDate(date);
      const result = await StatisticsController.getExclusionStatistics(
        dateFromString, dateToString,
      );
      setStatisticsEndpointResponse(result.data);
    };

    const toEducationResultLabel = (key) => {
      if (key === 'MIDDLE_BASIC') {
        return 'Educación media básica';
      }
      if (key === 'MIDDLE_UPPER') {
        return 'Educación media superior';
      }
      return educationTypes[key].name;
    };

    const fetchEducationResults = async () => {
      const response = await StatisticsController.getEducationResultsStatistics(date.getFullYear());
      const data = {
        labels: ['Abandonaron', 'Repitieron', 'Aprobaron'],
        datasets: Object.assign(
          {},
          ...(Object.entries(response).map(([key, value]) => ({
            [toEducationResultLabel(key)]: { values: [...(Object.values(value))] },
          }))),
        ),
      };
      setEducationResults(data);
    };

    fetchEnrolledPeopleCount();
    fetchInquiryAmountData();
    fetchInformedExclusions();
    fetchEducationResults();
  }, []);

  const reRouteTo = (route) => {
    goToPage(route);
  };

  const inquiryData = inquiryAmountDataError ? {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo',
      'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    datasets: [
      {
        label: 'Consultas',
        data: [5, 6, 7, 3, 10, 2, 6, 4, 9, 2, 9, 12],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Inscripciones',
        data: [4, 7, 3, 10, 3, 6, 2, 2, 2, 9, 9, 10],
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  } : {
    labels: Object.keys(inquiryAmountData).map((inquiryDate) => months[
      (new Date(inquiryDate)).getUTCMonth()
    ]),
    datasets: [
      {
        label: 'Consultas',
        data: Object.values(inquiryAmountData).map((val) => val.inquiries),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Inscripciones',
        data: Object.values(inquiryAmountData).map((val) => val.inscriptions),
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  };

  return (
    <div className={globalStyles.statisticsContainer}>
      <Title text={strings.STATISTICS_TITLE} />
      <div className={globalStyles.statisticsItemContainer}>
        <StatisticsPreview title="Cantidad de personas">
          {numberEnrolledPeople()}
        </StatisticsPreview>
        <StatisticsPreview
          title={strings.INFORMED_EXC_STATISTICS_TITLE}
          onClick={() => reRouteTo(routeNaming.INFORMED_EXCLUSION_STATISTICS)}
        >
          {/* {informedExclusionStatistics()} */}
          <InformedExclusionChart
            preview
            institutionTypesSelected={[...Array(11).keys()]}
            statisticsEndpointResponse={statisticsEndpointResponse}
          />
        </StatisticsPreview>
        <StatisticsPreview
          title={strings.INQUIRY_AMOUNT_STATISTICS}
          onClick={() => reRouteTo(routeNaming.INQUIRY_AMOUNT_STATISTIC)}
        >
          <BarChart data={inquiryData} original />
        </StatisticsPreview>
        <StatisticsPreview
          title="Nivel educativo"
          onClick={() => reRouteTo(routeNaming.EDUCATION_LEVEL_STATISTIC)}
        >
          <EducationLevelChart date={date} />
        </StatisticsPreview>
        <StatisticsPreview
          title={strings.MOTIVE_DISTRIBUTION_TITLE}
          onClick={() => reRouteTo(routeNaming.MOTIVE_DISTRIBUTION_STATISTICS)}
        >
          <MotiveDistributionChart
            dateFrom={new Date(date.getFullYear(), 0, 1)}
            dateTo={date}
            preview
          />
        </StatisticsPreview>
        <StatisticsPreview
          title={strings.EDUCATION_RESULTS_DISTRIBUTION_TITLE}
          onClick={() => reRouteTo(routeNaming.EDUCATION_RESULTS_STATISTICS)}
        >
          <BarChart
            data={educationResults}
            stacked
            options={{ maintainAspectRatio: false }}
          />
        </StatisticsPreview>
      </div>
    </div>
  );
};

export { Statistics };
