import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEducationLevelStatistic } from '../../store/slices/education-level-statistic-slice';
import { BarChart } from '../bar-chart/bar-chart';

const EducationLevelChart = ({
  date,
}) => {
  const { educationLevels } = useSelector(
    (selector) => selector.educationLevelStatistics,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (date && date.getFullYear() > 999) {
      dispatch(fetchEducationLevelStatistic(date));
    }
  }, [date.getFullYear()]);

  const data = {
    labels: Object.keys(educationLevels),
    datasets: {
      NivelEducativo:
      {
        values: Object.values(educationLevels),
      },
    },
  };

  return (
    <>
      <BarChart
        data={data}
        options={{
          maintainAspectRatio: false,
        }}
      />
    </>
  );
};

EducationLevelChart.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export { EducationLevelChart };
