/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Message } from '../../models/message';
import { MessageController } from '../../networking/controllers/message-controller';

const initialState = {
  editing: true,
  loading: false,
  error: '',
  success: false,
};

const submitForm = createAsyncThunk(
  'form/submitFormStatus',
  async (formData) => {
    const message = new Message({
      ...formData,
      motive: formData.otherMotive || formData.motive,
      relationshipType: formData.otherRelationship || formData.relationshipType,
    });
    return MessageController.createMessage(message);
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
  state.editing = false;
  state.success = false;
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.editing = true;
  state.success = false;
};

const setSuccess = (state) => {
  state.loading = false;
  state.error = '';
  state.editing = false;
  state.success = true;
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitForm.pending, setLoading)
      .addCase(submitForm.fulfilled, setSuccess)
      .addCase(submitForm.rejected, setError);
  },
});

export { submitForm };

export default formSlice.reducer;
