import { MeetingsResponseSerializer } from '../serializers/meetings-response-serializer';
import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';

class MeetingsController {
  static async getMeetings(limit, past, page) {
    return ApiService.get(API_ROUTES.MEETINGS, {
      limit, past, page: page + 1,
    })
      .then((response) => MeetingsResponseSerializer.deSerialize(response.data));
  }

  static async deleteMeeting(id) {
    return ApiService.delete(`${API_ROUTES.MEETINGS}/${id}`);
  }
}

export { MeetingsController };
