import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const GenericFormSelect = ({
  value, onChange, options, label, id, required,
}) => (
  <TextField
    size="small"
    margin="normal"
    id={id}
    label={label}
    select
    fullWidth
    required={required}
    value={value}
    onChange={onChange}
  >
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </TextField>
);

GenericFormSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

GenericFormSelect.defaultProps = {
  required: false,
  value: undefined,
};

export { GenericFormSelect };
