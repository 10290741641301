import { React } from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

// import styles from './create-event-modal.module.scss';

const DatePickerEvent = ({
  value, handleChange, eventType, label, minDate, maxDate,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={2}>
      <DatePicker
        label={label}
        value={value}
        inputFormat="dd/MM/yyyy"
        maxDate={maxDate || ((eventType === 1 || eventType === 2
          || eventType === 3) ? new Date() : undefined)}
        minDate={minDate}
        onChange={handleChange}
        /* eslint-disable react/jsx-props-no-spreading */
        renderInput={(props) => (
          <TextField
            {...props}
            required
            className="primaryOutlinedTextField"
            variant="outlined"
            size="small"
            margin="normal"
          />
        )}
      />
    </Stack>
  </LocalizationProvider>

);

DatePickerEvent.propTypes = {
  value: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  eventType: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,

};

DatePickerEvent.defaultProps = {
  minDate: undefined,
  maxDate: undefined,
};

export { DatePickerEvent };
