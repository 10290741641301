/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoginController } from '../../networking/controllers/login-controller';
import { LoggedUserController } from '../../networking/controllers/logged-user-controller';
import { LogoutController } from '../../networking/controllers/logout-controller';

const initialState = {
  loggedIn: true,
  error: false,
  loggedUser: undefined,
  loading: true,
  loginLoading: false,
  alerts: [],
};

const checkUser = createAsyncThunk(
  'session/checkUserStatus',
  async () => LoggedUserController.checkLoggedUser(),
);

const requestLogin = createAsyncThunk(
  'session/loginStatus',
  async (params) => {
    await LoginController.login(params);
    return LoggedUserController.checkLoggedUser();
  },
);

const requestLogout = createAsyncThunk(
  'session/logoutStatus',
  async () => LogoutController.logout(),
);

const fetchAlerts = createAsyncThunk(
  'session/fetchAlertsStatus',
  async () => {
    const alerts = await LoggedUserController.getAlerts();
    return alerts;
  },
);

const markAlertsSeen = createAsyncThunk(
  'session/markAlertsSeenStatus',
  async (alerts) => {
    await alerts.forEach((alert) => LoggedUserController.markAlertSeen(alert.id));
    return LoggedUserController.getAlerts();
  },
);

const archiveAlert = createAsyncThunk(
  'session/archiveAlertStatus',
  async (alert) => {
    await LoggedUserController.archiveAlert(alert.id);
    return LoggedUserController.getAlerts();
  },
);

const setAlerts = (state, action) => {
  state.alerts = action.payload;
  state.loading = false;
  state.error = false;
};

const setLoggedUser = (state, action) => {
  state.loggedIn = true;
  state.error = false;
  state.loggedUser = action.payload;
  state.loading = false;
  state.loginLoading = false;
};

const setLoading = (state) => {
  state.loading = true;
  state.error = false;
};

const logout = (state) => {
  state.error = false;
  state.loggedIn = false;
  state.loggedUser = undefined;
  state.loading = false;
  state.loginLoading = false;
};

const error = (state) => {
  state.loggedIn = false;
  state.error = true;
  state.loginLoading = false;
};

const checkUserError = (state) => {
  state.loggedIn = false;
  state.error = false;
  state.loginLoading = false;
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    clearError(state) {
      state.loggedIn = false;
      state.error = false;
      state.loginLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkUser.pending, setLoading)
      .addCase(checkUser.fulfilled, setLoggedUser)
      .addCase(checkUser.rejected, checkUserError)
      .addCase(requestLogout.pending, setLoading)
      .addCase(requestLogout.fulfilled, logout)
      .addCase(requestLogout.rejected, error)
      .addCase(requestLogin.pending, setLoading)
      .addCase(requestLogin.fulfilled, setLoggedUser)
      .addCase(requestLogin.rejected, error)
      .addCase(archiveAlert.fulfilled, setAlerts)
      .addCase(archiveAlert.rejected, error)
      .addCase(markAlertsSeen.fulfilled, setAlerts)
      .addCase(markAlertsSeen.rejected, error)
      .addCase(fetchAlerts.fulfilled, setAlerts)
      .addCase(fetchAlerts.rejected, error);
  },
});

export const { clearError } = sessionSlice.actions;

export {
  checkUser,
  requestLogin,
  requestLogout,
  fetchAlerts,
  markAlertsSeen,
  archiveAlert,
};

export default sessionSlice.reducer;
