import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';

class CSVExchangeController {
  static uploadCSV(params) {
    return ApiService.post(API_ROUTES.IMPORT_ENROLLED_PEOPLE, params);
  }
}

export { CSVExchangeController };
