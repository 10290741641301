import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import styles from './bar-chart.module.scss';
import chartPalette from '../chart-palette';

const baseOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const stackedOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const BarChart = ({
  data,
  original,
  stacked,
  options,
}) => {
  const dataset = {
    labels: data?.labels,
    datasets: Object.entries(data?.datasets ?? {}).map(([key, value], index) => ({
      label: key,
      data: value.values,
      backgroundColor: value.color ?? chartPalette[index],
      borderColor: value.color ?? chartPalette[index],
      borderWidth: 1,
    })),
  };
  const additionalOptions = stacked ? stackedOptions : {};
  return (
    <Bar
      data={original ? data : dataset}
      options={{ ...baseOptions, ...additionalOptions, ...options }}
      className={styles.chart}
    />
  );
};

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
  original: PropTypes.bool,
  stacked: PropTypes.bool,
  options: PropTypes.object,
};

BarChart.defaultProps = {
  original: false,
  stacked: false,
  options: {},
};

export { BarChart };
