class EducationTypeStatisticSerializer {
  static deSerialize(data) {
    return {
      Maternal: data.maternal,
      Inicial: data.inicial,
      Primaria: data.primaria,
      Secundaria: data.secundaria,
      Terciaria: data.terciaria,
    };
  }
}

export { EducationTypeStatisticSerializer };
