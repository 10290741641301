import { React } from 'react';
import {
  FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';

const DropdownSelect = ({
  label,
  items, // If items is an object with objects, they should contain {name: ..., id: ...,}
  onChange,
  value,
  margin,
  required,
  className,
}) => (
  <FormControl size="small" variant="outlined" margin={margin} className={className}>
    <InputLabel>{label}</InputLabel>
    <Select
      label={label}
      value={value}
      className="primaryOutlinedSelect"
      displayEmpty
      onChange={onChange}
      required={required}
    >
      {/* eslint-disable react/no-array-index-key */}
      {Object.values(items).map((item, index) => (
        <MenuItem
          sx={{ 'white-space': 'normal', 'text-align': 'left' }}
          // sx={{ overflow: 'auto' }}
          key={index}
          value={(item.id || item.id === 0) ? item.id : item}
        >
          {item.name || item}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

DropdownSelect.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.oneOfType(
    [PropTypes.instanceOf(Array), PropTypes.instanceOf(Object)],
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  margin: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
};

DropdownSelect.defaultProps = {
  required: false,
  className: '',
  margin: 'normal',
};

const NoMarginDropdownSelect = ({
  label,
  items,
  onChange,
  value,
  className,
}) => <DropdownSelect label={label} items={items} onChange={onChange} value={value} margin="none" className={className} />;

NoMarginDropdownSelect.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

NoMarginDropdownSelect.defaultProps = {
  className: '',
};

export { DropdownSelect, NoMarginDropdownSelect };
