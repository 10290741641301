import { React } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Stack, TextField } from '@mui/material';
import styles from './person-enrollment.module.scss';
import { Message } from '../../models/message';
import { PrimaryTextfield } from '../primary-textfield/primary-textfield';
import { strings } from '../../config/strings';
import { NoMarginDropdownSelect } from '../dropdown-select/dropdown-select';
import motiveTypes from '../../config/motive-types';
import institutionTypes from '../../config/institution-types';
import educationTypes from '../../config/education-types';
import relationshipTypes from '../../config/relationship-types';
import { EducationCourseAndInstitutionSelects } from '../education-course-and-institution-selects/education-course-and-institution-selects';

const PersonEnrollmentContact = ({ inquiry, onChange, required }) => {
  /* eslint-disable-next-line */
  const RelationshipDropDown = ({ value }) => (
    <NoMarginDropdownSelect
      label={strings.MESSAGE_RELATIONSHIP_TYPE}
      value={value || inquiry.relationshipType}
      onChange={(event) => onChange({ ...inquiry, relationshipType: event.target.value })}
      items={relationshipTypes}
    />
  );
  return (
    <div className={styles.component}>
      <PrimaryTextfield
        label={strings.MESSAGE_CONTACT_NAME}
        value={inquiry.contactName}
        onChange={(event) => onChange({ ...inquiry, contactName: event.target.value })}
        required
      />
      <div className={styles.spacer} />
      {Object.values(relationshipTypes)
        .some((item) => item === inquiry.relationshipType)
        && inquiry.relationshipType !== relationshipTypes.OTHER
        ? <RelationshipDropDown /> : (
          <>
            <RelationshipDropDown value={relationshipTypes.OTHER} />
            <div className={styles.spacer} />
            <PrimaryTextfield
              label={strings.MESSAGE_RELATIONSHIP_TYPE_OTHER}
              value={inquiry.relationshipType ? inquiry.relationshipType : ''}
              onChange={(event) => onChange({ ...inquiry, relationshipType: event.target.value })}
              multiline
            />
          </>
        )}
      <div className={styles.spacer} />
      <PrimaryTextfield
        label={strings.MESSAGE_CONTACT_PHONE}
        value={inquiry.contactPhoneNumber}
        onChange={(event) => onChange({ ...inquiry, contactPhoneNumber: event.target.value })}
        required={required}
        softRequired
        helperText={(inquiry.contactPhoneNumber || inquiry.contactEmail) ? '' : strings.FORM_CONTACT_EMAIL_HELPER}
      />
      <div className={styles.spacer} />
      <PrimaryTextfield
        label={strings.MESSAGE_CONTACT_EMAIL}
        value={inquiry.contactEmail}
        onChange={(event) => onChange({ ...inquiry, contactEmail: event.target.value })}
        required={required}
        softRequired
        helperText={(inquiry.contactPhoneNumber || inquiry.contactEmail) ? '' : strings.FORM_CONTACT_EMAIL_HELPER}
      />
      <div className={styles.bigSpacer} />
    </div>
  );
};

const PersonEnrollmentPerson = ({ inquiry, onChange, errorDate }) => {
  /* eslint-disable-next-line */
  const MotiveDropDown = ({ value }) => (
    <NoMarginDropdownSelect
      label={strings.MESSAGE_MOTIVE}
      value={value || inquiry.motive}
      onChange={(event) => onChange({ ...inquiry, motive: event.target.value })}
      items={motiveTypes}
    />
  );

  return (
    <div className={styles.component}>
      <PrimaryTextfield
        label={strings.MESSAGE_PERSON_NAME}
        value={inquiry.name}
        onChange={(event) => onChange({ ...inquiry, name: event.target.value })}
        required
      />
      <div className={styles.spacer} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={2}>
          <DatePicker
            label={strings.FORM_PERSON_BIRTHDAY_LABEL}
            inputFormat="dd/MM/yyyy"
            value={inquiry.birthDate}
            maxDate={new Date()}
            onChange={(e) => onChange({ ...inquiry, birthDate: e })}
            /* eslint-disable react/jsx-props-no-spreading */
            renderInput={(props) => (
              <TextField
                {...props}
                required
                className="primaryOutlinedTextField"
                variant="outlined"
                size="small"
                margin="normal"
                error={errorDate}
                helperText={errorDate ? strings.FORM_PERSON_BIRTHDAY_ERROR : ''}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
      <div className={styles.spacer} />

      {Object.values(motiveTypes)
        .some((item) => item === inquiry.motive) && inquiry.motive !== motiveTypes.OTHER
        ? <MotiveDropDown /> : (
          <>
            <MotiveDropDown value={motiveTypes.OTHER} />
            <div className={styles.spacer} />
            <PrimaryTextfield
              label={strings.MESSAGE_MOTIVE_OTHER}
              value={inquiry.motive}
              onChange={(event) => onChange({ ...inquiry, motive: event.target.value })}
              multiline
            />
          </>
        )}
      <div className={styles.spacer} />

      <PrimaryTextfield
        label={strings.MESSAGE_DESCRIPTION}
        value={inquiry.description}
        onChange={(event) => onChange({ ...inquiry, description: event.target.value })}
        multiline
      />
      <div className={styles.bigSpacer} />
    </div>
  );
};

const PersonEnrollmentInstitution = ({ inquiry, onChange }) => (
  <div className={styles.component}>
    <NoMarginDropdownSelect
      label={strings.MESSAGE_INSTITUTION_TYPE}
      value={inquiry.institutionType.id}
      onChange={(event) => onChange({
        ...inquiry,
        institutionType: Object
          .values(institutionTypes)
          .find((value) => value.id === event.target.value) || institutionTypes.REGULAR_SCHOOL,
      })}
      items={institutionTypes}
    />
    <div className={styles.spacer} />

    <PrimaryTextfield
      label={strings.MESSAGE_INSTITUTION_NAME}
      value={inquiry.institutionName}
      onChange={(event) => onChange({ ...inquiry, institutionName: event.target.value })}
    />
    <div className={styles.spacer} />

    <PrimaryTextfield
      label={strings.MESSAGE_INSTITUTION_LOCALITY}
      value={inquiry.institutionLocality}
      onChange={(event) => onChange({ ...inquiry, institutionLocality: event.target.value })}
    />
    <EducationCourseAndInstitutionSelects
      educationType={inquiry.educationType ? inquiry.educationType.id : undefined}
      course={inquiry.course}
      onChangeEducationType={(e) => onChange({
        ...inquiry,
        educationType: Object.values(educationTypes)
          .find((value) => value.id === e.target.value),
      })}
      onChangeCourse={(e) => onChange({ ...inquiry, course: e.target.value })}
      labelEducationType={strings.FORM_EDUCATION_TYPE}
      labelCourse={strings.FORM_COURSE}
    />
    <div className={styles.bigSpacer} />
  </div>
);

PersonEnrollmentContact.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

PersonEnrollmentContact.defaultProps = {
  required: false,
};

PersonEnrollmentPerson.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
  onChange: PropTypes.func.isRequired,
  errorDate: PropTypes.bool,
};

PersonEnrollmentPerson.defaultProps = {
  errorDate: false,
};

PersonEnrollmentInstitution.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { PersonEnrollmentContact };
export { PersonEnrollmentPerson };
export { PersonEnrollmentInstitution };
