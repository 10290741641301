/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MessagePreviewController } from '../../networking/controllers/message-preview-controller';

const SortingMethods = {
  NEWEST_FIRST: {
    id: 1,
    sortingTransformation: (list) => list.sort((a, b) => (b.date - a.date)),
    name: 'desc',
    display: 'Más recientes primero',
  },
  OLDEST_FIRST: {
    id: 2,
    sortingTransformation: (list) => list.sort((a, b) => (a.date - b.date)),
    name: 'asc',
    display: 'Más antiguos primero',
  },
};

const initialState = {
  messages: [],
  currentPage: 0,
  archivedPage: 0,
  itemsPerPage: 10,
  messageCount: 0,
  showArchived: false,
  sortingMethod: SortingMethods.NEWEST_FIRST,
  error: '',
  loading: false,
};

const fetchMessages = createAsyncThunk(
  'messages/fetchMessagesStatus',
  async ({
    newPage,
    newItemsPerPage,
    archivedNewPage,
    showArchived,
    sortingMethod,
  }) => {
    const response = await MessagePreviewController.getMessagesPreview(
      sortingMethod.name,
      newItemsPerPage,
      showArchived,
      showArchived ? archivedNewPage : newPage,
    );
    return {
      messages: response.inquiries,
      paging: {
        newPage: showArchived ? newPage : response.page,
        newItemsPerPage,
        archivedNewPage: showArchived ? archivedNewPage : response.page,
        count: response.count,
      },
      showArchived,
      sortingMethod,
    };
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.error;
};

const setMessages = (state, action) => {
  const {
    newPage, newItemsPerPage, archivedNewPage, count,
  } = action.payload.paging;
  return {
    ...state,
    messages: action.payload.messages,
    archivedPage: archivedNewPage,
    currentPage: newPage,
    itemsPerPage: newItemsPerPage,
    messageCount: count,
    showArchived: action.payload.showArchived,
    sortingMethod: action.payload.sortingMethod,
    error: '',
    loading: false,
  };
};

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, setLoading)
      .addCase(fetchMessages.fulfilled, setMessages)
      .addCase(fetchMessages.rejected, setError);
  },
});

export { fetchMessages, SortingMethods };

export default messagesSlice.reducer;
