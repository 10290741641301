import { Event } from './event';

class CourseEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.endDate = params.endDate;
    this.educationType = params.educationType;
    this.educationLevel = params.educationLevel;
    this.result = params.result;
    this.repeating = params.repeating;
  }
}

export { CourseEvent };
