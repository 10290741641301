import { ExtraordinaryHelpEvent } from '../../models/events/extraordinary-help-event';
import { DiscriminationEvent } from '../../models/events/discrimination-event';
import { EducationAbandonmentEvent } from '../../models/events/education-abandonment-event';
import { EgressEvent } from '../../models/events/egress-event';
import { CourseEvent } from '../../models/events/course-event';
import { InstitutionEnrollmentEvent } from '../../models/events/institution-enrollment-event';
import { InstitutionChangeEvent } from '../../models/events/institution-change-event';
import { MeetingEvent } from '../../models/events/meeting-event';
import educationTypes from '../../config/education-types';
import institutionTypes, { institutionTypesBackendMapping } from '../../config/institution-types';
import helpTypes from '../../config/help-types';
import discriminationTypes from '../../config/discrimination-types';
import courses from '../../config/courses';
import { getKey } from '../../helpers/utils';
import { MeetingSerializer } from './meeting-serializer';
import courseEventResults from '../../config/course-event-results';
import { FollowUpEvent } from '../../models/events/follow-up-event';

class EventSerializer {
  static serialize(event) {
    if (event instanceof ExtraordinaryHelpEvent) {
      return {
        event_type: '0',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        help_type: helpTypes[event.helpType],
      };
    } if (event instanceof DiscriminationEvent) {
      return {
        event_type: '1',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        discrimination_type: discriminationTypes[event.discriminationType],
        institution_type: institutionTypes[event.institutionType].id,
      };
    } if (event instanceof EducationAbandonmentEvent) {
      return {
        event_type: '2',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        motive: event.motive,
        education_type: event.educationType.id,
      };
    } if (event instanceof EgressEvent) {
      return {
        event_type: '3',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        age: event.age,
      };
    } if (event instanceof CourseEvent) {
      return {
        event_type: '4',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        end_date: event.endDate,
        result: event.result === 3 ? null : event.result,
        education_level: courses[getKey(educationTypes, event.educationType)][event.educationLevel]
          || event.educationLevel,
        repeating: event.repeating,
        education_type: event.educationType.id,
      };
    } if (event instanceof InstitutionEnrollmentEvent) {
      return {
        event_type: '5',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        institution_name: event.institutionName,
        institution_locality: event.institutionLocality,
        institution_type: institutionTypes[event.institutionType].id,
      };
    } if (event instanceof InstitutionChangeEvent) {
      return {
        event_type: '6',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
        institution_name: event.institutionName,
        institution_locality: event.institutionLocality,
        institution_type: institutionTypes[event.institutionType].id,
        motive: event.motive,
      };
    } if (event instanceof FollowUpEvent) {
      return {
        event_type: '8',
        enrolled_person_id: event.personId,
        date: event.date,
        note: event.note,
      };
    }
    return {
      event_type: '0',
      enrolled_person_id: event.personId,
      date: event.date,
      note: event.note,
      help_type: event.helpType,
    };
  }

  static deSerialize(event, personId) {
    const eventExtraInfo = event.eventable;
    switch (event.eventable_type) {
      case 'ExtraordinaryHelpEvent':
        return new ExtraordinaryHelpEvent({
          ...event, eventType: 0, personId, helpType: getKey(helpTypes, eventExtraInfo.help_type),
        });
      case 'DiscriminationEvent':
        return new DiscriminationEvent({
          ...event,
          eventType: 1,
          personId,
          discriminationType: getKey(discriminationTypes, eventExtraInfo.discrimination_type),
          institutionType: institutionTypesBackendMapping[eventExtraInfo.institution_type],
        });
      case 'EducationAbandonmentEvent':
        return new EducationAbandonmentEvent({
          ...event,
          eventType: 2,
          personId,
          motive: eventExtraInfo.motive,
          educationType: educationTypes[Object.keys(educationTypes).find(
            (key) => educationTypes[key].name.toLowerCase().includes(
              eventExtraInfo.education_type.toLowerCase(),
            ),
          )],
        });
      case 'InstitutionEgressEvent':
        return new EgressEvent({
          ...event,
          eventType: 3,
          personId,
          age: eventExtraInfo.age,
        });
      case 'CourseEvent':
        return new CourseEvent({
          ...event,
          eventType: 4,
          personId,
          educationType: educationTypes[Object.keys(educationTypes).find(
            (key) => educationTypes[key].name.toLowerCase().includes(
              eventExtraInfo.education_type.toLowerCase(),
            ),
          )],
          educationLevel: eventExtraInfo.education_level,
          repeating: eventExtraInfo.repeating,
          result: courseEventResults[eventExtraInfo.result || 'EMPTY'],
          endDate: eventExtraInfo.end_date,
        });
      case 'InstitutionEnrollmentEvent':
        return new InstitutionEnrollmentEvent({
          ...event,
          eventType: 5,
          personId,
          institutionName: eventExtraInfo.institution_name,
          institutionNumber: 0,
          institutionLocality: eventExtraInfo.institution_locality,
          institutionType: institutionTypesBackendMapping[eventExtraInfo.institution_type],
        });
      case 'InstitutionChangeEvent':
        return new InstitutionChangeEvent({
          ...event,
          eventType: 6,
          personId,
          institutionName: eventExtraInfo.institution_name,
          institutionNumber: 0,
          institutionLocality: eventExtraInfo.institution_locality,
          institutionType: institutionTypesBackendMapping[eventExtraInfo.institution_type],
          motive: eventExtraInfo.motive,
        });
      case 'MeetingEvent':
        return new MeetingEvent({
          ...event,
          eventType: 7,
          personId,
          meeting: MeetingSerializer.deSerialize(eventExtraInfo.meeting),
        });
      case 'FollowUpEvent':
        return new FollowUpEvent({
          ...event,
          eventType: 8,
          personId,
        });
      default:
        throw new Error('Event type invalid');
    }
  }
}

export { EventSerializer };
