class EnrolledPeopleCountSerializer {
  static deSerialize(data) {
    return {
      total: data.total,
      active: data.active,
      archived: data.archived,
    };
  }
}

export { EnrolledPeopleCountSerializer };
