import React from 'react';

import { LoadingIndicator } from '../../common/loading-indicator';
import styles from './loading-screen.module.scss';

const LoadingScreen = () => (
  <div className={styles.container}>
    <div>
      <h3>Cargando sitio web...</h3>
      <LoadingIndicator color="secondary" />
    </div>
  </div>
);

export { LoadingScreen };
