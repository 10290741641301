import { MessagePreview } from '../../models/message-preview';
import { MessagePreviewSerializer } from './message-preview-serializer';

class MessagesResponseSerializer {
  static deSerialize(data) {
    return {
      inquiries:
        data.inquiries.map((it) => new MessagePreview(MessagePreviewSerializer.deSerialize(it))),
      count: data.count,
      page: data.page - 1,
    };
  }
}

export { MessagesResponseSerializer };
