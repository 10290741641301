import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
import { Title } from '../../common/title/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './enroll-person.module.scss';
import {
  PersonEnrollmentContact,
  PersonEnrollmentPerson,
  PersonEnrollmentInstitution,
} from '../../common/person-enrollment/person-enrollment';
import { LoadingIndicator } from '../../common/loading-indicator';
import { fetchMessage, updateMessage } from '../../store/slices/message-slice';
import { Message } from '../../models/message';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';
import { PrimaryTextfield } from '../../common/primary-textfield/primary-textfield';
import { EnrollmentRequest } from '../../models/enrollment-request';
import { PersonController } from '../../networking/controllers/person-controller';
import { CustomSnackbar } from '../../common/custom-snackbar/custom-snackbar';
import { goToPage, routeNaming } from '../../routes';

const EnrollPerson = () => {
  const { message, loading, error } = useSelector((selector) => selector.message);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [suggestions, setSuggestions] = useState('');
  const [comments, setComments] = useState('');
  const [followup, setFollowup] = useState('');
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState(true);

  useEffect(() => {
    dispatch(fetchMessage(id));
  }, []);

  useEffect(() => {
    setEmailOrPhone(message.contactEmail === '' && message.contactPhoneNumber === '');
  }, [message.contactEmail, message.contactPhoneNumber]);

  const showSnackError = (prompt) => {
    setSnackSeverity('error');
    setSnackMessage(prompt);
    setSnackOpen(true);
  };

  const showSnackSuccess = (prompt) => {
    setSnackSeverity('success');
    setSnackMessage(prompt);
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const onMessageUpdated = (updatedMessage) => {
    if (/^(\d*)$/.test(updatedMessage.contactPhoneNumber)) {
      dispatch(updateMessage(updatedMessage));
    } else {
      dispatch(updateMessage({ ...updatedMessage, contactPhoneNumber: message.contactPhoneNumber.replace(/\D/g, '') }));
    }
  };

  const validateForm = () => (message.contactName
    && (message.contactEmail || message.contactPhoneNumber)
    && message.name && message.birthDate && message.educationType
    && (message.educationType.id === 0 || message.course));

  const handleEnrollment = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        const response = await PersonController.enrollPerson((new EnrollmentRequest({
          ...message, inquiryId: message.id, followup, suggestions, comments,
        })));
        const personId = response.data.id;
        showSnackSuccess(strings.ENROLL_PERSON_SUCCESS);
        setTimeout(() => { goToPage(routeNaming.PERSON_DETAIL, { id: personId }); }, 2000);
      } catch (exception) {
        showSnackError(exception.message);
      }
    }
  };

  return (
    <div className={globalStyles.genericContainer}>
      {error && <ErrorPage />}
      {loading && <LoadingIndicator />}
      {!(loading || error) && (
        <>
          <CustomSnackbar
            open={snackOpen}
            onClose={handleSnackClose}
            severity={snackSeverity}
            message={snackMessage}
          />
          <div>
            <Title text={strings.ENROLL_PERSON_TITLE} />
          </div>
          <form onSubmit={handleEnrollment}>
            <div className={styles.component}>
              <div className={styles.column}>
                <p className={styles.subtitle}>{strings.MESSAGE_CONTACT_DATA}</p>
                <div className={styles.spacer} />
                <PersonEnrollmentContact
                  inquiry={new Message(message)}
                  onChange={onMessageUpdated}
                  required={emailOrPhone}
                />
              </div>
              <div className={styles.column}>
                <p className={styles.subtitle}>{strings.MESSAGE_PERSON_DATA}</p>
                <div className={styles.spacer} />
                <PersonEnrollmentPerson
                  inquiry={new Message(message)}
                  onChange={onMessageUpdated}
                  required
                />
              </div>
              <div className={styles.column}>
                <p className={styles.subtitle}>{strings.MESSAGE_INSTITUTION_DATA}</p>
                <div className={styles.spacer} />
                <PersonEnrollmentInstitution
                  inquiry={new Message(message)}
                  onChange={onMessageUpdated}
                />
              </div>
            </div>
            <div className={styles.additionalInformation}>
              <div className={styles.column}>
                <PrimaryTextfield
                  label={strings.ENROLL_PERSON_SUGGESTIONS}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                  className={styles.additionalTextfield}
                  multiline
                  fullWidth
                />
              </div>
              <div className={styles.spacer} />
              <div className={styles.column}>
                <PrimaryTextfield
                  label={strings.ENROLL_PERSON_FOLLOWUP}
                  value={followup}
                  onChange={(e) => setFollowup(e.target.value)}
                  className={styles.additionalTextfield}
                  multiline
                  fullWidth
                />
              </div>
              <div className={styles.spacer} />
              <div className={styles.column}>
                <PrimaryTextfield
                  label={strings.ENROLL_PERSON_COMMENTS}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  className={styles.additionalTextfield}
                  multiline
                  fullWidth
                />
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <Button
                className={globalStyles.primaryButton}
                startIcon={<PersonAdd />}
                variant="contained"
                type="submit"
                color="primary"
                disabled={message.usedForEnrollment}
              >
                {strings.ENROLL_PERSON_TITLE}
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export { EnrollPerson };
