import { React } from 'react';
import { TableRow, TableCell } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PropTypes from 'prop-types';
import { ButtonOverlay } from '../button-overlay';
import styles from './meeting-list-item.module.scss';
import { ButtonOverlayAction } from '../../models/button-overlay-action';
import { Meeting } from '../../models/meeting';

const MeetingListItem = ({
  meeting,
  onClick,
  deleteAction,
  editAction,
  copyAction,
}) => {
  const overlayActions = [
    new ButtonOverlayAction({
      icon: EditIcon,
      action: editAction,
      label: 'Editar',
    }),
    new ButtonOverlayAction({
      icon: DeleteIcon,
      action: deleteAction,
      label: 'Eliminar',
    }),
    new ButtonOverlayAction({
      icon: ContentCopyIcon,
      action: copyAction,
      label: 'Copiar invitados',
    }),
  ];

  const date = new Date(meeting.date);
  return (
    <TableRow
      className={styles.tableRow}
      onClick={() => onClick(meeting.id)}
    >
      <TableCell className={styles.tableCell}>{`${date.toLocaleDateString('es-UY')} ${date.toLocaleTimeString('es-UY')}`}</TableCell>
      <TableCell className={styles.tableCell}>
        <div className={styles.textContainer}>
          {meeting.duration}
        </div>
      </TableCell>
      <TableCell className={styles.tableCell}>
        <div className={styles.textContainer}>
          {meeting.person?.name ?? ''}
        </div>
      </TableCell>
      <TableCell className={styles.tableCell}>
        <div className={styles.textContainer}>
          {meeting.guests.join(', ')}
        </div>
      </TableCell>
      <TableCell className={styles.tableCell}>
        <div className={styles.textContainer}>
          {meeting.description}
        </div>
      </TableCell>
      <TableCell className={styles.tableCell}>
        <ButtonOverlay actions={overlayActions} show />
      </TableCell>
    </TableRow>
  );
};

MeetingListItem.propTypes = {
  meeting: PropTypes.instanceOf(Meeting).isRequired,
  deleteAction: PropTypes.func.isRequired,
  editAction: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  copyAction: PropTypes.func.isRequired,
};

export { MeetingListItem };
