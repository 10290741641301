import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMotiveDistribution } from '../../store/slices/motives-distribution-slice';
import { PieChart } from '../pie-chart';

const MotiveDistributionChart = ({
  dateFrom,
  dateTo,
  preview,
}) => {
  const state = useSelector((selector) => selector.motiveDistribution);
  const dispatch = useDispatch();

  const data = {
    labels: [...(Object.values(state.distribution).map(({ name }) => name))],
    datasets: {
      'Distribución de motivos de consulta':
        { values: [...(Object.values(state.distribution).map(({ value }) => value))] },
    },
  };

  const fetchData = (from, to) => {
    if (!!from && !!to) {
      dispatch(fetchMotiveDistribution({ dateFrom: from, dateTo: to }));
    }
  };

  useEffect(() => fetchData(dateFrom, dateTo), [dateFrom, dateTo]);

  const previewOptions = {
    tooltips: {
      enabled: false,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const baseOptions = { maintainAspectRatio: false };

  return (
    <>
      <PieChart
        data={data}
        options={preview ? previewOptions : baseOptions}
      />
    </>
  );
};

MotiveDistributionChart.propTypes = {
  dateFrom: PropTypes.instanceOf(Date).isRequired,
  dateTo: PropTypes.instanceOf(Date).isRequired,
  preview: PropTypes.bool,
};

MotiveDistributionChart.defaultProps = {
  preview: false,
};

export { MotiveDistributionChart };
