import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { StylesProvider } from '@mui/styles';

import styles from './login.module.scss';
import ADdULogo from '../../assets/images/ADdULogo.png';
import { routeNaming, getRouteFromRouteNaming } from '../../routes/routes';
import { LoadingIndicator } from '../../common/loading-indicator';
import { strings } from '../../config/strings';
import { clearError, requestLogin } from '../../store/slices/session-slice';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formNotSentYet, setFormNotSentYet] = useState(true);
  const { loggedIn, error, loginLoading } = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      const messagesRedirectRoute = getRouteFromRouteNaming(routeNaming.MESSAGES);
      history.push(messagesRedirectRoute);
    }
  }, [loggedIn]);

  const handleOnChangeUsername = (e) => {
    setUsername(e.target.value);
    if (error) {
      dispatch(clearError());
      setFormNotSentYet(true);
    }
  };

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
    if (error) {
      dispatch(clearError());
      setFormNotSentYet(true);
    }
  };

  const validateForm = () => (username.length > 0 && password.length > 0);

  const handleOnClickLogin = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFormNotSentYet(false);
      dispatch(requestLogin({ username, password }));
    }
  };

  // formNotSentYet variable is added in order to avoid multiple clicks
  // on the submit button after one request is sent.
  const isButtonDisabled = () => (
    formNotSentYet && validateForm()
  );

  return (
    <StylesProvider injectFirst>
      <div className={styles.container}>
        <div className={styles.loginContainer}>
          <img alt={strings.ADDU_LOGO_DESCRIPTION} src={ADdULogo} />
          <h1>Iniciar Sesión</h1>
          <form className={styles.loginForm}>

            <div className={styles.loginInputs}>

              {(loginLoading) ? <LoadingIndicator color="secondary" /> : (
                <>
                  <TextField
                    id="username"
                    size="small"
                    type="text"
                    label={strings.LOGIN_USERNAME_LABEL}
                    variant="outlined"
                    value={username}
                    className="whiteOutlinedTextField"
                    onChange={(e) => handleOnChangeUsername(e)}
                    error={error}
                  />

                  <span className={styles.loginInputsSpaceBetween} />

                  <TextField
                    id="password"
                    size="small"
                    type="password"
                    label={strings.LOGIN_PASSWORD_LABEL}
                    variant="outlined"
                    value={password}
                    onChange={(e) => handleOnChangePassword(e)}
                    className="whiteOutlinedTextField"
                    error={error}
                    helperText={
                      error
                        ? strings.LOGIN_CREDENTIALS_ERROR
                        : ''
                    }
                  />
                </>
              )}

            </div>
            {!loginLoading && (
              <button
                className={styles.loginButton}
                type="submit"
                disabled={!isButtonDisabled()}
                onClick={(e) => handleOnClickLogin(e)}
                value={strings.LOGIN_SUBMIT}
              >
                {strings.LOGIN_SUBMIT}
              </button>
            )}

          </form>
        </div>
      </div>
    </StylesProvider>
  );
};

export { Login };
