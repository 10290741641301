import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Title } from '../../common/title/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { LoadingIndicator } from '../../common/loading-indicator';
import { fetchPerson, updatePerson } from '../../store/slices/person-slice';
import { Person } from '../../models/person';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';
import styles from './person-detail.module.scss';
import {
  PersonDetailInfo,
  PersonDetailTimeLine,
} from '../../common/person-detail/person-detail';
import { CreateEventModal } from '../../common/create-event-modal/create-event-modal';
import { CreateMeetingModal } from '../../common/create-meeting-modal/create-meeting-modal';

const PersonDetail = () => {
  const { person, loading, error } = useSelector((selector) => selector.person);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [openMeeting, setOpenMeeting] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    dispatch(fetchPerson(id));
  }, [id]);

  useEffect(() => {
    const headerOffset = 222;
    if (person.events) {
      const element = document.getElementById(`eventCard_${query.get('eventId')}`);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [person, query]);

  const handlePersonChange = (change) => {
    dispatch(updatePerson({ ...person, ...change }));
  };

  return (
    <div className={globalStyles.genericContainer}>
      {error && <ErrorPage />}
      {loading && <LoadingIndicator />}
      {(!loading && !error) && (
        <>
          <div>
            <Title text={strings.PERSON_DETAIL_TITLE} />
          </div>
          <div className={styles.component}>
            <div className={styles.column}>
              <div className={styles.spacer} />
              <PersonDetailInfo person={new Person(person)} onChange={handlePersonChange} />
            </div>
            <div className={styles.column}>
              <div className={styles.spacer} />
              <PersonDetailTimeLine
                person={new Person(person)}
                onClickCreateEvent={() => setOpen(true)}
                onClickCreateMeeting={() => setOpenMeeting(true)}
                selectedEvent={query.get('eventId')}
              />
            </div>
          </div>
          <CreateEventModal open={open} handleClose={() => setOpen(false)} personId={+id} />
          <CreateMeetingModal
            open={openMeeting}
            handleClose={() => setOpenMeeting(false)}
            person={new Person(person)}
          />
        </>
      )}
    </div>
  );
};

export { PersonDetail };
