/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MeetingsController } from '../../networking/controllers/meetings-controller';

const initialState = {
  meetings: [],
  currentPage: 0,
  pastPage: 0,
  itemsPerPage: 10,
  meetingsCount: 0,
  showPast: false,
  error: '',
  loading: false,
};

const fetchMeetings = createAsyncThunk(
  'meetings/fetchMeetingsStatus',
  async ({
    newPage,
    newItemsPerPage,
    pastNewPage,
    showPast,
  }) => {
    const response = await MeetingsController.getMeetings(
      newItemsPerPage,
      showPast,
      showPast ? pastNewPage : newPage,
    );
    return {
      meetings: response.meetings,
      paging: {
        newPage: showPast ? newPage : response.page,
        newItemsPerPage,
        pastNewPage: showPast ? pastNewPage : response.page,
        count: response.count,
      },
      showPast,
    };
  },
);

const setLoading = (state) => {
  state.loading = true;
  state.error = '';
};

const setError = (state, action) => {
  state.loading = false;
  state.error = action.error;
};

const setMeetings = (state, action) => {
  const {
    newPage, newItemsPerPage, pastNewPage, count,
  } = action.payload.paging;
  state.meetings = action.payload.meetings;
  state.pastPage = pastNewPage;
  state.currentPage = newPage;
  state.itemsPerPage = newItemsPerPage;
  state.meetingsCount = count;
  state.showPast = action.payload.showPast;
  state.error = '';
  state.loading = false;
};

const meetingsSlice = createSlice({
  name: 'meetings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeetings.pending, setLoading)
      .addCase(fetchMeetings.fulfilled, setMeetings)
      .addCase(fetchMeetings.rejected, setError);
  },
});

export { fetchMeetings };

export default meetingsSlice.reducer;
