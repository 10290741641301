const educationTypes = {
  BABY_SCHOOL: { name: 'Maternal', id: 0 },
  KINDER: { name: 'Inicial', id: 1 },
  PRIMARY_SCHOOL: { name: 'Primaria', id: 2 },
  HIGH_SCHOOL: { id: 3, name: 'Secundaria' },
  TERCIARY: { name: 'Educación terciaria', id: 4 },
  OTHER: { name: 'Otro', id: 5 },
};

export default educationTypes;
