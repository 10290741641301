import { Event } from './event';

class EducationAbandonmentEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.motive = params.motive;
    this.educationType = params.educationType;
  }
}

export { EducationAbandonmentEvent };
