import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';

import { DropdownSelect } from 'common/dropdown-select/dropdown-select';
import { strings } from '../../config/strings';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './select-option-dialog.module.scss';

const SelectOptionDialog = (props) => {
  const {
    open, title, optionsLabel, options, onConfirm, onCancel,
  } = props;

  const [selectedValue, setSelectedValue] = useState(options[0]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DropdownSelect
          className={styles.fullWidth}
          label={optionsLabel}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          items={options}
        />
        <div className={styles.spacer} />
        <div className={styles.confirmCancelButtonsContainer}>
          <Button
            variant="contained"
            component="span"
            className={globalStyles.primaryButton}
            onClick={() => onConfirm(selectedValue)}
          >
            {strings.UTILS_ACCEPT}
          </Button>
          <Button
            variant="contained"
            component="span"
            className={globalStyles.secondaryButton}
            onClick={() => onCancel()}
          >
            {strings.UTILS_CANCEL}
          </Button>
        </div>

      </DialogContent>

    </Dialog>
  );
};

SelectOptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  optionsLabel: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { SelectOptionDialog };
