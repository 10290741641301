import { combineReducers } from 'redux';
import formReducer from './slices/form-slice';
import messagesReducer from './slices/messages-slice';
import peopleReducer from './slices/people-slice';
import sessionReducer from './slices/session-slice';
import messageReducer from './slices/message-slice';
import eventsReducer from './slices/send-event-slice';
import personReducer from './slices/person-slice';
import passwordChangeReducer from './slices/password-slice';
import educationLevelStatisticSlice from './slices/education-level-statistic-slice';
import motivesDistributionReducer from './slices/motives-distribution-slice';
import inquiryAmountStatisticReducer from './slices/inquiry-amount-statistic-slice';
import meetingReducer from './slices/meeting-slice';
import educationResultsReducer from './slices/education-results-slice';
import meetingsReducer from './slices/meetings-slice';

const reducers = combineReducers({
  people: peopleReducer,
  messages: messagesReducer,
  session: sessionReducer,
  message: messageReducer,
  form: formReducer,
  password: passwordChangeReducer,
  events: eventsReducer,
  person: personReducer,
  educationLevelStatistics: educationLevelStatisticSlice,
  motiveDistribution: motivesDistributionReducer,
  inquiryAmountStatistic: inquiryAmountStatisticReducer,
  meeting: meetingReducer,
  educationResults: educationResultsReducer,
  meetings: meetingsReducer,
});

export default reducers;
