import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { UsersEmailsListSerializer } from '../serializers/users-emails-list-serializer';

class UsersEmailsListController {
  static async getUsersEmailsList() {
    return ApiService.get(API_ROUTES.USERS_INDEX)
      .then((response) => UsersEmailsListSerializer.deSerialize(response.data));
  }
}

export { UsersEmailsListController };
