/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatisticsController } from '../../networking/controllers/statistics-controller';

const initialState = {
  educationResults: {
    BABY_SCHOOL: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    KINDER: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    PRIMARY_SCHOOL: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    MIDDLE_BASIC: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    MIDDLE_UPPER: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    TERCIARY: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
    OTHER: {
      Abandonaron: 0,
      Repitieron: 0,
      Aprobaron: 0,
    },
  },
  loading: false,
  error: '',
};

const fetchEducationResults = createAsyncThunk(
  'educationResults/fetchStatistics',
  async (date) => StatisticsController.getEducationResultsStatistics(date),
);

const setLoading = (state) => {
  state.loading = true;
  state.error = false;
};

const setEducationResults = (state, action) => {
  state.error = '';
  state.loading = false;
  state.educationResults = action.payload;
};

const setError = (state, action) => {
  state.error = action.error;
  state.loading = false;
};

const educationResultsStatisticSlice = createSlice({
  name: 'education-level-statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducationResults.pending, setLoading)
      .addCase(fetchEducationResults.fulfilled, setEducationResults)
      .addCase(fetchEducationResults.rejected, setError);
  },
});

export { fetchEducationResults };

export default educationResultsStatisticSlice.reducer;
