import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import { strings } from '../../config/strings';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './confirm-dialog.module.scss';

const ConfirmDialog = (props) => {
  const {
    open, title, message, onConfirm, onCancel,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <p>{message}</p>
        <div className={styles.spacer} />
        <div className={styles.confirmCancelButtonsContainer}>
          <Button
            variant="contained"
            component="span"
            className={globalStyles.primaryButton}
            onClick={onConfirm}
          >
            {strings.UTILS_ACCEPT}
          </Button>
          <Button
            variant="contained"
            component="span"
            className={globalStyles.secondaryButton}
            onClick={onCancel}
          >
            {strings.UTILS_CANCEL}
          </Button>
        </div>

      </DialogContent>

    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { ConfirmDialog };
