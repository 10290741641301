import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const routeNaming = {
  HOME: 'home',
  LOGIN: 'login',
  PASSWORD_CHANGE_SCREEN: 'passwordChangeScreen',
  NOT_FOUND: 'notFound',
  MESSAGES: 'messages',
  PEOPLE: 'people',
  MESSAGE_DETAIL: 'messageDetail',
  DOWNLOADS: 'downloads',
  PERSON_DETAIL: 'personDetail',
  ENROLL_PERSON: 'enrollPerson',
  STATISTICS: 'statistics',
  EDUCATION_LEVEL_STATISTIC: 'educationLevelStatistic',
  INFORMED_EXCLUSION_STATISTICS: 'informedExclusionStatistics',
  MOTIVE_DISTRIBUTION_STATISTICS: 'motiveDistributionStatistics',
  INQUIRY_AMOUNT_STATISTIC: 'inquiryAmountStatistic',
  EDUCATION_RESULTS_STATISTICS: 'educationResultsStatistics',
  MEETINGS: 'meetings',
};

const PATH_PARAM_REGEX = /:[^/]+/gi;
/**
  Analyzes the path defined for `route` and
  returns a copy of the route with a new attribute
  `pathParams` which is a list of strings that correspond to the path params.

  @param {object} route - Object that represents a route.

  @return {object} updated route with the new attribute.

  @example

    determineRouteParams({ name: 'product', path: '/product/:id', component: ProductPage })
*/
const determineRouteParams = (route) => {
  const newRoute = { ...route };
  const { path } = newRoute;
  // Extract the names of the parameters
  const pathMatch = path.match(PATH_PARAM_REGEX) || [];
  const pathParams = pathMatch.map((param) => param.slice(1));
  newRoute.pathParams = pathParams || [];
  return newRoute;
};

const routes = [
  {
    name: routeNaming.ENROLL_PERSON,
    path: '/messages/enroll/:id',
  },
  {
    name: routeNaming.MESSAGE_DETAIL,
    path: '/messages/:id',
  },
  {
    name: routeNaming.MESSAGES,
    path: '/messages',
    exact: true,
  },
  {
    name: routeNaming.PASSWORD_CHANGE_SCREEN,
    path: '/password',
  },
  {
    name: routeNaming.PERSON_DETAIL,
    path: '/people/:id',
  },
  {
    name: routeNaming.PEOPLE,
    path: '/people',
    exact: true,
  },
  {
    name: routeNaming.HOME,
    path: '/',
    exact: true,
  },
  {
    name: routeNaming.LOGIN,
    path: '/login',
  },
  {
    name: routeNaming.DOWNLOADS,
    path: '/downloads',
  },
  {
    name: routeNaming.STATISTICS,
    path: '/statistics',
    exact: true,
  },
  {
    name: routeNaming.EDUCATION_LEVEL_STATISTIC,
    path: '/statistics/education-level',
  },
  {
    name: routeNaming.INFORMED_EXCLUSION_STATISTICS,
    path: '/statistics/exclusion-situations',
  },
  {
    name: routeNaming.MOTIVE_DISTRIBUTION_STATISTICS,
    path: '/statistics/motive-distribution',
  },
  {
    name: routeNaming.INQUIRY_AMOUNT_STATISTIC,
    path: '/statistics/inquiry-amount',
  },
  {
    name: routeNaming.EDUCATION_RESULTS_STATISTICS,
    path: '/statistics/education-results',
  },
  {
    name: routeNaming.MEETINGS,
    path: '/meetings',
  },
  {
    name: routeNaming.NOT_FOUND,
    path: '*',
  },
].map(determineRouteParams);

const getRouteFromRouteNaming = (routeName) => {
  const routeObject = routes.find((element) => element.name === routeName);
  return (routeObject) ? routeObject.path : '';
};

export {
  determineRouteParams,
  history,
  routeNaming,
  routes,
  getRouteFromRouteNaming,
};
