const eventType = {
  // This order matters to create Event Form.
  EXTRAORDINARY_HELP_EVENT: { id: 0, display: 'Ayudas extraordinarias' },
  DISCRIMINATION_EVENT: { id: 1, display: 'Situación de discriminación' },
  EDUCATION_ABANDONMENT_EVENT: { id: 2, display: 'Abandono del sistema educativo' },
  EGRESS_EVENT: { id: 3, display: 'Egreso de institución educativa' },
  COURSE_EVENT: { id: 4, display: 'Evento de curso' },
  INSTITUTION_ENROLLMENT_EVENT: { id: 5, display: 'Ingreso a institución educativa' },
  INSTITUTION_CHANGE_EVENT: { id: 6, display: 'Cambio de institución' },
  MEETING_EVENT: { id: 7, display: 'Reunión' },
  FOLLOW_UP_EVENT: { id: 8, display: 'Acción de seguimiento' },
};

export default eventType;
