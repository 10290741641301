import React from 'react';
import PropTypes from 'prop-types';
import { GenericFormSelect } from './common/generic-form-select';
import helpTypes from '../../../config/help-types';
import { strings } from '../../../config/strings';

// import styles from './create-event-modal.module.scss';

const ExtraordinaryHelpEventForm = ({ values, changeValues }) => (
  <>
    <GenericFormSelect
      value={values.helpType}
      id="helpTypeSelect"
      label={strings.EVENT_CARD_HELP_TYPE}
      required
      onChange={(e) => changeValues('helpType', e.target.value)}
      options={Object.entries(helpTypes).map(([key, value]) => ({ value: key, label: value }))}
    />
  </>
);

ExtraordinaryHelpEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export { ExtraordinaryHelpEventForm };
