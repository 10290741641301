import React from 'react';
import { AppLink, routeNaming } from 'routes';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import ADdULogo from '../../assets/images/logo.png';
import styles from './error-page.module.scss';
import { strings } from '../../config/strings';

const ErrorPage = () => {
  const { loggedIn } = useSelector((state) => state.session);
  return (
    <div className={styles.container}>
      <img alt={strings.ADDU_LOGO_DESCRIPTION} src={ADdULogo} />
      <div className={styles.textContainer}>
        <h1>{strings.ERROR_500_TITLE}</h1>
        <p>{strings.ERROR_500_MESSAGE_1}</p>
        <div className={styles.redirectContainer}>
          <p>{strings.ERROR_500_MESSAGE_2}</p>
          {
          loggedIn
            ? (
              <AppLink routeName={routeNaming.MESSAGES}>
                <Button
                  variant="contained"
                  component="span"
                  className={globalStyles.primaryButton}
                >
                  {strings.ERROR_500_BUTTON}
                </Button>
              </AppLink>
            )
            : (
              <AppLink routeName={routeNaming.MESSAGES}>
                <Button
                  variant="contained"
                  component="span"
                  className={globalStyles.primaryButton}
                >
                  {strings.ERROR_500_BUTTON}
                </Button>
              </AppLink>
            )
        }
        </div>
      </div>
    </div>
  );
};

export { ErrorPage };
