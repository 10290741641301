import React from 'react';

import { LoginRequired } from './login-required';

const withLoginRequired = (Component) => (
  (props) => (
    <LoginRequired>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </LoginRequired>
  )
);

export { withLoginRequired };
