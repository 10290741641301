import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Stack,
  TextField,
  Chip,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Cancel, Save } from '@mui/icons-material';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import styles from './person-detail.module.scss';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { Person } from '../../models/person';
import { formatDate } from '../../helpers/utils';
import { PersonPreviewController } from '../../networking/controllers/person-preview-controller';
import { UsersEmailsListController } from '../../networking/controllers/users-emails-list-controller';
import { EventController } from '../../networking/controllers/event-controller';
import { strings } from '../../config/strings';
import { CustomSnackbar } from '../custom-snackbar/custom-snackbar';
import { EventCard } from '../person-detail-event-card/person-detail-event-card';
import { ValueOrBlankField } from '../value-or-blank-field/value-or-blank-field';
import { ValueOrBlankFieldFlex } from '../value-or-blank-field-flex/value-or-blank-field-flex';
import { ConfirmDialog } from '../confirm-dialog';
import { PrimaryTextfield } from '../primary-textfield/primary-textfield';
import { fetchPerson, updatePerson } from '../../store/slices/person-slice';
import { NoMarginDropdownSelect } from '../dropdown-select/dropdown-select';
import relationshipTypes from '../../config/relationship-types';
import motiveTypes from '../../config/motive-types';
import { PersonController } from '../../networking/controllers/person-controller';
import { SelectOptionDialog } from '../select-option-dialog';
import { checkUser } from '../../store/slices/session-slice';

const PersonDetailInfo = ({ person, onChange }) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');
  const dispatch = useDispatch();

  const { loggedUser } = useSelector((state) => state.session);
  const [shouldRenderUnsuscribeButton, setShouldRenderUnsuscribeButton] = useState(false);

  // const [selectedEmail, setSelectedEmail] = useState(undefined);
  const [subscribeEmails, setSubscribeEmails] = useState([]);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);
  const [openConfirmArchiveDialog, setOpenConfirmArchiveDialog] = useState(false);

  // we save the person on our local state in order to modify it for example
  // when setting it as archived.
  const [editing, setEditing] = useState(false);

  // we make sure that we have the logged in user's email
  useEffect(() => {
    dispatch(checkUser());
  }, []);

  useEffect(() => {
    if (person && person.subscribedUsers && loggedUser) {
      setShouldRenderUnsuscribeButton(person.subscribedUsers.map(
        (i) => i.email,
      ).includes(loggedUser.email));
    } else {
      setShouldRenderUnsuscribeButton(false);
    }
  }, [loggedUser, person]);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (message) => {
    setSnackSeverity('success');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const isEmail = (string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(string).toLowerCase());
  };

  const validate = () => (
    !!person.contactName
    && ((!!person.contactEmail && isEmail(person.contactEmail)) || !!person.contactPhoneNumber)
    && !!person.name
    && !!person.birthDate && new Date(person.birthDate) < new Date()
  );

  const requestArchivePerson = async (personId) => {
    try {
      await PersonPreviewController.archivePerson(personId);
      // we update the person's 'archived' attribute.
      // we need to do it this way because the 'person' variable
      // passed as a parameter is inmutable.
      dispatch(updatePerson({ ...person, archived: !person.archived }));

      if (!person.archived) {
        // if it's now archived, it was unarchived.
        showSnackSuccess(strings.PEOPLE_ARCHIVED_SUCCESS_SNACK);
      } else {
        showSnackSuccess(strings.PEOPLE_UNARCHIVED_SUCCESS_SNACK);
      }
    } catch (err) {
      showSnackError(strings.PEOPLE_ARCHIVED_ERROR_SNACK);
    }
  };

  const handleSubscribeToPersonClick = async () => {
    try {
      const response = await UsersEmailsListController.getUsersEmailsList();

      const alreadySuscribedEmails = person.subscribedUsers.map(
        (user) => user.email,
      );

      const filteredEmails = response.emails.filter(
        (mail) => !alreadySuscribedEmails.includes(mail),
      );

      if (filteredEmails.length > 0) {
        // we only include the emails that are not already subscribed.
        setSubscribeEmails(filteredEmails);

        // if the logged user is available, we define the logged user as the default option
        // moving it to the first position of the array.
        if (loggedUser
          && loggedUser.email
          && filteredEmails.includes(loggedUser.email)) {
          const indexLoggedUserEmail = filteredEmails.indexOf(loggedUser.email);
          filteredEmails.unshift(filteredEmails.splice(indexLoggedUserEmail, 1)[0]);
        }

        setOpenSubscribeDialog(true);
      } else {
        showSnackError(strings.PERSON_SUBSCRIBE_ERROR_NO_USERS_LEFT_SNACK);
      }
    } catch {
      showSnackError(strings.PERSON_SUBSCRIBE_ERROR_FETCHING_EMAILS_SNACK);
    }
  };

  const handleConfirmSubscribeToPerson = async (email) => {
    try {
      const response = await PersonController.subscribeToPerson(person.id, email);
      // we update the person's redux data if success
      dispatch(updatePerson({ ...person, subscribedUsers: response.subscribedUsers }));
      showSnackSuccess(strings.PERSON_SUBSCRIBED_SUCCESS_SNACK);
    } catch {
      showSnackError(strings.PERSON_SUBSCRIBED_ERROR_SNACK);
    }
    setOpenSubscribeDialog(false);
  };

  const handleUnsubscribeToPersonClick = async () => {
    try {
      const response = await PersonController.subscribeToPerson(person.id, loggedUser.email);
      // we update the person's redux data if success
      dispatch(updatePerson({ ...person, subscribedUsers: response.subscribedUsers }));
      showSnackSuccess(strings.PERSON_UNSUBSCRIBED_SUCCESS_SNACK);
    } catch {
      showSnackError(strings.PERSON_SUBSCRIBED_ERROR_SNACK);
    }
  };

  const handleCancelSubscribeToPerson = () => {
    setOpenSubscribeDialog(false);
  };

  const handleSaveClick = async () => {
    try {
      await PersonController.updatePerson(person);
      dispatch(fetchPerson(person.id));
      showSnackSuccess(strings.PERSON_UPDATED_SUCCESSFULLY);
    } catch (error) {
      showSnackError(error.message);
    }
  };

  const handleConfirmArchive = () => {
    requestArchivePerson(person.id);
    setOpenConfirmArchiveDialog(false);
  };

  const handleCancelArchive = () => {
    setOpenConfirmArchiveDialog(false);
  };

  // eslint-disable-next-line react/prop-types
  const RelationshipDropDown = ({ value }) => (
    <NoMarginDropdownSelect
      label={strings.MESSAGE_RELATIONSHIP_TYPE}
      value={value || person.relationshipType}
      onChange={(event) => onChange({ relationshipType: event.target.value })}
      items={relationshipTypes}
      className={styles.fullWidth}
    />
  );

  // eslint-disable-next-line react/prop-types
  const MotiveDropDown = ({ value }) => (
    <NoMarginDropdownSelect
      label={strings.MESSAGE_MOTIVE}
      value={value || person.motive}
      onChange={(event) => onChange({ motive: event.target.value })}
      items={motiveTypes}
      className={styles.fullWidth}
    />
  );

  return (
    <div className={styles.component}>
      <CustomSnackbar
        open={snackOpen}
        onClose={handleSnackClose}
        severity={snackSeverity}
        message={snackMessage}
      />
      {openSubscribeDialog && (
        <SelectOptionDialog
          open={openSubscribeDialog}
          onConfirm={handleConfirmSubscribeToPerson}
          onCancel={handleCancelSubscribeToPerson}
          title={strings.PERSON_SUBSCRIBE_DIALOG_TITLE}
          options={subscribeEmails}
          optionsLabel="Email"
        />
      )}
      <div className={styles.nameAndButtons}>
        <div>
          <div className={styles.personName}>
            {
              editing
                ? (
                  <PrimaryTextfield
                    label={strings.FORM_PERSON_NAME_LABEL}
                    value={person.name}
                    onChange={(event) => onChange({ name: event.target.value })}
                    required
                  />
                )
                : person.name
            }

            {person.imported ? (
              <Chip
                label={strings.IMPORTED}
                color="secondary"
                className={styles.importedFlag}
              />
            ) : ''}
          </div>

          <div className={styles.spacer} />
          {editing
            ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={2}>
                  <DatePicker
                    label={strings.FORM_PERSON_BIRTHDAY_LABEL}
                    inputFormat="dd/MM/yyyy"
                    value={person.birthDate}
                    maxDate={new Date()}
                    onChange={(e) => onChange({ birthDate: e })}
                    /* eslint-disable react/jsx-props-no-spreading */
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        required
                        className="primaryOutlinedTextField"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        error={!person.birthDate || person.birthDate > new Date()}
                        helperText={(!person.birthDate || person.birthDate > new Date()) ? strings.FORM_PERSON_BIRTHDAY_ERROR : ''}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            )
            : (
              <p className={styles.personBithDate}>{formatDate(person.birthDate)}</p>
            )}
          <div className={styles.bigSpacer} />
        </div>
        <div className={styles.containerButton}>
          {editing
            ? (
              <>
                <Button
                  className={globalStyles.primaryButton}
                  color="primary"
                  variant="contained"
                  startIcon={<Save />}
                  onClick={handleSaveClick}
                  disabled={!validate()}
                >
                  {strings.PERSON_DETAIL_SAVE}
                </Button>
                <div className={styles.horizontalSpacer} />
                <Button
                  className={globalStyles.primaryButton}
                  startIcon={<Cancel />}
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.reload()}
                >
                  {strings.PERSON_DETAIL_CANCEL}
                </Button>
              </>
            )
            : (
              <>
                <>
                  <Button
                    className={globalStyles.primaryButton}
                    variant="contained"
                    color="primary"
                    startIcon={person.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                    onClick={() => setOpenConfirmArchiveDialog(true)}
                  >
                    {person.archived ? 'Desarchivar' : 'Archivar'}
                  </Button>
                  <div className={styles.horizontalSpacer} />
                  <Button
                    className={globalStyles.primaryButton}
                    startIcon={<EditIcon />}
                    color="primary"
                    variant="contained"
                    onClick={() => setEditing(true)}
                  >
                    {strings.PERSON_DETAIL_EDIT}
                  </Button>
                  <div className={styles.horizontalSpacer} />
                  <Button
                    className={globalStyles.primaryButton}
                    variant="contained"
                    color="primary"
                    startIcon={<NotificationAddIcon />}
                    onClick={() => handleSubscribeToPersonClick()}
                  >
                    {strings.PERSON_SUBSCRIBE}
                  </Button>
                  {
                    shouldRenderUnsuscribeButton
                    && (
                      <>
                        <div className={styles.horizontalSpacer} />
                        <Button
                          className={globalStyles.primaryButton}
                          variant="contained"
                          color="primary"
                          startIcon={<HighlightOffIcon />}
                          onClick={() => handleUnsubscribeToPersonClick()}
                        >
                          {strings.PERSON_DETAIL_UNSUSCRIBE}
                        </Button>
                      </>
                    )
                  }
                </>
              </>
            )}
        </div>
      </div>
      {editing && (
        Object.values(motiveTypes)
          .some((item) => item === person.motive) && person.motive !== motiveTypes.OTHER
          ? <MotiveDropDown /> : (
            <>
              <MotiveDropDown value={motiveTypes.OTHER} />
              <div className={styles.spacer} />
              <PrimaryTextfield
                label={strings.MESSAGE_MOTIVE_OTHER}
                value={person.motive}
                onChange={(event) => onChange({ motive: event.target.value })}
                multiline
                required
              />
            </>
          )
      )}
      {!editing && (
        <ValueOrBlankField
          label={strings.PERSON_DETAIL_MOTIVE}
          value={person.motive}
        />
      )}
      <div className={styles.bigSpacer} />
      <ValueOrBlankField
        label={strings.PERSON_DETAIL_DESCRIPTION}
        value={person.description}
        editing={editing}
        onChange={(event) => onChange({ description: event.target.value })}
        multiline
      />
      <div className={styles.bigSpacer} />
      <p className={styles.title}>{strings.PERSON_DETAIL_CONTACT}</p>
      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_CONTACT_NAME}
          className={styles.fullWidth}
          value={person.contactName}
          editing={editing}
          onChange={(event) => onChange({ contactName: event.target.value })}
          required
        />
      </div>
      <div className={styles.spacer} />

      <div className={styles.marginInfo}>
        {editing && ((Object.values(relationshipTypes)
          .some((item) => item === person.relationshipType)
          && person.relationshipType !== relationshipTypes.OTHER)
          ? <RelationshipDropDown /> : (
            <>
              <RelationshipDropDown value={relationshipTypes.OTHER} />
              <div className={styles.spacer} />
              <PrimaryTextfield
                label={strings.MESSAGE_RELATIONSHIP_TYPE_OTHER}
                value={person.relationshipType ? person.relationshipType : ''}
                onChange={(event) => onChange({ relationshipType: event.target.value })}
                className={styles.fullWidth}
              />
            </>
          ))}
        {!editing && (
          <ValueOrBlankFieldFlex
            label={strings.PERSON_DETAIL_CONTACT_RELATIONSHIP}
            value={person.relationshipType}
          />
        )}
      </div>
      <div className={styles.spacer} />

      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_CONTACT_PHONE}
          value={person.contactPhoneNumber}
          className={styles.fullWidth}
          editing={editing}
          onChange={(event) => { if (/^((\+)?\d*(\s)?\d*)$/.test(event.target.value)) onChange({ contactPhoneNumber: event.target.value }); }}
        />
      </div>
      <div className={styles.spacer} />

      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_CONTACT_EMAIL}
          value={person.contactEmail}
          className={styles.fullWidth}
          editing={editing}
          onChange={(event) => onChange({ contactEmail: event.target.value })}
        />
      </div>
      <div className={styles.bigSpacer} />

      <p className={styles.title}>{strings.PERSON_DETAIL_INSTITUTION}</p>
      {editing && (
        <p className={styles.personDetailComment}>
          Información generada a partir de los eventos asociados a la persona.
          Para editarla, por favor diríjase a los eventos de dicha persona.
        </p>
      )}
      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_INSTITUTION_TYPE}
          value={person.institutionType.name}
        />
      </div>
      <div className={styles.spacer} />

      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_INSTITUTION_NAME}
          value={person.institutionName}
          editing={false}
          onChange={(event) => onChange({ institutionName: event.target.value })}
          className={styles.fullWidth}
        />
      </div>
      <div className={styles.spacer} />

      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_INSTITUTION_LOCALITY}
          value={person.institutionLocality}
          editing={false}
          onChange={(event) => onChange({ institutionLocality: event.target.value })}
          className={styles.fullWidth}
        />
      </div>
      <div className={styles.spacer} />
      <div className={styles.marginInfo}>
        <ValueOrBlankFieldFlex
          label={strings.PERSON_DETAIL_EDUCATION_TYPE}
          value={person.educationType?.name}
        />
      </div>
      {!editing && (
        <>
          <div className={styles.spacer} />
          <div className={styles.marginInfo}>
            <ValueOrBlankFieldFlex
              label={strings.PERSON_DETAIL_COURSE}
              value={person.course}
            />
          </div>
        </>
      )}
      <div className={styles.bigSpacer} />

      {editing
        ? (
          <PrimaryTextfield
            label={strings.PERSON_DETAIL_SUGGESTIONS}
            value={person.suggestions}
            multiline
            onChange={(event) => onChange({ suggestions: event.target.value })}
          />
        )
        : (
          <>
            <p className={styles.title}>{strings.PERSON_DETAIL_SUGGESTIONS}</p>
            <div className={styles.marginlastThree}>
              <ValueOrBlankField
                label=""
                value={person.suggestions}
              />
            </div>
          </>
        )}
      <div className={styles.bigSpacer} />
      {editing
        ? (
          <PrimaryTextfield
            label={strings.PERSON_DETAIL_FOLLOWUP}
            value={person.followup}
            multiline
            onChange={(event) => onChange({ followup: event.target.value })}
          />
        )
        : (
          <>
            <p className={styles.title}>{strings.PERSON_DETAIL_FOLLOWUP}</p>
            <div className={styles.marginlastThree}>
              <ValueOrBlankField
                label=""
                value={person.followup}
              />
            </div>
          </>
        )}
      <div className={styles.bigSpacer} />
      {editing
        ? (
          <PrimaryTextfield
            label={strings.PERSON_DETAIL_COMMENTS}
            value={person.comments}
            multiline
            onChange={(event) => onChange({ comments: event.target.value })}
          />
        )
        : (
          <>
            <p className={styles.title}>{strings.PERSON_DETAIL_COMMENTS}</p>
            <div className={styles.marginlastThree}>
              <ValueOrBlankField
                label=""
                value={person.comments}
              />
            </div>
          </>
        )}
      <div className={styles.bigSpacer} />
      <ConfirmDialog
        open={openConfirmArchiveDialog}
        title={person.archived ? strings.PERSON_UNARCHIVE : strings.PERSON_ARCHIVE}
        message={
          person.archived
            ? strings.PERSON_UNARCHIVE_DIALOG
            : strings.PERSON_ARCHIVE_DIALOG
        }
        onConfirm={handleConfirmArchive}
        onCancel={handleCancelArchive}
      />
    </div>
  );
};

const PersonDetailTimeLine = ({
  person,
  onClickCreateEvent,
  onClickCreateMeeting,
  selectedEvent,
}) => {
  const [personEvents, setPersonEvents] = useState(person.events);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const [eventIdToDelete, setEventIdToDelete] = useState('');
  const [openDeleteArchiveDialog, setOpenConfirmDeleteEventDialog] = useState(false);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (message) => {
    setSnackSeverity('success');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const requestDeleteEvent = async () => {
    try {
      await EventController.deleteEvent(eventIdToDelete);
      // if success, update the data displayed on the screen.
      setPersonEvents(personEvents.filter((e) => e.id !== eventIdToDelete));
      showSnackSuccess(strings.PERSON_DETAIL_EVENT_DELETE_SUCCESS_SNACK);
      setEventIdToDelete(null);
      window.location.reload();
    } catch (err) {
      showSnackError(strings.PERSON_DETAIL_EVENT_DELETE_ERROR_SNACK);
      setEventIdToDelete(null);
    }
  };

  const handleConfirmDeleteEvent = () => {
    requestDeleteEvent();
    setOpenConfirmDeleteEventDialog(false);
  };

  const handleCancelDeleteEvent = () => {
    setOpenConfirmDeleteEventDialog(false);
    setEventIdToDelete(null);
  };

  const handleDeleteEventClick = (eventId) => {
    setEventIdToDelete(eventId);
    setOpenConfirmDeleteEventDialog(true);
  };

  return (
    <div className={styles.component}>
      <CustomSnackbar
        open={snackOpen}
        onClose={handleSnackClose}
        severity={snackSeverity}
        message={snackMessage}
      />

      <ConfirmDialog
        open={openDeleteArchiveDialog}
        title={strings.PERSON_DETAIL_EVENT_DELETE_DIALOG_TITLE}
        message={strings.PERSON_DETAIL_EVENT_DELETE_DIALOG_MESSAGE}
        onConfirm={handleConfirmDeleteEvent}
        onCancel={handleCancelDeleteEvent}
      />

      <div className={styles.containerButtonAddEvent}>
        <Button
          className={globalStyles.primaryButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onClickCreateEvent}
        >
          {strings.ADD_EVENT}
        </Button>
        <div className={styles.horizontalSpacer} />
        <div className={styles.verticalMobileSpacer} />
        <Button
          className={globalStyles.primaryButton}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onClickCreateMeeting}
        >
          {strings.MEETING_ARRANGE}
        </Button>
      </div>
      {Object.entries(personEvents
        .reduce((acc, value) => {
          const year = value.date.split('/')[0];
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(value);
          return acc;
        }, {}))
        .reverse()
        .map(([year, events]) => (
          <VerticalTimeline key={events[0].id.toString()} animate={false} layout="1-column-left" className={styles.verticalTimeLineColor}>
            <VerticalTimelineElement className={styles.verticalTimelineElementDate}>
              <h2>{year}</h2>
            </VerticalTimelineElement>
            {events.map(
              (event) => (
                <EventCard
                  key={event.id.toString()}
                  event={event}
                  onDelete={() => handleDeleteEventClick(event.id)}
                  // eslint-disable-next-line eqeqeq
                  selected={event.id == selectedEvent}
                />
              ),
            )}
          </VerticalTimeline>
        ))}
    </div>
  );
};

PersonDetailInfo.propTypes = {
  person: PropTypes.instanceOf(Person).isRequired,
  onChange: PropTypes.func,
};

PersonDetailInfo.defaultProps = {
  onChange: () => { },
};

PersonDetailTimeLine.propTypes = {
  person: PropTypes.instanceOf(Person).isRequired,
  onClickCreateEvent: PropTypes.func.isRequired,
  onClickCreateMeeting: PropTypes.func.isRequired,
  selectedEvent: PropTypes.number,
};

PersonDetailTimeLine.defaultProps = {
  selectedEvent: undefined,
};

export { PersonDetailInfo };
export { PersonDetailTimeLine };
