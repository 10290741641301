import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { EnrolledPeopleCountSerializer } from '../serializers/enrolled_people_count_serializer';

class EnrolledPeopleCountController {
  static async enrolledPeopleCount() {
    const response = await ApiService.get(API_ROUTES.ENROLLED_PEOPLE_COUNT);
    return EnrolledPeopleCountSerializer.deSerialize(response.data);
  }
}

export { EnrolledPeopleCountController };
