import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import styles from './education-level-statistic.module.scss';
import { LoadingIndicator } from '../../common/loading-indicator';
import { ErrorPage } from '../../common/error-page';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { strings } from '../../config/strings';
import { Title } from '../../common/title';
import { EnrolledPeopleComponent } from '../../common/enrolled-people/enrolled-people';
import { EducationLevelChart } from '../../common/education-level-chart';

const initialEducationLevelState = {
  date: new Date(),
};

const EducationLevelStatistic = () => {
  const { loading, error } = useSelector(
    (selector) => selector.educationLevelStatistics,
  );
  const [educationLevelState, setEducationLevelState] = useState(initialEducationLevelState);
  const [errorYear, setErrorYear] = useState(false);
  const [errorYearRequired, setErrorYearRequired] = useState(false);

  const onYearChange = (e) => {
    setEducationLevelState({ ...educationLevelState, date: e });
    setErrorYear(false);
    if (e > new Date()) {
      setErrorYear(true);
    }
    if (e === null) {
      setErrorYearRequired(true);
    }
  };

  return (
    <div className={globalStyles.genericContainer}>
      {error && <ErrorPage />}
      {loading && <LoadingIndicator />}
      <div className={styles.component}>
        <div>
          <Title text={strings.EDUCATION_LEVEL_STATISTIC} />
        </div>
        <div>
          <EnrolledPeopleComponent />
        </div>
      </div>
      <div className={styles.component}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={2}>
            <DatePicker
              views={['year']}
              label={strings.UTILS_YEAR}
              value={educationLevelState.date}
              maxDate={new Date()}
              onChange={onYearChange}
              /* eslint-disable react/jsx-props-no-spreading */
              renderInput={(props) => (
                <TextField
                  {...props}
                  required
                  className="primaryOutlinedTextField"
                  variant="outlined"
                  size="small"
                  margin="normal"
                  error={errorYear}
                  helperText={`${errorYear ? strings.EDUCATION_LEVEL_STATISTIC_YEAR_HELPER : ''} ${errorYearRequired ? strings.EDUCATION_LEVEL_STATISTIC_YEAR_REQUIRED : ''}`}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </div>
      <div className={styles.charts}>
        <EducationLevelChart date={educationLevelState.date} />
      </div>
    </div>
  );
};

export { EducationLevelStatistic };
