import React from 'react';
import PropTypes from 'prop-types';
import { Header } from '../../common/header';

const LAYOUT_TYPES = {
  HEADER: 'header',
};

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  layoutType: PropTypes.oneOf(Object.values(LAYOUT_TYPES)).isRequired,
};

const Layout = ({ layoutType, children }) => {
  if (layoutType === LAYOUT_TYPES.HEADER) {
    return (
      <>
        <Header />
        {children}
      </>
    );
  }
  return null;
};

Layout.propTypes = IPropTypes;

export { Layout, LAYOUT_TYPES };
