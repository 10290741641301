import React, { useEffect, useState } from 'react';
import {
  Button,
  InputAdornment,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CSVUploadIcon from '@mui/icons-material/GroupAdd';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routeNaming, getRouteFromRouteNaming } from '../../routes/routes';
import { goToPage } from '../../routes';
import { Title } from '../../common/title/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './people.module.scss';
import { PersonListItem } from '../../common/person-list-item';
import { fetchPeople } from '../../store/slices/people-slice';
import { PersonPreviewController } from '../../networking/controllers/person-preview-controller';
import { CustomSnackbar } from '../../common/custom-snackbar';
import { TableWithLoading } from '../../common/table-with-loading/table-with-loading';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';
import { CSVExchangeController } from '../../networking/controllers/csv-exchange-controller';
import { ImportCSVDialog } from '../../common/import-csv-dialog/import-csv-dialog';
import { ConfirmDialog } from '../../common/confirm-dialog';
import { PrimaryTextfield } from '../../common/primary-textfield/primary-textfield';

const People = () => {
  const state = useSelector((selector) => selector.people);

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const dispatch = useDispatch();

  const [csvFileFormData, setCsvFileFormData] = useState(null);
  const [csvFileName, setCsvFileName] = useState('');
  const [openCsvUploadDialog, setOpenCsvUploadDialog] = useState(false);

  const [selectedPersonId, setSelectedPersonId] = useState(null);
  const [openConfirmArchiveDialog, setOpenConfirmArchiveDialog] = useState(false);

  const [search, setSearch] = useState('');

  const fetchPeopleInitial = () => (
    dispatch(
      fetchPeople({
        newPage: 0,
        newItemsPerPage: 10,
        archivedNewPage: 0,
        showArchived: false,
        search,
      }),
    )
  );

  useEffect(() => {
    fetchPeopleInitial();
  }, []);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (message) => {
    setSnackSeverity('success');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const showSnackError = (message) => {
    setSnackSeverity('error');
    setSnackMessage(message);
    setSnackOpen(true);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(
      fetchPeople({
        newPage: state.showArchived ? state.currentPage : newPage,
        newItemsPerPage: state.itemsPerPage,
        archivedNewPage: state.showArchived ? newPage : state.archivedPage,
        showArchived: state.showArchived,
        search,
      }),
    );
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(
      fetchPeople({
        newPage: state.currentPage,
        newItemsPerPage: event.target.value,
        archivedNewPage: state.archivedPage,
        showArchived: state.showArchived,
        search,
      }),
    );
  };

  const PEOPLE_COLUMNS = [
    'Nombre de PSD',
    'Nombre de contacto',
    'Motivo de consulta',
    'Localidad de institución',
    'Nivel educativo',
    'Fecha de inscripción',
    '',
  ];

  const handleShowArchivedClick = () => {
    dispatch(
      fetchPeople({
        newPage: state.currentPage,
        newItemsPerPage: state.itemsPerPage,
        archivedNewPage: state.archivedPage,
        showArchived: !state.showArchived,
        search,
      }),
    );
  };

  const handlePersonClick = (id) => {
    goToPage(routeNaming.PERSON_DETAIL, { id });
  };

  const handleMassiveUploadClick = () => {
    setOpenCsvUploadDialog(true);
  };

  const clearCSVData = () => {
    setCsvFileFormData(null);
    setCsvFileName('');
  };

  const handleUploadCSV = (e) => {
    const csvFile = e.target.files[0];
    if (csvFile.type !== 'text/csv') {
      showSnackError(strings.PEOPLE_UPLOAD_CSV_INVALID_FORMAT);
    } else if (csvFile.size > (50 * 1024 * 1024)) {
      // size is bigger than 50MB
      showSnackError(strings.PEOPLE_UPLOAD_CSV_INVALID_SIZE);
    } else {
      setCsvFileName(csvFile.name);

      // Create an object of formData
      const csvFormData = new FormData();

      // Update the formData object
      csvFormData.append(
        'imported_csv',
        csvFile,
        csvFile.name,
      );

      setCsvFileFormData(csvFormData);
    }
  };

  const handleConfirmUploadCSV = async () => {
    try {
      await CSVExchangeController.uploadCSV(csvFileFormData);

      showSnackSuccess(strings.PEOPLE_UPLOAD_CSV_SUCCESS_SNACK);
      setOpenCsvUploadDialog(false);
      clearCSVData();
      fetchPeopleInitial();
    } catch (exc) {
      showSnackError(`${strings.PEOPLE_UPLOAD_CSV_ERROR_SNACK}: ${exc.message}`);
      setOpenCsvUploadDialog(false);
      clearCSVData();
    }
  };

  const handleCancelUploadCSV = () => {
    setOpenCsvUploadDialog(false);
    clearCSVData();
  };

  const requestArchivePerson = async () => {
    try {
      await PersonPreviewController.archivePerson(selectedPersonId);
      // if success, update the data displayed on the screen.
      dispatch(
        fetchPeople({
          newPage: state.currentPage,
          newItemsPerPage: state.itemsPerPage,
          archivedNewPage: state.archivedPage,
          showArchived: state.showArchived,
          search,
        }),
      );
      showSnackSuccess(
        state.showArchived
          ? strings.PERSON_UNARCHIVED_SUCCESS_SNACK
          : strings.PERSON_ARCHIVED_SUCCESS_SNACK,
      );
      setSelectedPersonId(null);
    } catch (err) {
      showSnackError(strings.PERSON_ARCHIVED_ERROR_SNACK);
      setSelectedPersonId(null);
    }
  };

  const handleConfirmArchive = () => {
    requestArchivePerson();
    setOpenConfirmArchiveDialog(false);
  };

  const handleCancelArchive = () => {
    setOpenConfirmArchiveDialog(false);
    setSelectedPersonId(null);
  };

  const handleArchivePersonClick = (personId) => {
    setSelectedPersonId(personId);
    setOpenConfirmArchiveDialog(true);
  };

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    dispatch(
      fetchPeople({
        newPage: state.currentPage,
        newItemsPerPage: state.itemsPerPage,
        archivedNewPage: state.archivedPage,
        showArchived: state.showArchived,
        search: event.target.value,
      }),
    );
  };

  const history = useHistory();

  const handleExportPerson = () => {
    const downloadsRedirectRoute = getRouteFromRouteNaming(routeNaming.DOWNLOADS);
    history.push(downloadsRedirectRoute);
  };

  return (
    <div className={globalStyles.genericContainer}>
      {state.error && <ErrorPage />}
      {(!state.error) && (
        <>
          <CustomSnackbar
            open={snackOpen}
            onClose={handleSnackClose}
            severity={snackSeverity}
            message={snackMessage}
            autoHideDuration={20000}
          />

          <ImportCSVDialog
            open={openCsvUploadDialog}
            sendEnabled={(csvFileFormData !== null)}
            onUpload={handleUploadCSV}
            onConfirm={handleConfirmUploadCSV}
            onCancel={handleCancelUploadCSV}
            fileName={csvFileName}
          />

          <ConfirmDialog
            open={openConfirmArchiveDialog}
            title={state.showArchived ? strings.PERSON_UNARCHIVE : strings.PERSON_ARCHIVE}
            message={
              state.showArchived
                ? strings.PERSON_UNARCHIVE_DIALOG
                : strings.PERSON_ARCHIVE_DIALOG
            }
            onConfirm={handleConfirmArchive}
            onCancel={handleCancelArchive}
          />

          <Title text={state.showArchived ? strings.PEOPLE_ARCHIVED_TITLE : strings.PEOPLE_TITLE} />
          <div className={styles.buttonsContainer}>
            <PrimaryTextfield
              value={search}
              onChange={onSearchChange}
              className={styles.search}
              label={strings.PEOPLE_SEARCH_LABEL}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              helperText={strings.PEOPLE_SEARCH_HELPER}
            />
            <div className={styles.spacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<PersonIcon />}
              onClick={handleShowArchivedClick}
            >
              {!state.showArchived ? strings.PEOPLE_SHOW_ARCHIVED : strings.PEOPLE_SHOW_ACTIVE}
            </Button>
            <div className={styles.spacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<CSVUploadIcon />}
              onClick={handleMassiveUploadClick}
            >
              {strings.PEOPLE_UPLOAD_BUTTON}
            </Button>
            <div className={styles.spacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleExportPerson}
            >
              {strings.PEOPLE_DOWNLOAD_BUTTON}
            </Button>
          </div>
          <TableWithLoading
            items={state.people.map((person) => (
              <PersonListItem
                key={person.id}
                personPreview={person}
                isArchived={state.showArchived}
                showActions={!state.showArchived}
                onClick={handlePersonClick}
                archiveAction={() => handleArchivePersonClick(person.id)}
              />
            ))}
            isLoading={state.loading}
            columns={PEOPLE_COLUMNS}
            count={state.peopleCount}
            rowsPerPage={state.itemsPerPage}
            page={state.showArchived ? state.archivedPage : state.currentPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}
    </div>
  );
};

export { People };
