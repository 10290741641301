import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from './checkbox-list.module.scss';

// 'values' must be an array of objects like the following: { name: 'Name', id: 0 }
// 'onClick' function must receive 'event' and the checkbox id as parameters
const CheckboxList = (props) => {
  const {
    title,
    values,
    selectedValues,
    onClick,
  } = props;
  return (
    <div className={styles.container}>
      <p>{title}</p>
      <FormGroup>
        {values.map((option) => (
          <FormControlLabel
            key={option.name.replace(/\s+/g, '')}
            control={(
              <Checkbox
                icon={<CircleUnchecked className={styles.icon} />}
                checkedIcon={<CircleCheckedFilled className={styles.icon} />}
                checked={selectedValues.includes(option.id)}
                onClick={(event) => onClick(event, option.id)}
              />
            )}
            label={option.name}
          />
        ))}
      </FormGroup>
    </div>
  );
};

CheckboxList.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.instanceOf(Array).isRequired,
  selectedValues: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func.isRequired,
};

export { CheckboxList };
