import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';

class DownloadsController {
  static getExportPeople(dates) {
    const date = new Date();
    const name = `Personas-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;
    const from = dates.dateFrom;
    const to = dates.dateTo;
    // eslint-disable-next-line global-require
    const FileDownload = require('js-file-download');
    return ApiService.get(`${API_ROUTES.PEOPLE}/export`, {
      from: `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
      to: `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`,
    })
      .then(({ data }) => {
        FileDownload(data, name);
      });
  }

  static getExportEvents(dates) {
    const date = new Date();
    const name = `Eventos-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`;
    const from = dates.dateFrom;
    const to = dates.dateTo;
    // eslint-disable-next-line global-require
    const FileDownload = require('js-file-download');
    return ApiService.get(`${API_ROUTES.EVENTS}/export`, {
      from: `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`,
      to: `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`,
    })
      .then(({ data }) => {
        FileDownload(data, name);
      });
  }
}

export { DownloadsController };
