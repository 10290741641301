import React, { useState, useEffect } from 'react';
import { es } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import styles from './inquiry-amount-statistic.module.scss';
import { LoadingIndicator } from '../../common/loading-indicator';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { strings } from '../../config/strings';
import { months } from '../../config/months';
import { Title } from '../../common/title';
import { BarChart } from '../../common/bar-chart';
import { EnrolledPeopleComponent } from '../../common/enrolled-people/enrolled-people';
import { fetchInquiryAmountStatistic } from '../../store/slices/inquiry-amount-statistic-slice';

const InquiryAmountStatistic = () => {
  const { dataInquiries, loading, error } = useSelector(
    (selector) => selector.inquiryAmountStatistic,
  );

  const [dateTo, setDateTo] = useState(new Date());

  const oneYearAgo = new Date(dateTo);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.toDateString();

  const [dateFrom, setDateFrom] = useState(oneYearAgo);

  const [errorDateTo, setErrorDateTo] = useState(false);
  const [errorDateFrom, setErrorDateFrom] = useState(false);

  const [errorRequiredDate, setErrorRequiredDate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInquiryAmountStatistic({ dateFrom, dateTo }));
  }, []);

  const loadedData = {
    labels: Object.entries(dataInquiries)
      .map(([date, values]) => `${months[(new Date(date)).getUTCMonth()]} ${new Date(date).getUTCFullYear()} (${Math.floor((values.inquiries ? (values.inscriptions / values.inquiries) : 0) * 100)}%)`),
    datasets: [
      {
        label: 'Consultas',
        data: Object.values(dataInquiries).map((val) => val.inquiries),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Inscripciones',
        data: Object.values(dataInquiries).map((val) => val.inscriptions),
        backgroundColor: 'rgb(54, 162, 235)',
        stack: 'Stack 1',
      },
    ],
  };

  const onDateFromChanged = (e) => {
    setDateFrom(e);
    setErrorRequiredDate(!e);
    setErrorDateFrom(!(e && e.getDate()));
    if (e && e.getDate() && !errorDateTo && !errorRequiredDate) {
      dispatch(fetchInquiryAmountStatistic({ dateFrom: e, dateTo }));
    }
  };

  const onDateToChanged = (e) => {
    setDateTo(e);
    setErrorRequiredDate(!e);
    setErrorDateTo(!(e && e.getDate()));
    if (e && e.getDate() && !errorDateFrom && !errorRequiredDate) {
      dispatch(fetchInquiryAmountStatistic({ dateFrom, dateTo: e }));
    }
  };

  const renderDatePickerFrom = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_FROM}
          views={['year', 'month']}
          value={dateFrom}
          maxDate={dateTo}
          onChange={onDateFromChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorRequiredDate || errorDateFrom}
              helperText={`
                ${errorDateFrom ? strings.UTILS_DATE_INVALID : ''}
                ${errorRequiredDate ? strings.UTILS_DATE_REQUIRED_RANGE : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  const renderDatePickerTo = () => (
    <div className={styles.dates}>
      <Stack spacing={2}>
        <DatePicker
          label={strings.UTILS_TO}
          views={['year', 'month']}
          value={dateTo}
          minDate={dateFrom}
          maxDate={new Date()}
          onChange={onDateToChanged}
          /* eslint-disable react/jsx-props-no-spreading */
          renderInput={(props) => (
            <TextField
              {...props}
              required
              className="primaryOutlinedTextField"
              variant="outlined"
              size="small"
              margin="normal"
              error={errorRequiredDate || errorDateTo}
              helperText={`
                ${errorDateTo ? strings.UTILS_DATE_INVALID : ''}
                ${errorRequiredDate ? strings.UTILS_DATE_REQUIRED_RANGE : ''}
              `}
            />
          )}
        />
      </Stack>
    </div>
  );

  return (
    <div className={globalStyles.genericContainer}>
      {/* {error && <ErrorTitle />} */}
      {loading && <LoadingIndicator />}
      {(!error) && (
        <>
          <div className={styles.component}>
            <div>
              <Title text={strings.INQUIRY_AMOUNT_STATISTIC} />
            </div>
            <div>
              <EnrolledPeopleComponent />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              {renderDatePickerFrom()}
              {renderDatePickerTo()}
            </LocalizationProvider>
            <div className={styles.heightSpacer} />
          </div>
          <div className={styles.charts}>
            <BarChart
              options={{
                maintainAspectRatio: false,
              }}
              data={loadedData}
              original
            />
          </div>
          <div className={styles.explainPorcentajeText}>
            {strings.INQUIRY_AMOUNT_STATISTICS_HELPER_TEXT}
          </div>
        </>
      )}
    </div>
  );
};

export { InquiryAmountStatistic };
