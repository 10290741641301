import { Event } from './event';

class EgressEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.age = params.age;
    this.educationType = params.educationType;
    this.fromCourse = params.fromCourse;
  }
}

export { EgressEvent };
