class EnrollmentRequest {
  constructor(params) {
    this.inquiryId = params.inquiryId;
    this.birthDate = params.birthDate;
    this.contactEmail = params.contactEmail;
    this.contactName = params.contactName;
    this.contactPhoneNumber = params.contactPhoneNumber;
    this.course = params.course;
    this.inquiryDescription = params.description;
    this.institutionLocality = params.institutionLocality;
    this.institutionName = params.institutionName;
    this.institutionType = params.institutionType;
    this.inquiryMotive = params.motive;
    this.name = params.name;
    this.relationshipType = params.relationshipType;
    this.suggestions = params.suggestions;
    this.followup = params.followup;
    this.comments = params.comments;
    this.educationType = params.educationType;
  }
}

export { EnrollmentRequest };
