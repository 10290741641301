const institutionTypes = {
  REGULAR_SCHOOL: { name: 'Escuela común', id: 0 },
  SPECIAL_SCHOOL: { name: 'Escuela especial', id: 1 },
  REGULAR_PRIVATE_SCHOOL: { name: 'Colegio común', id: 2 },
  SPECIAL_PRIVATE_SCHOOL: { name: 'Colegio especial', id: 3 },
  HIGH_SCHOOL: { name: 'Liceo', id: 4 },
  UTU: { name: 'UTU', id: 5 },
  UNIVERSITY: { name: 'Universidad', id: 6 },
  KIDS_CLUB: { name: 'Club de niños', id: 7 },
  PUBLIC_KINDER: { name: 'Jardín público', id: 8 },
  PRIVATE_KINDER: { name: 'Jardín privado', id: 9 },
  CAIF: { name: 'CAIF', id: 10 },
};

export const institutionTypesBackendMapping = {
  jardin_publico: 'PUBLIC_KINDER',
  club_de_ninos: 'KIDS_CLUB',
  escuela_comun: 'REGULAR_SCHOOL',
  escuela_especial: 'SPECIAL_SCHOOL',
  colegio_comun: 'REGULAR_PRIVATE_SCHOOL',
  colegio_especial: 'SPECIAL_PRIVATE_SCHOOL',
  liceo: 'HIGH_SCHOOL',
  utu: 'UTU',
  universidad: 'UNIVERSITY',
  jardin_privado: 'PRIVATE_KINDER',
  caif: 'CAIF',
};

export default institutionTypes;
