import { useParams } from 'react-router-dom';
import { React, useState } from 'react';
import { Tooltip } from '@mui/material';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import AddIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import institutionTypes from '../../config/institution-types';
import eventTypes from '../../config/event-types';
import styles from './person-detail-event-card.module.scss';
import { ExpandField } from '../expand-field';
import { strings } from '../../config/strings';
import { formatDate } from '../../helpers/utils';
import { CreateEventModal } from '../create-event-modal/create-event-modal';
import { CreateMeetingModal } from '../create-meeting-modal/create-meeting-modal';
import discriminationTypes from '../../config/discrimination-types';
import helpTypes from '../../config/help-types';

const EventCard = ({ event, onDelete, selected }) => {
  const translateType = (e) => Object.values(eventTypes)
    .find((value) => value.id === e)
    .display;
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMeeting, setOpenMeeting] = useState(false);
  const { id } = useParams();

  const handleCopyEmailsClick = () => {
    const emails = event.meeting.guests.join();
    navigator.clipboard.writeText(emails);
  };

  return (
    <>
      <VerticalTimelineElement className={`${styles.eventCard} ${selected ? styles.selected : ''}`}>
        <div className={styles.nameAndButtons} id={`eventCard_${event.id}`}>
          <div>
            <h2>{translateType(event.eventType)}</h2>
            <p>
              {strings.EVENT_CARD_DATE}
              {`${event.eventType === eventTypes.MEETING_EVENT.id ? formatDate(event.meeting.date?.split(' ')[0]) : formatDate(event.date)}`}
            </p>
            {show && event.eventType === eventTypes.DISCRIMINATION_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_DISCRIMINATION_TYPE}
                  {`${discriminationTypes[event.discriminationType]}`}
                </p>
                <p>
                  {strings.EVENT_CARD_INSTITUTTION_TYPE}
                  {`${institutionTypes[event.institutionType].name}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.EDUCATION_ABANDONMENT_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_EDUCATION_TYPE}
                  {`${event.educationType.name}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MOTIVE}
                  {`${event.motive}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.EGRESS_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_AGE}
                  {`${event.age}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.EXTRAORDINARY_HELP_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_HELP_TYPE}
                  {`${helpTypes[event.helpType]}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.INSTITUTION_CHANGE_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_INSTITUTTION_TYPE}
                  {`${institutionTypes[event.institutionType].name}`}
                </p>
                <p>
                  {strings.EVENT_CARD_NEW_INSTITUTION_NAME}
                  {`${event.institutionName}`}
                </p>
                <p>
                  {strings.EVENT_CARD_INSTITUTION_LOCATION}
                  {`${event.institutionLocality}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MOTIVE}
                  {`${event.motive}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.INSTITUTION_ENROLLMENT_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_INSTITUTTION_TYPE}
                  {`${institutionTypes[event.institutionType].name}`}
                </p>
                <p>
                  {strings.EVENT_CARD_INSTITUTION_NAME}
                  {event.institutionName}
                </p>
                <p>
                  {strings.EVENT_CARD_INSTITUTION_LOCATION}
                  {`${event.institutionLocality}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.COURSE_EVENT.id && (
              <div>
                {event.endDate
                && (
                <p>
                  {strings.EVENT_END_DATE}
                  {`${formatDate(event.endDate)}`}
                </p>
                )}
                <p>
                  {strings.EVENT_CARD_EDUCATION_TYPE}
                  {`${event.educationType.name}`}
                </p>
                <p>
                  {strings.EVENT_CARD_TO_GRADE}
                  {`${event.educationLevel}`}
                </p>
                <p>
                  {strings.EVENT_CARD_IS_REPEATING}
                  {`${event.repeating ? 'Sí' : 'No'}`}
                </p>
                <p>
                  {strings.EVENT_CARD_RESULT}
                  {`${event.result.name}`}
                </p>
              </div>
            )}
            {show && event.eventType === eventTypes.MEETING_EVENT.id && (
              <div>
                <p>
                  {strings.EVENT_CARD_MEETING_TITLE}
                  {`${event.meeting.title}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MEETING_TIME}
                  {`${new Date(event.meeting.date).getHours()}:${new Date(event.meeting.date).getMinutes() < 10 ? '0' : ''}${new Date(event.meeting.date).getMinutes()}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MEETING_DURATION}
                  {`${event.meeting.duration}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MEETING_GUESTS}
                  {`${event.meeting.guests.join(', ')}`}
                </p>
                <p>
                  {strings.EVENT_CARD_MEETING_LOCATION}
                </p>
                <p>
                  {strings.EVENT_CARD_MEETING_DESCRIPTION}
                  {`${event.meeting.description}`}
                </p>
                <ExpandField
                  label={strings.NOTES}
                  value={event.meeting.notes}
                />
              </div>
            )}
            {show && event.eventType !== eventTypes.MEETING_EVENT.id && (
              <>
                <ExpandField
                  label={strings.NOTES}
                  value={event.note}
                />
              </>
            )}
          </div>
          <div>
            <Tooltip title="Ver más">
              <IconButton
                sx={{ height: 'min-content' }}
                className={show ? styles.invisible : styles.visible}
                onClick={() => setShow((prev) => !prev)}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver menos">
              <IconButton
                sx={{ height: 'min-content' }}
                className={show ? styles.visible : styles.invisible}
                onClick={() => setShow((prev) => !prev)}
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
            <div>
              <Tooltip title="Editar">
                <IconButton
                  className={show ? styles.visible : styles.invisible}
                  onClick={() => (event.eventType === eventTypes.MEETING_EVENT.id
                    ? setOpenMeeting(true) : setOpen(true))}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar">
                <IconButton
                  className={show ? styles.visible : styles.invisible}
                  onClick={onDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {show && event.eventType === eventTypes.MEETING_EVENT.id && (
                <Tooltip title="Copiar invitados">
                  <IconButton
                    className={show ? styles.visible : styles.invisible}
                    onClick={handleCopyEmailsClick}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </VerticalTimelineElement>
      <CreateEventModal
        open={open}
        handleClose={() => setOpen(false)}
        event={event}
        personId={+id}
        editing
      />
      <CreateMeetingModal
        open={openMeeting}
        handleClose={() => setOpenMeeting(false)}
        editing
        meeting={event.meeting}
        personId={+id}
      />
    </>
  );
};
EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

EventCard.defaultProps = {
  selected: false,
};

export { EventCard };
