import { React } from 'react';
import { TableRow, TableCell } from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import PropTypes from 'prop-types';
import { parseDate } from '../../helpers/utils';
import { ButtonOverlay } from '../button-overlay';
import { MessagePreview } from '../../models/message-preview';
import styles from './message-list-item.module.scss';
import { ButtonOverlayAction } from '../../models/button-overlay-action';
import { strings } from '../../config/strings';

const MessageListItem = ({
  inquiry,
  isArchived,
  onClick,
  archiveAction,
}) => {
  const overlayIcon = (isArchived ? UnarchiveIcon : ArchiveIcon);
  const overlayLabel = (isArchived ? strings.MESSAGE_UNARCHIVE : strings.MESSAGE_ARCHIVE);

  const overlayActions = [
    new ButtonOverlayAction({
      icon: overlayIcon,
      action: archiveAction,
      label: overlayLabel,
    }),
  ];
  return (
    <TableRow
      className={styles.tableRow}
      onClick={() => onClick(inquiry.id)}
    >
      <TableCell className={styles.tableCell}>{inquiry.contactName}</TableCell>
      <TableCell className={styles.tableCell}>{inquiry.name}</TableCell>
      <TableCell className={styles.tableCell}>{inquiry.motive}</TableCell>
      <TableCell className={styles.tableCell}>{inquiry.institutionLocality}</TableCell>
      <TableCell className={styles.tableCell}>{inquiry.educationType?.name}</TableCell>
      <TableCell className={styles.tableCell}>{parseDate(inquiry.date)}</TableCell>
      <TableCell className={styles.tableCell}>
        <ButtonOverlay actions={overlayActions} show />
      </TableCell>
    </TableRow>
  );
};

MessageListItem.propTypes = {
  inquiry: PropTypes.instanceOf(MessagePreview).isRequired,
  isArchived: PropTypes.bool.isRequired,
  archiveAction: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { MessageListItem };
