import { CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './loading-indicator.module.scss';

const LoadingIndicator = ({ color }) => (
  <div className={styles.container}>
    <CircularProgress
      className={color === 'secondary' ? styles.indicatorSecondary : styles.indicator}
      variant="indeterminate"
      thickness={4}
    />
  </div>
);

LoadingIndicator.propTypes = {
  color: PropTypes.string,
};

LoadingIndicator.defaultProps = {
  color: 'primary',
};

export { LoadingIndicator };
