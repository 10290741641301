class SubscriptionAlert {
  constructor(params) {
    this.author = params.author;
    this.person = params.person;
    this.date = params.date;
    this.id = params.id;
    this.seen = params.seen;
  }
}

export { SubscriptionAlert };
