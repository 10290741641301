import React from 'react';

import './index.scss';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import { ErrorBoundary } from './common/error-boundary';
import { routeConfig } from './route-components';
import { Router } from './routes';

const theme = createTheme({}, esES);

const App = () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <Router routeConfig={routeConfig} />
    </ThemeProvider>
  </ErrorBoundary>
);

export { App };
