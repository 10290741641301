import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button } from '@mui/material';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { Title } from '../../common/title/title';
import styles from './downloads.module.scss';
import { LoadingIndicator } from '../../common/loading-indicator';
import { submitDownloadsPerson, submitDownloadsEvents } from '../../store/slices/downloads-slice';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';

const Downloads = () => {
  const Dates = {
    dateFrom: '',
    dateTo: new Date(),
  };
  const { loading, error } = useSelector((selector) => selector);
  const dispatch = useDispatch();
  const [downloadsState, setDownloadsState] = useState(Dates);
  const [errorDateFrom, setErrorDateFrom] = useState(false);
  const [errorDateTo, setErrorDateTo] = useState(false);
  const [errorRequiredDateTo, setErrorRequiredDateTo] = useState(false);
  const [errorRequiredDateFrom, setErrorRequiredDateFrom] = useState(false);

  const onDateFromChanged = (e) => {
    setDownloadsState({ ...downloadsState, dateFrom: e });
    setErrorDateFrom(false);
    setErrorRequiredDateFrom(false);
  };

  const onDateToChanged = (e) => {
    setDownloadsState({ ...downloadsState, dateTo: e });
    setErrorDateTo(false);
    setErrorRequiredDateTo(false);
  };

  function controlBeforeExport() {
    if (downloadsState.dateTo === null) {
      setErrorRequiredDateTo(true);
      return false;
    } if (downloadsState.dateFrom === null) {
      setErrorRequiredDateFrom(true);
      return false;
    } if (downloadsState.dateFrom > downloadsState.dateTo) {
      setErrorDateFrom(true);
      return false;
    }
    return true;
  }

  const handleExportPerson = (e) => {
    e.preventDefault();
    if (controlBeforeExport()) {
      dispatch(submitDownloadsPerson(downloadsState));
    }
  };

  const handleExportEvents = (e) => {
    e.preventDefault();
    if (controlBeforeExport()) {
      dispatch(submitDownloadsEvents(downloadsState));
    }
  };

  return (
    <div className={globalStyles.genericContainer}>
      {error && <ErrorPage />}
      {loading && <LoadingIndicator />}
      {(!loading && !error) && (
        <>
          <div>
            <Title text={strings.DOWNLOADS_TITLE} />
          </div>
          <div className={styles.dates}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className={styles.dates}>
                <Stack spacing={2}>
                  <DatePicker
                    label={strings.UTILS_FROM}
                    inputFormat="dd/MM/yyyy"
                    value={downloadsState.dateFrom}
                    maxDate={new Date()}
                    onChange={onDateFromChanged}
                    /* eslint-disable react/jsx-props-no-spreading */
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        required
                        size="small"
                        margin="normal"
                        error={errorDateFrom}
                        helperText={`${errorDateFrom ? strings.DOWNLOADS_DATE_FROM_HELPER : ''} ${errorRequiredDateFrom ? strings.DOWNLOADS_DATE_REQUIRED_HELPER : ''}`}
                      />
                    )}
                  />
                </Stack>
              </div>
              <div className={styles.dates}>
                <Stack spacing={2}>
                  <DatePicker
                    label={strings.UTILS_TO}
                    inputFormat="dd/MM/yyyy"
                    value={downloadsState.dateTo}
                    maxDate={new Date()}
                    onChange={onDateToChanged}
                    /* eslint-disable react/jsx-props-no-spreading */
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        required
                        className="primaryOutlinedTextField"
                        variant="outlined"
                        size="small"
                        margin="normal"
                        error={errorDateTo}
                        helperText={errorRequiredDateTo ? strings.DOWNLOADS_DATE_REQUIRED_HELPER : ''}
                      />
                    )}
                  />
                </Stack>
              </div>
            </LocalizationProvider>
          </div>
          <div className={styles.heightSpacer} />
          <div className={styles.buttonsContainer}>
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={handleExportEvents}
            >
              {strings.EVENTS_DOWNLOAD_BUTTON}
            </Button>
            <div className={styles.spacer} />
            <Button
              className={globalStyles.primaryButton}
              variant="contained"
              color="primary"
              startIcon={<CloudDownloadIcon />}
              onClick={handleExportPerson}
            >
              {strings.PEOPLE_DOWNLOAD_BUTTON}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export { Downloads };
