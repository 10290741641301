const courses = {
  BABY_SCHOOL: {},
  KINDER: {
    Level1: 'Nivel 1', Level2: 'Nivel 2', Level3: 'Nivel 3', Level4: 'Nivel 4', Level5: 'Nivel 5',
  },
  PRIMARY_SCHOOL: {
    FirstGrade: 'Primero',
    SecondGrade: 'Segundo',
    ThirdGrade: 'Tercero',
    FourthGrade: 'Cuarto',
    FifthGrade: 'Quinto',
    SixthGrade: 'Sexto',
  },
  HIGH_SCHOOL: {
    FirstGrade: 'Primero',
    SecondGrade: 'Segundo',
    ThirdGrade: 'Tercero',
    FourthGrade: 'Cuarto',
    FifthGrade: 'Quinto',
    SixthGrade: 'Sexto',
  },
  TERCIARY: {},
  OTHER: {},
};

export default courses;
