import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';

class EventController {
  static deleteEvent(id) {
    return ApiService.delete(`${API_ROUTES.EVENTS}/${id}`);
  }
}

export { EventController };
