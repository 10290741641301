import { Event } from './event';

class DiscriminationEvent extends Event {
  constructor(params) {
    super({ ...params });
    this.discriminationType = params.discriminationType;
    this.institutionType = params.institutionType;
  }
}

export { DiscriminationEvent };
