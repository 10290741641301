import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { strings } from '../../../config/strings';

const EgressEventForm = ({ values, changeValues }) => (
  <>
    <TextField
      id="age"
      size="small"
      margin="normal"
      label={strings.EVENT_CARD_AGE}
      fullWidth
      type="number"
      required
      value={values.age}
      onChange={(e) => changeValues({ age: e.target.value })}
    />
  </>
);
EgressEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export { EgressEventForm };
