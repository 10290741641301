/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MeetingController } from '../../networking/controllers/meeting-controller';

const initialState = {
  editing: true,
  loading: false,
  error: '',
  success: false,
  meeting: {},
};

const createMeeting = createAsyncThunk(
  'meetings/createMeeting',
  async (params) => MeetingController.createMeeting(params),
);

const editMeeting = createAsyncThunk(
  'meetings/editMeeting',
  async ({ params, id }) => MeetingController.editMeeting(params, id),
);

const setLoading = (state) => {
  state.editing = false;
  state.loading = true;
  state.error = '';
  state.success = false;
};

const setError = (state, action) => {
  state.editing = true;
  state.loading = false;
  state.error = action.payload;
  state.success = false;
};

const setSuccess = (state, action) => {
  state.editing = false;
  state.loading = false;
  state.error = '';
  state.success = true;
  state.meeting = action.payload;
};

const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createMeeting.pending, setLoading)
      .addCase(createMeeting.fulfilled, setSuccess)
      .addCase(createMeeting.rejected, setError)
      .addCase(editMeeting.pending, setLoading)
      .addCase(editMeeting.fulfilled, setSuccess)
      .addCase(editMeeting.rejected, setError);
  },
});

export { createMeeting, editMeeting };

export default meetingSlice.reducer;
