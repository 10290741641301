import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { PasswordChangeSerializer } from '../serializers/password-change-serializer';

class PasswordChangeController {
  static passwordChange({
    currentpassword, newpassword, repeatnewpassword, user,
  }) {
    const userInfo = PasswordChangeSerializer.serialize(
      { currentpassword, newpassword, repeatnewpassword },
    );
    return ApiService.put(`${API_ROUTES.PASSWORD_CHANGE}/${user.id}`, {
      user: userInfo,
    });
  }
}

export { PasswordChangeController };
