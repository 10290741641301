import { React, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// eslint-disable-next-line react/jsx-props-no-spreading
const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

const CustomSnackbar = ({
  open, onClose, severity, message, autoHideDuration,
}) => (
  <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
    <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
);

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};

CustomSnackbar.defaultProps = {
  autoHideDuration: 3000,
};

export { CustomSnackbar };
