import { React } from 'react';
import PropTypes from 'prop-types';
import styles from './message-detail.module.scss';
import { Message } from '../../models/message';
import { formatDate } from '../../helpers/utils';
import { ValueOrBlankField } from '../value-or-blank-field/value-or-blank-field';

const MessageDetailContact = ({ inquiry }) => (
  <div className={styles.component}>
    <ValueOrBlankField
      label="Nombre de contacto"
      value={inquiry.contactName}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Vínculo del contacto"
      value={inquiry.relationshipType}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Teléfono de contacto"
      value={inquiry.contactPhoneNumber}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Email de contacto"
      value={inquiry.contactEmail}
    />
    <div className={styles.bigSpacer} />
  </div>
);

const MessageDetailPerson = ({ inquiry }) => (
  <div className={styles.component}>
    <ValueOrBlankField
      label="Nombre PSD"
      value={inquiry.name}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Fecha de nacimiento"
      value={formatDate(inquiry.birthDate)}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Motivo de consulta"
      value={inquiry.motive}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Descripción de consulta"
      value={inquiry.description}
    />
    <div className={styles.bigSpacer} />
  </div>
);

const MessageDetailInstitution = ({ inquiry }) => (
  <div className={styles.component}>
    <ValueOrBlankField
      label="Tipo de institución"
      value={inquiry.institutionType.name}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Nombre de institución"
      value={inquiry.institutionName}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Localidad de institución"
      value={inquiry.institutionLocality}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Tipo de educación"
      value={inquiry.educationType.name}
    />
    <div className={styles.spacer} />
    <ValueOrBlankField
      label="Nivel educativo"
      value={inquiry.course}
    />
    <div className={styles.bigSpacer} />
  </div>
);

MessageDetailContact.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
};

MessageDetailPerson.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
};

MessageDetailInstitution.propTypes = {
  inquiry: PropTypes.instanceOf(Message).isRequired,
};

export { MessageDetailContact };
export { MessageDetailPerson };
export { MessageDetailInstitution };
