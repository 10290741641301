class LoginSerializer {
  static serialize(params) {
    return {
      email: params.username,
      password: params.password,
    };
  }
}

export { LoginSerializer };
