import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { PeopleResponseSerializer } from '../serializers/people-response-serializer';

class PersonPreviewController {
  static async getPersonPreviews(order, limit, archived, page, search) {
    return ApiService.get(API_ROUTES.PEOPLE, {
      order, limit, archived, page: page + 1, search,
    }).then((response) => PeopleResponseSerializer.deSerialize(response.data));
  }

  static async archivePerson(personId) {
    await ApiService.put(`${API_ROUTES.PEOPLE}/${personId}/archive`);
  }
}

export { PersonPreviewController };
