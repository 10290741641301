import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { Person, PersonAdd } from '@mui/icons-material';
import { Title } from '../../common/title/title';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './message-detail.module.scss';
import { MessageDetailContact, MessageDetailPerson, MessageDetailInstitution } from '../../common/message-detail/message-detail';
import { MessagePreviewController } from '../../networking/controllers/message-preview-controller';
import { LoadingIndicator } from '../../common/loading-indicator';
import { fetchMessage } from '../../store/slices/message-slice';
import { Message } from '../../models/message';
import { CustomSnackbar } from '../../common/custom-snackbar/custom-snackbar';
import { ErrorPage } from '../../common/error-page';
import { strings } from '../../config/strings';
import { goToPage, routeNaming } from '../../routes';

const PersonDetailButton = ({ enrolledPersonId }) => (
  <Button
    className={globalStyles.primaryButton}
    variant="contained"
    color="primary"
    startIcon={<Person />}
    onClick={() => goToPage(routeNaming.PERSON_DETAIL, { id: enrolledPersonId })}
  >
    {strings.PERSON_DETAIL_SHOW}
  </Button>
);

PersonDetailButton.propTypes = {
  enrolledPersonId: PropTypes.instanceOf(Number).isRequired,
};

const MessageDetail = () => {
  const { message, loading, error } = useSelector((selector) => selector.message);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  useEffect(() => {
    dispatch(fetchMessage(id));
  }, []);

  const goToEnrollment = () => {
    goToPage(routeNaming.ENROLL_PERSON, { id });
  };

  const InscriptionButton = () => (
    <Button
      className={globalStyles.primaryButton}
      variant="contained"
      color="primary"
      startIcon={<PersonAdd />}
      onClick={goToEnrollment}
    >
      {strings.MESSAGE_ENROLL_PERSON}
    </Button>
  );

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const showSnackSuccess = (msg) => {
    setSnackSeverity('success');
    setSnackMessage(msg);
    setSnackOpen(true);
  };

  const showSnackError = (msg) => {
    setSnackSeverity('error');
    setSnackMessage(msg);
    setSnackOpen(true);
  };

  const handleArchiveMessageClick = async () => {
    try {
      await MessagePreviewController.archiveMessage(message.id);
      dispatch(fetchMessage(message.id));

      if (message.archived) {
        showSnackSuccess(strings.MESSAGE_UNARCHIVE_SUCCESS_SNACK);
      } else {
        showSnackSuccess(strings.MESSAGE_ARCHIVE_SUCCESS_SNACK);
      }
    } catch (err) {
      showSnackError(strings.MESSAGE_ARCHIVE_ERROR_SNACK);
    }
  };

  return (
    <div className={globalStyles.genericContainer}>
      <CustomSnackbar
        open={snackOpen}
        onClose={handleSnackClose}
        severity={snackSeverity}
        message={snackMessage}
      />

      {error && <ErrorPage />}
      {loading && <LoadingIndicator />}
      {(!loading && !error) && (
        <>
          <div className={styles.titleHeader}>
            <Title text={strings.MESSAGE_DETAIL_TITLE} />
            <div className={styles.containerHeaderButtons}>
              {!message.usedForEnrollment ? <InscriptionButton />
                : <PersonDetailButton enrolledPersonId={message.enrolledPersonId} /> }
              <div className={styles.horizontalSpacer} />
              <Button
                className={globalStyles.primaryButton}
                variant="contained"
                color="primary"
                startIcon={message.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                onClick={() => handleArchiveMessageClick(message.id)}
              >
                {message.archived ? 'Desarchivar' : 'Archivar'}
              </Button>
            </div>
          </div>
          <div className={styles.component}>
            <div className={styles.column}>
              <p className={styles.subtitle}>{strings.MESSAGE_CONTACT_DATA}</p>
              <div className={styles.spacer} />
              <MessageDetailContact inquiry={new Message(message)} />
            </div>
            <div className={styles.column}>
              <p className={styles.subtitle}>{strings.MESSAGE_PERSON_DATA}</p>
              <div className={styles.spacer} />
              <MessageDetailPerson inquiry={new Message(message)} />
            </div>
            <div className={styles.column}>
              <p className={styles.subtitle}>{strings.MESSAGE_INSTITUTION_DATA}</p>
              <div className={styles.spacer} />
              <MessageDetailInstitution inquiry={new Message(message)} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { MessageDetail };
