import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from '../../store/slices/session-slice';
import { history } from '../../routes/routes';
import { LoadingScreen } from '../../pages/loading-screen';

const LoginRequired = ({ children }) => {
  const { loggedIn, loading } = useSelector((state) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUser());
  }, []);

  useEffect(() => {
    if (!loggedIn) history.push('/login');
  }, [loggedIn]);

  return (
    loading
      ? (
        <LoadingScreen />
      )
      : (
        <>
          {children}
        </>
      )
  );
};

const IPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};

LoginRequired.propTypes = IPropTypes;

export { LoginRequired };
