import {
  React,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styles from './expand-field.module.scss';
import { PrimaryTextfield } from '../primary-textfield/primary-textfield';

const ExpandField = ({
  label, value, editing, onChange, multiline, error, helperText,
}) => {
  const [showMoreValue, setShowMoreValue] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const heightRef = useRef(null);
  useEffect(() => {
    const el = heightRef.current;
    if (el.offsetHeight + 3 < el.scrollHeight) {
      setIsOverflowing(true);
    }
  }, []);
  return (
    editing
      ? (
        <PrimaryTextfield
          label={label}
          value={value}
          onChange={onChange}
          multiline={multiline}
          helperText={helperText}
          error={error}
        />
      ) : (
        <>
          <div className={`${styles.value} ${showMoreValue ? styles.less : styles.more}`} ref={heightRef}>
            <p>
              {label}
              {value || '---'}
            </p>
          </div>
          {!showMoreValue && isOverflowing
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            && (<button type="button" className={styles.link} onClick={() => setShowMoreValue(true)}> Ver más </button>
            )}
          {showMoreValue && isOverflowing
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            && (<button type="button" className={styles.link} onClick={() => setShowMoreValue(false)}>Ver menos</button>
            )}
        </>
      )
  );
};

ExpandField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

ExpandField.defaultProps = {
  value: '',
  editing: false,
  onChange: () => { },
  multiline: false,
  error: false,
  helperText: '',
};

export { ExpandField };
