import motiveTypes from '../../config/motive-types';

class MotiveDistributionSerializer {
  static deSerialize(data) {
    return {
      teacherSupport: { name: motiveTypes.SUPPORT_REQUEST, value: data.teacher_support },
      discrimination: { name: motiveTypes.DISCRIMINATION_SITUATION, value: data.discrimination },
      educationTeam: { name: motiveTypes.INTERVIEW_REQUEST, value: data.education_team },
      educationalProcess: {
        name: motiveTypes.ACCOMPANIMENT_REQUEST,
        value: data.educational_process,
      },
      other: { name: motiveTypes.OTHER, value: data.others },
    };
  }
}

export { MotiveDistributionSerializer };
