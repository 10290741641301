import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { MessagesResponseSerializer } from '../serializers/messages-response-serializer';

class MessagePreviewController {
  static async getMessagesPreview(order, limit, archived, page) {
    return ApiService.get(API_ROUTES.MESSAGES, {
      order, limit, archived, page: page + 1,
    })
      .then((response) => MessagesResponseSerializer.deSerialize(response.data));
  }

  static async archiveMessage(messageId) {
    await ApiService.put(`${API_ROUTES.MESSAGES}/${messageId}`);
  }
}

export { MessagePreviewController };
