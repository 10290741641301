import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Save } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingIndicator } from '../loading-indicator';
import { sendEvent, editEvent } from '../../store/slices/send-event-slice';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { EventTypeSelector } from './event-type-selector';
import { DatePickerEvent } from './date-picker-event';
import { ExtraordinaryHelpEventForm } from './EventForms/extraordinary-help-event-form';
import { DiscriminationEventForm } from './EventForms/discrimination-event-form';
import { EducationAbandonmentEventForm } from './EventForms/education-abandonment-event-form';
import { EgressEventForm } from './EventForms/egress-event-form';
import { CourseEventForm } from './EventForms/course-event-form';
import { InstitutionEnrollmentEventForm } from './EventForms/institution-enrollment-event-form';
import { InstitutionChangeEventForm } from './EventForms/institution-change-event-form';
import styles from './create-event-modal.module.scss';
import { strings } from '../../config/strings';
import educationTypes from '../../config/education-types';

const CreateEventModal = ({
  open, handleClose, personId, editing, event,
}) => {
  const [eventType, setEventType] = useState(0);
  const [date, setDate] = useState(new Date());
  const [note, setNote] = useState('');
  const dispatch = useDispatch();
  const { loading, success } = useSelector((selector) => selector.events);
  const [loadingValues, setLoadingValues] = useState(true);

  // Specific form Values from each Event
  const [extraordinaryHelpEventData, setExtraordinaryHelpEventData] = useState({ helpType: '' });
  const [discriminationEventData, setDiscriminationEventData] = useState({ discriminationType: '', institutionType: '' });
  const [educationAbandonmentEventData, setEducationAbandonmentEventData] = useState({ motive: '', educationType: educationTypes.PRIMARY_SCHOOL });
  const [egressEventData, setEgressEventData] = useState({ age: 18 });
  const [courseEventData, setCourseEventData] = useState({
    educationType: educationTypes.PRIMARY_SCHOOL, educationLevel: '', repeating: false, result: 3, endDate: undefined,
  });
  const [institutionEnrollmentEventData, setInstitutionEnrollmentEventData] = useState(
    {
      institutionName: '', institutionNumber: 0, institutionLocality: '', institutionType: '',
    },
  );
  const [institutionChangeEventData, setInstitutionChangeEventData] = useState(
    {
      motive: '', institutionName: '', institutionNumber: 0, institutionLocality: '', institutionType: '',
    },
  );

  React.useEffect(() => {
    if (success) window.location.reload();
  }, [success]);

  const changeExtraordinaryHelpEventData = (key, value) => {
    setExtraordinaryHelpEventData({ ...extraordinaryHelpEventData, [key]: value });
  };
  const changeDiscriminationEventData = (key, value) => {
    setDiscriminationEventData({ ...discriminationEventData, [key]: value });
  };
  const changeEducationAbandonmentEventData = (obj) => {
    setEducationAbandonmentEventData({ ...educationAbandonmentEventData, ...obj });
  };
  const changeEgressEventData = (obj) => {
    setEgressEventData({ ...egressEventData, ...obj });
  };
  const changeCourseEventData = (obj) => {
    setCourseEventData({ ...courseEventData, ...obj });
  };
  const changeInstitutionEnrollmentEventData = (obj) => {
    setInstitutionEnrollmentEventData({ ...institutionEnrollmentEventData, ...obj });
  };

  const changeInstitutionChangeEventData = (obj) => {
    setInstitutionChangeEventData({ ...institutionChangeEventData, ...obj });
  };

  React.useEffect(() => {
    if (editing && open) {
      setDate(new Date(event.date));
      setNote(event.note);
      switch (event.eventType) {
        case 0:
          setEventType(0);
          setExtraordinaryHelpEventData(
            { ...event },
          );
          break;
        case 1:
          setEventType(1);
          setDiscriminationEventData(
            { ...event },
          );
          break;
        case 2:
          setEventType(2);
          setEducationAbandonmentEventData(
            { ...event },
          );
          break;
        case 3:
          setEventType(3);
          setEgressEventData(
            { ...event },
          );
          break;
        case 4:
          setEventType(4);
          setCourseEventData(
            {
              ...event,
              endDate: event.endDate ? new Date(event.endDate) : undefined,
              result: event.result.id,
            },
          );
          break;
        case 5:
          setEventType(5);
          setInstitutionEnrollmentEventData(
            { ...event },
          );
          break;
        case 6:
          setEventType(6);
          setInstitutionChangeEventData(
            { ...event },
          );
          break;
        case 8:
          setEventType(8);
          break;
        default:
          setEventType(0);
          setExtraordinaryHelpEventData({ ...event });
          break;
      }
      setLoadingValues(false);
    }
  }, [editing, open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const actualFormValues = [
      extraordinaryHelpEventData, discriminationEventData, educationAbandonmentEventData,
      egressEventData, courseEventData, institutionEnrollmentEventData,
      institutionChangeEventData, {},
    ][eventType];
    if ((date && date.getDate())) {
      if ((eventType === 4
        && courseEventData.result !== 3)
        && (!(courseEventData.endDate && courseEventData.endDate.getDate())
          || courseEventData.endDate < date)
      ) {
        // Wrong date
      } else if (editing) {
        dispatch(editEvent({
          actualFormValues: {
            ...actualFormValues, note, date, eventType: eventType.toString(), personId,
          },
          eventType,
          id: event.id,
        }));
      } else {
        dispatch(sendEvent({
          actualFormValues: {
            ...actualFormValues, note, date, eventType: eventType.toString(), personId,
          },
          eventType,
        }));
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {loading || (editing && loadingValues) ? <LoadingIndicator />
        : (
          <form onSubmit={handleSubmit} className={styles.paddingForm}>
            <DialogTitle className={styles.title}>
              {editing ? strings.EDIT_EVENT : strings.ADD_EVENT}
            </DialogTitle>
            <DialogContent className={styles.dialogPaddingTop}>
              <Stack direction="column">
                <EventTypeSelector
                  value={eventType}
                  handleChange={(ev) => setEventType(ev.target.value)}
                />
                <DatePickerEvent
                  value={date}
                  handleChange={(e) => setDate(e)}
                  eventType={eventType}
                  label={eventType === 4 ? strings.EVENT_START_DATE : strings.EVENT_DATE}
                  maxDate={eventType === 4 ? courseEventData.endDate : undefined}
                />
                {
                  [(
                    <ExtraordinaryHelpEventForm
                      values={extraordinaryHelpEventData}
                      changeValues={changeExtraordinaryHelpEventData}
                    />
                  ), (
                    <DiscriminationEventForm
                      values={discriminationEventData}
                      changeValues={changeDiscriminationEventData}
                    />
                  ), (
                    <EducationAbandonmentEventForm
                      values={educationAbandonmentEventData}
                      changeValues={changeEducationAbandonmentEventData}
                    />
                  ), (
                    <EgressEventForm
                      values={egressEventData}
                      changeValues={changeEgressEventData}
                    />
                  ), (
                    <CourseEventForm
                      values={courseEventData}
                      changeValues={changeCourseEventData}
                      date={date}
                    />
                  ), (
                    <InstitutionEnrollmentEventForm
                      values={institutionEnrollmentEventData}
                      changeValues={changeInstitutionEnrollmentEventData}
                    />
                  ), (
                    <InstitutionChangeEventForm
                      values={institutionChangeEventData}
                      changeValues={changeInstitutionChangeEventData}
                    />
                  ),
                  (
                    <></>
                  )][eventType]
                }
                <TextField
                  id={strings.NOTES}
                  label={strings.NOTES}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Stack>
            </DialogContent>
            <DialogActions>

              <>
                <Button
                  className={globalStyles.primaryButton}
                  variant="contained"
                  type="submit"
                  startIcon={editing ? <Save /> : <AddIcon />}
                >
                  {editing ? 'Guardar' : 'Agregar Evento'}
                </Button>
                <Button
                  className={globalStyles.secondaryButton}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </>

            </DialogActions>
          </form>
        )}
    </Dialog>
  );
};

CreateEventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  personId: PropTypes.number.isRequired,
  editing: PropTypes.bool,
  event: PropTypes.any,
};

CreateEventModal.defaultProps = {
  editing: false,
  event: undefined,
};

export { CreateEventModal };
