import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Save } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoggedUserController } from '../../networking/controllers/logged-user-controller';
import { LoadingIndicator } from '../loading-indicator';
import { createMeeting, editMeeting } from '../../store/slices/meeting-slice';
import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import { DateTimePickerMeeting } from './date-time-picker-meeting';
import styles from './create-meeting-modal.module.scss';
import { strings } from '../../config/strings';
import { Person } from '../../models/person';

const CreateMeetingModal = ({
  open, handleClose, editing, meeting, person,
}) => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date());
  const [duration, setDuration] = useState('');
  const [emails, setEmails] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [inviteCreator, setInviteCreator] = useState(false);
  const [notes, setNotes] = useState('');
  const [correctFormatEmail, setCorrectFormatEmail] = useState(true);
  const [correctFormatDuration, setCorrectFormatDuration] = useState(true);
  const dispatch = useDispatch();
  const { loading, success } = useSelector((selector) => selector.meeting);

  const isEmail = (string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(string).toLowerCase());
  };

  const isNumber = (string) => {
    const re = /^[0-9]+$/;
    return re.test(String(string).toLowerCase());
  };

  React.useEffect(() => {
    if (success) window.location.reload();
  }, [success]);

  const handleInviteCreator = async () => {
    const user = await LoggedUserController.checkLoggedUser();
    let updatedEmails = '';
    if (emails !== '') updatedEmails = emails.concat(`, ${user.email}`);
    else updatedEmails = user.email;
    setEmails(updatedEmails);
    return updatedEmails;
  };

  React.useEffect(() => {
    if (editing && open) {
      setTitle(meeting.title);
      setDate(new Date(meeting.date));
      setDuration(meeting.duration);
      setEmails((meeting.invited_users ?? meeting.guests)?.join(', '));
      setDescription(meeting.description);
      setLocation(meeting.location);
      setNotes(meeting.notes);
    }
  }, [editing, open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedEmails;
    let listEmails = [];
    if (inviteCreator) {
      updatedEmails = await handleInviteCreator();
      listEmails = updatedEmails?.split(',');
    } else if (emails !== '') {
      listEmails = emails?.split(',') ?? [];
    }
    let correctEmail = true;
    listEmails.forEach((email) => {
      correctEmail = correctEmail && isEmail(email.trim());
    });
    if (!correctEmail) setCorrectFormatEmail(false);
    let correctDuration = true;
    if (duration !== '') {
      correctDuration = isNumber(duration);
      setCorrectFormatDuration(correctDuration);
    }
    if (correctEmail && correctDuration) {
      const params = {
        person,
        title,
        date,
        duration: Number(duration),
        guests: listEmails.map((email) => email.trim()),
        description,
        location,
        notes,
      };
      if (editing) {
        dispatch(editMeeting({
          params,
          id: meeting.id,
        }));
      } else {
        dispatch(createMeeting(params));
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      {loading ? <LoadingIndicator />
        : (
          <form onSubmit={handleSubmit} className={styles.paddingForm}>
            <DialogTitle className={styles.title}>
              {editing ? strings.EDIT_MEETING : strings.MEETING_ARRANGE}
            </DialogTitle>
            <DialogContent className={styles.dialogPaddingTop}>
              <Stack direction="column">
                <TextField
                  id={strings.MEETING_TITLE}
                  size="small"
                  margin="normal"
                  label={strings.MEETING_TITLE}
                  fullWidth
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <DateTimePickerMeeting value={date} handleChange={(e) => setDate(e)} />
                <TextField
                  id={strings.MEETING_DURATION}
                  size="small"
                  margin="normal"
                  label={strings.MEETING_DURATION}
                  fullWidth
                  type="number"
                  value={duration}
                  onChange={(e) => {
                    setCorrectFormatDuration(true);
                    setDuration(e.target.value);
                  }}
                  error={!correctFormatDuration}
                  helperText={
                    !correctFormatDuration
                      ? strings.MEETING_WRONG_FORMAT_DURATION
                      : ''
                  }
                />
                <TextField
                  id={strings.MEETING_EMAILS}
                  size="small"
                  margin="normal"
                  label={strings.MEETING_EMAILS}
                  fullWidth
                  value={emails}
                  onChange={(e) => {
                    setCorrectFormatEmail(true);
                    setEmails(e.target.value);
                  }}
                  error={!correctFormatEmail}
                  helperText={
                    !correctFormatEmail
                      ? strings.MEETING_WRONG_FORMAT_EMAIL
                      : strings.MEETING_FORMAT_EMAIL
                  }
                />
                <TextField
                  id={strings.MEETING_DESCRIPTION}
                  label={strings.MEETING_DESCRIPTION}
                  margin="normal"
                  multiline
                  rows={3}
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                  id={strings.MEETING_LOCATION}
                  size="small"
                  margin="normal"
                  label={strings.MEETING_LOCATION}
                  fullWidth
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
                <TextField
                  id={strings.NOTES}
                  label={strings.NOTES}
                  margin="normal"
                  multiline
                  rows={4}
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={inviteCreator}
                      onChange={() => setInviteCreator(!inviteCreator)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  )}
                  label={strings.MEETING_INVITE_CREATOR}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <>
                <Button
                  className={globalStyles.primaryButton}
                  variant="contained"
                  type="submit"
                  startIcon={editing ? <Save /> : <AddIcon />}
                >
                  {!editing ? 'Confirmar reunión' : 'Guardar'}
                </Button>
                <Button
                  className={globalStyles.secondaryButton}
                  variant="contained"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              </>
            </DialogActions>
          </form>
        )}
    </Dialog>
  );
};

CreateMeetingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  meeting: PropTypes.object,
  person: PropTypes.instanceOf(Person),
};

CreateMeetingModal.defaultProps = {
  editing: false,
  person: undefined,
  meeting: undefined,
};

export { CreateMeetingModal };
