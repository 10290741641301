import React from 'react';
import PropTypes from 'prop-types';
import { GenericFormSelect } from './common/generic-form-select';
import discriminationTypes from '../../../config/discrimination-types';
import institutionTypes from '../../../config/institution-types';
import { strings } from '../../../config/strings';

const DiscriminationEventForm = ({ values, changeValues }) => (
  <>
    <GenericFormSelect
      value={values.discriminationType}
      id="discriminationTypeSelect"
      label={strings.EVENT_CARD_DISCRIMINATION_TYPE}
      required
      onChange={(e) => changeValues('discriminationType', e.target.value)}
      options={Object.entries(discriminationTypes).map(
        ([key, value]) => ({ value: key, label: value }),
      )}
    />
    <GenericFormSelect
      value={values.institutionType}
      id="institutionTypeSelect"
      label={strings.FORM_INSTITUTION_TYPE_LABEL}
      required
      onChange={(e) => changeValues('institutionType', e.target.value)}
      options={
        Object.entries(institutionTypes).map(
          ([key, value]) => ({ value: key, label: value.name }),
        )
      }
    />
  </>
);

DiscriminationEventForm.propTypes = {
  values: PropTypes.object.isRequired,
  changeValues: PropTypes.func.isRequired,
};

export { DiscriminationEventForm };
