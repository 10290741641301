import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import RepeatIcon from '@mui/icons-material/Repeat';
import React, { useEffect, useState } from 'react';
import { strings } from '../../config/strings';
import { CustomSnackbar } from '../../common/custom-snackbar/custom-snackbar';

import globalStyles from '../../assets/stylesheets/global-styles.module.scss';
import styles from './password-change-screen.module.scss';
import { requestPasswordChange, clear } from '../../store/slices/password-slice';
import { LoadingIndicator } from '../../common/loading-indicator';

const PasswordChangeScreen = () => {
  const [currentpassword, setCurrentPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [repeatnewpassword, setRepeatNewPassword] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
  const [formNotSentYet, setFormNotSentYet] = useState(true);
  const { loading, error, success } = useSelector((selector) => selector.password);
  const dispatch = useDispatch();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState('success');
  const [snackMessage, setSnackMessage] = useState('');

  const showSnackSuccess = (prompt) => {
    setSnackSeverity('success');
    setSnackMessage(prompt);
    setSnackOpen(true);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  useEffect(() => {
    if (success) {
      showSnackSuccess(strings.PASSWORD_CHANGE_SUCCESS);
      dispatch(clear());
    }
  }, [success]);

  const handleOnChangeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    if (error !== strings.PASSWORD_NO_ERROR) {
      setFormNotSentYet(true);
    }
  };

  const handleOnChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
    if (errorNewPassword) {
      setFormNotSentYet(true);
    }
  };

  const handleOnChangeRepeatPassword = (e) => {
    setRepeatNewPassword(e.target.value);
    if (errorRepeatPassword) {
      setFormNotSentYet(true);
    }
  };

  const validateForm = () => (
    currentpassword.length > 0 && newpassword.length > 0 && repeatnewpassword.length > 0
  );

  const validateNewPasswordField = () => (
    newpassword.length > 7
  );

  const validateRepeatPasswordField = () => (
    newpassword === repeatnewpassword
  );

  const handleOnClickChangePassword = (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (validateNewPasswordField()) {
        setErrorNewPassword(false);
        if (validateRepeatPasswordField()) {
          setErrorRepeatPassword(false);
          setFormNotSentYet(false);
          dispatch(requestPasswordChange({ currentpassword, newpassword, repeatnewpassword }));
        } else {
          setErrorRepeatPassword(true);
          dispatch(clear());
        }
      } else {
        setErrorNewPassword(true);
        dispatch(clear());
      }
    }
  };

  // formNotSentYet variable is added in order to avoid multiple clicks
  // on the submit button after one request is sent.
  const isButtonDisabled = () => (
    formNotSentYet && !validateForm()
  );

  return (
    <StylesProvider injectFirst>
      <div className={globalStyles.genericContainer}>
        <CustomSnackbar
          open={snackOpen}
          onClose={handleSnackClose}
          severity={snackSeverity}
          message={snackMessage}
        />
        <h1>Cambiar contraseña</h1>
        <form className={styles.passwordChangeScreenForm}>

          <div className={styles.passwordChangeScreenInputs}>
            {(loading) && <LoadingIndicator color="secondary" />}
            {!(loading || success) && (
              <>
                <span className={styles.passwordChangeSceenInputsSpaceBetween} />

                <TextField
                  size="small"
                  type="password"
                  label="Contraseña actual"
                  variant="outlined"
                  value={currentpassword}
                  className="outlinedTextField"
                  onChange={(e) => handleOnChangeCurrentPassword(e)}
                  error={
                    error !== strings.PASSWORD_NO_ERROR && error.message !== strings.NETWORK_ERROR
                  }
                  helperText={
                    (error !== strings.PASSWORD_NO_ERROR && error.message !== strings.NETWORK_ERROR)
                      ? strings.PASSWORD_INCORRECT
                      : ''
                  }
                />

                <span className={styles.passwordChangeSceenInputsSpaceBetween} />

                <TextField
                  size="small"
                  type="password"
                  label="Nueva contraseña"
                  variant="outlined"
                  value={newpassword}
                  onChange={(e) => handleOnChangeNewPassword(e)}
                  className="outlinedTextField"
                  error={errorNewPassword}
                  helperText={
                    errorNewPassword
                      ? strings.PASSWORD_WRONG_LENGTH
                      : ''
                  }
                />

                <span className={styles.passwordChangeSceenInputsSpaceBetween} />

                <TextField
                  size="small"
                  type="password"
                  label="Repetir nueva contraseña"
                  variant="outlined"
                  value={repeatnewpassword}
                  onChange={(e) => handleOnChangeRepeatPassword(e)}
                  className="outlinedTextField"
                  error={errorRepeatPassword}
                  helperText={
                    errorRepeatPassword
                      ? strings.PASSWORD_NOT_MATCH
                      : ''
                  }
                />

                <span className={styles.passwordChangeSceenInputsSpaceBetween} />
              </>
            )}

          </div>
          <div className={`${styles.buttonsContainer} ${styles.passwordChangeButton}`}>
            <Button
              className={globalStyles.primaryButton}
              startIcon={<RepeatIcon />}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isButtonDisabled()}
              onClick={(e) => handleOnClickChangePassword(e)}
              value="Cambiar contraseña"
            >
              Cambiar contraseña
            </Button>
          </div>

        </form>
      </div>
    </StylesProvider>
  );
};

export { PasswordChangeScreen };
