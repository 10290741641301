import { Meeting } from '../../models/meeting';
import { MeetingSerializer } from './meeting-serializer';

class MeetingsResponseSerializer {
  static deSerialize(data) {
    return {
      meetings:
        data.meetings.map((it) => new Meeting(MeetingSerializer.deSerialize(it))),
      count: data.count,
      page: data.page - 1,
    };
  }
}

export { MeetingsResponseSerializer };
