const strings = {
  ADD_EVENT: 'Agregar evento',
  ADDU_LOGO_DESCRIPTION: 'Logo de la ADdU',
  AN_ERROR_OCURRED: 'Ocurrió un error',
  ARCHIVE_MESSAGE_ERROR: 'No se pudo archivar el mensaje, por favor intentelo nuevamente más tarde.',
  ARCHIVE_MESSAGE_SUCCESS: 'Mensaje archivado satisfactoriamente.',
  ARCHIVE_MESSAGE: 'Archivar mensaje',
  ARCHIVE_PERSON: 'Archivar persona',
  DOWNLOADS_DATE_FROM_HELPER: 'Ingrese una fecha inicial correcta',
  DOWNLOADS_DATE_REQUIRED_HELPER: 'Fecha requerida!',
  DOWNLOADS_TITLE: 'Descargas',
  EDIT_EVENT: 'Editar evento',
  EDIT_MEETING: 'Editar reunión',
  EDIT: 'Editar',
  EDUCATION_LEVEL_STATISTIC_YEAR_HELPER: 'Debe ser menor al año actual',
  EDUCATION_LEVEL_STATISTIC_YEAR_REQUIRED: 'Debe ingresar un año',
  EDUCATION_LEVEL_STATISTIC: 'Nivel educativo',
  EDUCATION_RESULTS_DISTRIBUTION_TITLE: 'Distribución de resultados educativos',
  ENROLL_PERSON_COMMENTS: 'Comentarios',
  ENROLL_PERSON_ERROR: 'Ocurrió un error al generar la inscripción',
  ENROLL_PERSON_FOLLOWUP: 'Seguimiento',
  ENROLL_PERSON_SUCCESS: 'La inscripción se generó con éxito',
  ENROLL_PERSON_SUGGESTIONS: 'Sugerencias',
  ENROLL_PERSON_TITLE: 'Generar inscripción',
  ERROR_500_BUTTON: 'Ir a página de inicio',
  ERROR_500_MESSAGE_1: 'Ha ocurrido un error en el servidor (Error 500)',
  ERROR_500_MESSAGE_2: 'Prueba acceder nuevamente a la página de inicio, o contacta a un administrador.',
  ERROR_500_TITLE: '¡Ups! Se ha producido un error.',
  EVENT_CARD_AGE: 'Edad: ',
  EVENT_CARD_COURSE: 'Nivel educativo: ',
  EVENT_CARD_DATE: 'Fecha: ',
  EVENT_CARD_DISCRIMINATION_TYPE: 'Tipo de situación de discriminación: ',
  EVENT_CARD_EDUCATION_TYPE: 'Tipo de educación: ',
  EVENT_CARD_FORM_COURSE: 'Nivel educativo del que egresa: ',
  EVENT_CARD_GRADE: 'Nivel o grado: ',
  EVENT_CARD_HELP_TYPE: 'Tipo de ayuda: ',
  EVENT_CARD_INSTITUTION_LOCATION: 'Localidad de la institución: ',
  EVENT_CARD_INSTITUTION_NAME: 'Institución: ',
  EVENT_CARD_INSTITUTTION_TYPE: 'Tipo de institución: ',
  EVENT_CARD_IS_REPEATING: 'Repite (o no): ',
  EVENT_CARD_MEETING_DESCRIPTION: 'Descripción: ',
  EVENT_CARD_MEETING_DURATION: 'Duración: ',
  EVENT_CARD_MEETING_GUESTS: 'Invitados: ',
  EVENT_CARD_MEETING_LOCATION: 'Ubicación: ',
  EVENT_CARD_MEETING_TIME: 'Hora de inicio: ',
  EVENT_CARD_MEETING_TITLE: 'Título: ',
  EVENT_CARD_MOTIVE: 'Motivo: ',
  EVENT_CARD_NEW_INSTITUTION_NAME: 'Nueva institución: ',
  EVENT_CARD_RESULT: 'Resultado: ',
  EVENT_CARD_TO_COURSE: 'Nivel en el que ingresa: ',
  EVENT_CARD_TO_GRADE: 'Nivel o grado al que pasa: ',
  EVENT_DATE: 'Fecha del evento',
  EVENT_END_DATE: 'Fecha de fin del curso: ',
  EVENT_REPEATING_COURSE: 'Esta repitiendo curso',
  EVENT_START_DATE: 'Fecha de inicio del curso: ',
  EVENT_TYPE: 'Tipo de Evento',
  EVENTS_DOWNLOAD_BUTTON: 'Descargar datos de eventos',
  FORM_CONTACT_EMAIL_HELPER: 'Debe completar teléfono y/o email.',
  FORM_CONTACT_EMAIL_LABEL: 'Email',
  FORM_CONTACT_NAME_LABEL: 'Nombre',
  FORM_CONTACT_PHONE_LABEL: 'Teléfono',
  FORM_CONTACT_RELATIONSHIP_LABEL: 'Vínculo',
  FORM_CONTACT_RELATIONSHIP_OTHER_LABEL: 'Especifique vínculo',
  FORM_COURSE_LABEL: 'Curso',
  FORM_COURSE_OTHER_LABEL: 'Especifique curso',
  FORM_COURSE: 'Nivel educativo',
  FORM_DESCRIPTION_LABEL: 'Descripción',
  FORM_EDUCATION_TYPE: 'Tipo de educación',
  FORM_FILL_DATA: 'Completa los datos',
  FORM_INSTITUTION_IDENTIFIER_LABEL: 'Nombre o número de institución',
  FORM_INSTITUTION_LOCALITY_LABEL: 'Localidad de la institución',
  FORM_INSTITUTION_TYPE_LABEL: 'Tipo de institución',
  FORM_LINK_MESSAGE: 'https://www.downuruguay.org/',
  FORM_MOTIVE_LABEL: 'Motivo *',
  FORM_MOTIVE_OTHER_LABEL: 'Especifique motivo',
  FORM_PERSON_BIRTHDAY_ERROR: 'La fecha debe ser anterior a hoy.',
  FORM_PERSON_BIRTHDAY_LABEL: 'Fecha de nacimiento',
  FORM_PERSON_NAME_LABEL: 'Nombre',
  FORM_SEND_MESSAGE: 'Envia tu consulta',
  FORM_SUCCESS_MESSAGE: 'Su mensaje ha sido enviado con éxito.',
  HEADER_LINK_DOWNLOAD: 'Descargas',
  HEADER_LINK_LOGOUT: 'Cerrar sesión',
  HEADER_LINK_MEETINGS: 'Reuniones',
  HEADER_LINK_MESSAGES: 'Mensajes',
  HEADER_LINK_PASSWORD: 'Cambiar contraseña',
  HEADER_LINK_PEOPLE: 'Personas',
  HEADER_LINK_STATISTICS: 'Estadísticas',
  IMPORTED: 'Importado',
  INFORMED_EXC_STATISTICS_CHECKLIST_ERROR_SNACK: 'No es posible seleccionar más de cuatro instituciones.',
  INFORMED_EXC_STATISTICS_CHECKLIST_TITLE: 'Tipo de institución educativa',
  INFORMED_EXC_STATISTICS_GET_DATA_BUTTON_LABEL: 'Obtener Datos',
  INFORMED_EXC_STATISTICS_RETRIEVE_ERROR_SNACK: 'No pudo obtenerse la información debido a un error de conexión.',
  INFORMED_EXC_STATISTICS_TITLE: 'Cantidad de situaciones de exclusión informadas',
  INQUIRY_AMOUNT_STATISTIC: 'Cantidad de consultas',
  INQUIRY_AMOUNT_STATISTICS_HELPER_TEXT: '* (el porcentaje mostrado por mes, indica la proporción de consultas que resultaron en inscripciones)',
  INQUIRY_AMOUNT_STATISTICS: 'Cantidad de consultas',
  LOGIN_CREDENTIALS_ERROR: 'No existe en el sistema un usuario con ese email y contraseña.',
  LOGIN_PASSWORD_LABEL: 'Contraseña',
  LOGIN_SUBMIT: 'Iniciar',
  LOGIN_USERNAME_LABEL: 'Usuario',
  MEETING_ARRANGE: 'Coordinar reunión',
  MEETING_DATE: 'Fecha de la reunión',
  MEETING_DESCRIPTION: 'Descripción',
  MEETING_DURATION: 'Duración (minutos)',
  MEETING_EMAILS: 'Mails de los invitados',
  MEETING_FORMAT_EMAIL: 'Emails separados por coma',
  MEETING_INVITE_CREATOR: 'Invitar al creador',
  MEETING_LOCATION: 'Ubicación de la reunión',
  MEETING_TITLE: 'Titulo',
  MEETING_WRONG_FORMAT_DURATION: 'La duración debe ser un número positivo',
  MEETING_WRONG_FORMAT_EMAIL: 'Contiene un formato erroneo',
  MEETINGS_CONFIRM_DELETE: '¿Desea eliminar la reunión?',
  MEETINGS_DELETE: 'Eliminar reunión',
  MEETINGS_FUTURE: 'Reuniones futuras',
  MEETINGS_PAST: 'Reuniones pasadas',
  MEETINGS_SHOW_FUTURE: 'Mostrar futuras',
  MEETINGS_SHOW_PAST: 'Mostrar pasadas',
  MESSAGE_ARCHIVE_DIALOG: '¿Desea archivar el mensaje?',
  MESSAGE_ARCHIVE_ERROR_SNACK: 'No se pudo archivar/desarchivar el mensaje, por favor intentelo nuevamente más tarde',
  MESSAGE_ARCHIVE_SUCCESS_SNACK: 'Mensaje archivado satisfactoriamente',
  MESSAGE_ARCHIVE: 'Archivar mensaje',
  MESSAGE_CONTACT_DATA: 'Datos de contacto',
  MESSAGE_CONTACT_EMAIL: 'Email de contacto',
  MESSAGE_CONTACT_NAME: 'Nombre de contacto',
  MESSAGE_CONTACT_PHONE: 'Teléfono de contacto',
  MESSAGE_COURSE_OTHER: 'Especificación de curso',
  MESSAGE_COURSE: 'Nivel educativo',
  MESSAGE_DESCRIPTION: 'Descripción de consulta',
  MESSAGE_DETAIL_TITLE: 'Detalle de mensaje',
  MESSAGE_ENROLL_PERSON: 'Generar inscripción',
  MESSAGE_INSTITUTION_DATA: 'Datos de institucion educativa',
  MESSAGE_INSTITUTION_LOCALITY: 'Localidad de institución',
  MESSAGE_INSTITUTION_NAME: 'Nombre de institución',
  MESSAGE_INSTITUTION_TYPE: 'Tipo de institución',
  MESSAGE_MOTIVE_OTHER: 'Descripción de motivo',
  MESSAGE_MOTIVE: 'Motivo de consulta',
  MESSAGE_PERSON_DATA: 'Datos de la persona',
  MESSAGE_PERSON_NAME: 'Nombre PSD',
  MESSAGE_RELATIONSHIP_TYPE_OTHER: 'Descripción del vínculo',
  MESSAGE_RELATIONSHIP_TYPE: 'Vínculo del contacto',
  MESSAGE_UNARCHIVE_DIALOG: '¿Desea desarchivar el mensaje?',
  MESSAGE_UNARCHIVE_SUCCESS_SNACK: 'Mensaje desarchivado satisfactoriamente',
  MESSAGE_UNARCHIVE: 'Desarchivar mensaje',
  MESSAGES_ARCHIVED_TITLE: 'Mensajes Archivados',
  MESSAGES_SHOW_ARCHIVED: 'Mostrar mensajes archivados',
  MESSAGES_SHOW_OPEN: 'Mostrar mensajes abiertos',
  MESSAGES_SORT_LABEL: 'Ordenar por',
  MESSAGES_TITLE: 'Mensajes Abiertos',
  MOTIVE_DISTRIBUTION_TITLE: 'Distribución de motivos de consulta',
  NETWORK_ERROR: 'Network Error',
  NOT_FOUND_BUTTON: 'Ir a página de inicio',
  NOT_FOUND_MESSAGE_1: 'La página a la que intentaste acceder no existe en el servidor (Error 404).',
  NOT_FOUND_MESSAGE_2: 'Prueba acceder nuevamente a la página de inicio.',
  NOT_FOUND_TITLE: '¡Ups! Página no existente.',
  NOTES: 'Notas: ',
  PASSWORD_CHANGE_SUCCESS: 'La contraseña fue cambiada con éxito.',
  PASSWORD_CHANGED_SUCCESSFULLY: 'Password updated successfully',
  PASSWORD_INCORRECT: 'La contraseña ingresada no es correcta.',
  PASSWORD_NO_ERROR: '',
  PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden.',
  PASSWORD_WRONG_LENGTH: 'Largo de contraseña debe ser mayor a 7.',
  PEOPLE_ARCHIVED_ERROR_SNACK: 'No se pudo archivar/desarchivar la persona seleccionada, por favor intentelo nuevamente más tarde',
  PEOPLE_ARCHIVED_SUCCESS_SNACK: 'Persona archivada satisfactoriamente',
  PEOPLE_ARCHIVED_TITLE: 'Personas archivadas',
  PEOPLE_DOWNLOAD_BUTTON: 'Descargar datos de personas',
  PEOPLE_SEARCH_HELPER: 'Nombre de PSD/Email de contacto/Nombre de contacto',
  PEOPLE_SEARCH_LABEL: 'Búsqueda',
  PEOPLE_SHOW_ACTIVE: 'Mostrar personas activas',
  PEOPLE_SHOW_ARCHIVED: 'Mostrar personas archivadas',
  PEOPLE_TITLE: 'Personas activas',
  PEOPLE_UNARCHIVED_SUCCESS_SNACK: 'Persona desarchivada satisfactoriamente',
  PEOPLE_UPLOAD_BUTTON: 'Ingreso masivo de inscripciones',
  PEOPLE_UPLOAD_CSV_ERROR_SNACK: 'Error al cargar archivo CSV',
  PEOPLE_UPLOAD_CSV_FILE_BUTTON: 'Subir archivo CSV',
  PEOPLE_UPLOAD_CSV_INVALID_FORMAT: 'El archivo seleccionado no es .csv',
  PEOPLE_UPLOAD_CSV_INVALID_SIZE: 'El archivo debe ser menor a 50MB',
  PEOPLE_UPLOAD_CSV_SELECT_A_FILE: 'Seleccione un archivo',
  PEOPLE_UPLOAD_CSV_SELECTED_FILE: 'Archivo seleccionado: ',
  PEOPLE_UPLOAD_CSV_SUCCESS_SNACK: 'Archivo CSV cargado con éxito',
  PEOPLE_UPLOAD_CSV_SUCCESS_SNACKBAR: 'Archivo CSV cargado con éxito',
  PERSON_ARCHIVE_DIALOG: '¿Desea archivar la persona seleccionada? Una vez archivada esta persona ya no será contada en estadísticas.',
  PERSON_ARCHIVE: 'Archivar persona',
  PERSON_ARCHIVED_ERROR_SNACK: 'No se pudo archivar/desarchivar la persona seleccionada, por favor intentelo nuevamente más tarde',
  PERSON_ARCHIVED_SUCCESS_SNACK: 'Persona archivada satisfactoriamente',
  PERSON_CONTACT_NAME_REQUIRED: 'El nombre de contacto es requerido',
  PERSON_DETAIL_CANCEL: 'Cancelar',
  PERSON_DETAIL_COMMENTS: 'Comentarios',
  PERSON_DETAIL_CONTACT_EMAIL: 'Email: ',
  PERSON_DETAIL_CONTACT_NAME: 'Nombre: ',
  PERSON_DETAIL_CONTACT_PHONE: 'Teléfono: ',
  PERSON_DETAIL_CONTACT_RELATIONSHIP: 'Vínculo: ',
  PERSON_DETAIL_CONTACT: 'Contacto',
  PERSON_DETAIL_COURSE: 'Nivel educativo: ',
  PERSON_DETAIL_DESCRIPTION: 'Descripción de consulta',
  PERSON_DETAIL_EDIT: 'Editar',
  PERSON_DETAIL_EDUCATION_TYPE: 'Tipo de educación: ',
  PERSON_DETAIL_EVENT_DELETE_DIALOG_MESSAGE: '¿Desea eliminar el evento?',
  PERSON_DETAIL_EVENT_DELETE_DIALOG_TITLE: 'Eliminar evento',
  PERSON_DETAIL_EVENT_DELETE_ERROR_SNACK: 'No se pudo eliminar el evento, por favor intentelo nuevamente más tarde',
  PERSON_DETAIL_EVENT_DELETE_SUCCESS_SNACK: 'Evento eliminado satisfactoriamente',
  PERSON_DETAIL_FOLLOWUP: 'Seguimiento',
  PERSON_DETAIL_INSTITUTION_LOCALITY: 'Localidad: ',
  PERSON_DETAIL_INSTITUTION_NAME: 'Nombre o número: ',
  PERSON_DETAIL_INSTITUTION_TYPE: 'Tipo: ',
  PERSON_DETAIL_INSTITUTION: 'Institución',
  PERSON_DETAIL_MOTIVE: 'Motivo de consulta',
  PERSON_DETAIL_SAVE: 'Guardar',
  PERSON_DETAIL_SHOW: 'Ir a Persona',
  PERSON_DETAIL_SUGGESTIONS: 'Sugerencias',
  PERSON_DETAIL_TITLE: 'Detalle de persona',
  PERSON_DETAIL_UNSUSCRIBE: 'Desuscribirse',
  PERSON_SUBSCRIBE_DIALOG_TITLE: 'Suscribir persona a trayectoria',
  PERSON_SUBSCRIBE_ERROR_FETCHING_EMAILS_SNACK: 'No pudo obtenerse la lista de mails para suscribir a la persona',
  PERSON_SUBSCRIBE_ERROR_NO_USERS_LEFT_SNACK: 'No existen usuarios no suscritos a la persona',
  PERSON_SUBSCRIBE: 'Suscribir',
  PERSON_SUBSCRIBED_ERROR_SNACK: 'No fue posible suscribir/desuscribir a la trayectoria de la persona seleccionada, por favor intentelo nuevamente más tarde',
  PERSON_SUBSCRIBED_SUCCESS_SNACK: 'Suscrito a trayectoria satisfactoriamente',
  PERSON_UNARCHIVE_DIALOG: '¿Desea desarchivar la persona seleccionada?',
  PERSON_UNARCHIVE: 'Desarchivar persona',
  PERSON_UNARCHIVED_SUCCESS_SNACK: 'Persona desarchivada satisfactoriamente',
  PERSON_UNSUBSCRIBED_SUCCESS_SNACK: 'Desuscrito a trayectoria satisfactoriamente',
  PERSON_UPDATED_SUCCESSFULLY: 'La persona se actualizó con éxito',
  STATISTICS_TITLE: 'Estadísticas',
  UTILS_ACCEPT: 'Aceptar',
  UTILS_CANCEL: 'Cancelar',
  UTILS_DATE_FROM_HELPER: 'Debe ser menor a Fecha hasta',
  UTILS_DATE_INVALID: 'Fecha invalida',
  UTILS_DATE_RANGE: 'La fecha desde debe ser anterior a fecha hasta',
  UTILS_DATE_REQUIRED_HELPER: 'La fecha no puede ser vacia',
  UTILS_DATE_REQUIRED_RANGE: 'Se necesita tener un rango de fechas',
  UTILS_FROM: 'Desde',
  UTILS_TO: 'Hasta',
  UTILS_UPLOAD: 'Cargar',
  UTILS_YEAR: 'Año',
};

export { strings };
